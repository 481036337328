<template>
  <v-container class="pa-md-12">
    <section>
      <h3 class="d-flex justify-space-between align-center text-subtitle-1 font-weight-bold">
        Premium Subscriptions
      </h3>
      <div class="text-body-2 text-medium-emphasis mb-4 w-100 w-md-75">
        Manage premium subscriptions for users.
      </div>
      <v-data-table
        :headers="headers"
        :items="premiumSubscriptions"
        :items-per-page="10"
        class="elevation-1"
      >
        <template #[`item.premiumStartDate`]="{ item }">
          {{ formatDate(item.premiumStartDate) }}
        </template>
        <template #[`item.premiumRenewalDate`]="{ item }">
          {{ formatDate(item.premiumRenewalDate) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            class="text-none mr-2"
            color="primary"
            min-width="0"
            slim
            text="Edit"
            variant="text"
            @click="editSubscription(item)"
          />
          <v-btn
            class="text-none"
            color="error"
            min-width="0"
            slim
            text="Cancel"
            variant="text"
            @click="cancelSubscription(item)"
          />
        </template>
      </v-data-table>
    </section>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save">
            <v-text-field
              v-model="editedItem.name"
              label="Name"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="editedItem.email"
              label="Email"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="editedItem.premiumStartDate"
              label="Start Date"
              type="date"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.premiumRenewalDate"
              label="Renewal Date"
              type="date"
            ></v-text-field>
            <v-switch
              v-model="editedItem.cancelPremiumRenewal"
              label="Cancel Renewal"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminPremiumSubscriptionsView',
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Start Date', value: 'premiumStartDate' },
      { text: 'Renewal Date', value: 'premiumRenewalDate' },
      { text: 'Cancel Renewal', value: 'cancelPremiumRenewal' },
      { text: 'Actions', value: 'actions', sortable: false }
    ];
    const editedIndex = ref(-1);
    const editedItem = ref({
      name: '',
      email: '',
      premiumStartDate: '',
      premiumRenewalDate: '',
      cancelPremiumRenewal: false
    });
    const defaultItem = { ...editedItem.value };

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Subscription' : 'Edit Subscription');
    const premiumSubscriptions = computed(() => store.state.premiumSubscriptions);

    const editSubscription = (item) => {
      editedIndex.value = premiumSubscriptions.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const cancelSubscription = async (item) => {
      if (confirm('Are you sure you want to cancel this subscription?')) {
        try {
          await store.dispatch('cancelPremiumSubscription', item._id);
          await fetchPremiumSubscriptions();
        } catch (error) {
          console.error('Error cancelling subscription:', error);
        }
      }
    };

    const close = () => {
      dialog.value = false;
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    };

    const save = async () => {
      try {
        if (editedIndex.value > -1) {
          await store.dispatch('updatePremiumSubscription', editedItem.value);
        }
        close();
        await fetchPremiumSubscriptions();
      } catch (error) {
        console.error('Error saving subscription:', error);
      }
    };

    const fetchPremiumSubscriptions = async () => {
      await store.dispatch('fetchPremiumSubscriptions');
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    onMounted(() => {
      fetchPremiumSubscriptions();
    });

    return {
      dialog,
      headers,
      editedIndex,
      editedItem,
      formTitle,
      premiumSubscriptions,
      editSubscription,
      cancelSubscription,
      close,
      save,
      formatDate
    };
  }
};
</script>
