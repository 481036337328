<!-- ./frontend/src/views/AccountPage.vue -->
<template>
  <v-container fluid>
    <v-row>
      <AccountManagementDrawer />

      <v-col v-if="user">
        <h1 class="text-h4 mb-6">Welcome to your account</h1>

        <AccountMFACard
          :mfaEnabled="mfaEnabled"
          @toggle-mfa="toggleMFA"
          @verify-mfa="verifyMFA"
        />

        <AccountProfileCard 
          :userData="user"
          :showPhoneVerification="showPhoneVerification"
          :showEmailVerification="showEmailVerification"
          @updateProfile="handleProfileUpdate"
          @verifyEmail="handleVerifyEmail"
          @verifyPhone="handleVerifyPhone"
          @updateEmailVerificationStatus="updateEmailVerificationStatus"
        />

        <AccountAddressesCard />

        <AccountWalletCard
          :paymentMethods="paymentMethods"
          @add-payment-method="addPaymentMethod"
          @remove-payment-method="removePaymentMethod"
        />
      </v-col>
      <v-col v-else>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.text }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import AccountManagementDrawer from '@/components/AccountManagementDrawer.vue';
import AccountProfileCard from '@/components/AccountProfileCard.vue';
import AccountMFACard from '@/components/AccountMFACard.vue';
import AccountAddressesCard from '@/components/AccountAddressesCard.vue';
import AccountWalletCard from '@/components/AccountWalletCard.vue';

export default {
  name: 'AccountPage',
  components: {
    AccountManagementDrawer,
    AccountProfileCard,
    AccountMFACard,
    AccountAddressesCard,
    AccountWalletCard,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user.user);
    const paymentMethods = computed(() => store.getters['payment/getPaymentMethods']);
    const mfaEnabled = ref(false);
    const showPhoneVerification = ref(false);
    const showEmailVerification = ref(false);
    const emailVerificationAttempts = ref(0);

    const snackbar = ref({
      show: false,
      text: '',
      color: 'success',
      timeout: 3000
    });

    const showSnackbar = (text, color = 'success') => {
      snackbar.value.show = true;
      snackbar.value.text = text;
      snackbar.value.color = color;
    };

    const handleProfileUpdate = async (updatedData) => {
      try {
        const updatedUser = await store.dispatch('user/updateUserProfile', updatedData);
        if (updatedData.email && updatedUser.email !== user.value.email) {
          showSnackbar('Email updated. Please verify your new email address.', 'info');
        } else if (updatedData.phone && updatedData.phone !== user.value.phone) {
          store.commit('user/SET_PHONE_VERIFIED', false);
          showSnackbar('Phone number updated. Please verify your new phone number.', 'info');
        } else {
          showSnackbar('Profile updated successfully');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        showSnackbar('Error updating profile', 'error');
      }
    };

    const toggleMFA = async (enabled) => {
      try {
        await store.dispatch('userVerification/toggleMFA', enabled);
        mfaEnabled.value = enabled;
        showSnackbar(`Multi-Factor Authentication ${enabled ? 'enabled' : 'disabled'}`);
      } catch (error) {
        console.error('Error toggling MFA:', error);
        showSnackbar('Error toggling Multi-Factor Authentication', 'error');
        mfaEnabled.value = !enabled; // Revert the toggle
      }
    };

    const verifyMFA = async (code) => {
      try {
        await store.dispatch('userVerification/verifyMFA', code);
        showSnackbar('Multi-Factor Authentication verified successfully');
      } catch (error) {
        console.error('Error verifying MFA:', error);
        showSnackbar('Error verifying Multi-Factor Authentication', 'error');
      }
    };

    const handleVerifyEmail = async (code) => {
      try {
        if (code) {
          if (emailVerificationAttempts.value >= 3) {
            showSnackbar('Maximum attempts reached. Please request a new verification code.', 'error');
            showEmailVerification.value = false;
            emailVerificationAttempts.value = 0;
            return;
          }

          const result = await store.dispatch('userVerification/confirmEmailVerification', code);
          showSnackbar(result.message);
          if (result.verified) {
            store.commit('user/SET_EMAIL_VERIFIED', true);
            showEmailVerification.value = false;
            emailVerificationAttempts.value = 0;
          } else {
            emailVerificationAttempts.value++;
            if (emailVerificationAttempts.value >= 3) {
              showSnackbar('Maximum attempts reached. Please request a new verification code.', 'error');
              showEmailVerification.value = false;
              emailVerificationAttempts.value = 0;
            } else {
              showSnackbar(`Invalid code. ${3 - emailVerificationAttempts.value} attempts remaining.`, 'error');
            }
          }
        } else {
          await store.dispatch('userVerification/sendEmailVerification');
          showEmailVerification.value = true;
          emailVerificationAttempts.value = 0;
          showSnackbar('Verification email sent. Please check your inbox.');
        }
      } catch (error) {
        console.error('Error in email verification:', error);
        showSnackbar(error.response?.data?.message || 'Error in email verification', 'error');
      }
    };

    const handleVerifyPhone = async (code) => {
      try {
        if (code) {
          const result = await store.dispatch('userVerification/confirmPhoneVerification', code);
          showSnackbar(result.message);
          if (result.verified) {
            store.commit('user/SET_PHONE_VERIFIED', true);
            showPhoneVerification.value = false; // Hide the verification form
          }
        } else {
          await store.dispatch('userVerification/sendPhoneVerification');
          showPhoneVerification.value = true; // Show the verification form
          showSnackbar('Verification code sent to your phone. Please enter the code.');
        }
      } catch (error) {
        console.error('Error in phone verification:', error);
        showSnackbar(error.response?.data?.message || 'Error in phone verification', 'error');
      }
    };

    const addPaymentMethod = async (paymentData) => {
      try {
        await store.dispatch('payment/addPaymentMethod', paymentData);
        showSnackbar('Payment method added successfully');
      } catch (error) {
        console.error('Error adding payment method:', error);
        showSnackbar('Error adding payment method', 'error');
      }
    };

    const removePaymentMethod = async (paymentMethodId) => {
      try {
        await store.dispatch('payment/removePaymentMethod', paymentMethodId);
        showSnackbar('Payment method removed successfully');
      } catch (error) {
        console.error('Error removing payment method:', error);
        showSnackbar('Error removing payment method', 'error');
      }
    };

    const updateEmailVerificationStatus = (status) => {
      store.commit('user/SET_EMAIL_VERIFIED', status);
    };

    onMounted(async () => {
      try {
        await store.dispatch('user/fetchUserDetails');
        if (user.value) {
          mfaEnabled.value = user.value.mfaEnabled || false;
        }
        await store.dispatch('payment/fetchPaymentMethods');
        await store.dispatch('address/fetchAddresses');
      } catch (error) {
        console.error('Error loading account information:', error);
        showSnackbar('Error loading account information', 'error');
      }
    });

    return {
      user,
      paymentMethods,
      mfaEnabled,
      snackbar,
      showPhoneVerification,
      showEmailVerification,
      handleProfileUpdate,
      toggleMFA,
      verifyMFA,
      handleVerifyEmail,
      handleVerifyPhone,
      addPaymentMethod,
      removePaymentMethod,
      updateEmailVerificationStatus,
    };
  }
};
</script>

<style scoped>
.v-col {
  padding: 12px;
}
</style>
