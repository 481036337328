<template>
  <v-container v-if="bulkOrderInfo">
    <v-row>
      <v-col cols="12">
        <v-card class="mb-6">
          <v-card-title class="text-h4">{{ bulkOrderInfo.animalType }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card outlined>
                  <v-card-title class="text-h6">
                    {{ bulkOrderInfo.availableQuantity }}lbs available
                  </v-card-title>
                  <v-card-text>
                    <v-chip-group class="mb-4">
                      <v-chip v-for="spec in bulkOrderInfo.specificationChart" :key="spec.specification" small>
                        <v-icon left small>mdi-check</v-icon>
                        {{ spec.specification }}
                      </v-chip>
                      <v-chip v-if="bulkOrderInfo.halalOption" small>
                        <v-icon left small>mdi-check</v-icon>
                        Halal
                      </v-chip>
                    </v-chip-group>
                    <v-row class="mt-4">
                      <v-col>
                        <v-btn 
                          color="success" 
                          block 
                          @click="joinOrder" 
                          :disabled="bulkOrderInfo.availableQuantity <= 0"
                        >
                          Join
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined>
                  <v-card-title class="text-h6">Order Details</v-card-title>
                  <v-card-subtitle>
                    Status: 
                    <v-chip :color="statusColor" class="mr-2">{{ bulkOrderInfo.status }}</v-chip>
                    <v-chip color="primary">
                      {{ percentComplete }}% Complete
                    </v-chip>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Estimated Pickup Date</v-list-item-title>
                          <v-list-item-subtitle>{{ formatDate(bulkOrderInfo.estimatedPickupDate) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Location</v-list-item-title>
                          <v-list-item-subtitle>{{ bulkOrderInfo.location }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <h3 class="text-h5">Cuts</h3>
                <v-list dense class="mt-2">
                  <v-list-item v-for="cut in bulkOrderInfo.cutOptions" :key="cut.cut">
                    <v-list-item-content>
                      <v-list-item-title>{{ cut.cut }}</v-list-item-title>
                      <v-list-item-subtitle style="white-space: normal;">{{ cut.cutDescription }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-6">
          <v-card-title class="text-h5">Prices</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[]"
              :items="formattedPriceChart"
              :items-per-page="-1"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.comment }}</td>
                  <td>{{ item.quantity }} lb</td>
                  <td>${{ item.price }}/lb</td>
                  <td>${{ calculateTotal(item) }} Total</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card class="mb-6">
          <v-card-title class="text-h5">Location</v-card-title>
          <v-card-text>
            <div id="map" style="height: 400px;"></div>
          </v-card-text>
        </v-card>

        <v-card class="mb-6">
          <v-card-title class="text-h5">Farms</v-card-title>
          <v-card-text>
            <v-chip-group>
              <v-chip
                v-for="farm in bulkOrderInfo.allFarms"
                :key="farm"
                :color="bulkOrderInfo.preferredFarms.includes(farm) ? 'primary' : ''"
                outlined
              >
                {{ farm }}
                <v-icon v-if="bulkOrderInfo.preferredFarms.includes(farm)" right small>mdi-star</v-icon>
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>

        <v-card ref="generalInfo">
          <v-card-title class="text-h5">General Info</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h4 class="text-h6 mb-3">About Loquo</h4>
                <p class="mb-5">{{ bulkOrderInfo.about }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="text-h6 mb-3">Pickup Process for {{ bulkOrderInfo.location }}</h4>
                <p class="mb-5">{{ bulkOrderInfo.pickupProcess }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h4 class="text-h6 mb-3">Order Process</h4>
                <div v-html="formattedOrderProcess" class="mb-5"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'BulkOrderInfo',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedQuantity = ref(null);
    const selectedCut = ref(null);
    const priceChartDialog = ref(false);
    const showCutsDialog = ref(false);
    const selectedImage = ref(0);

    const bulkOrderInfo = computed(() => store.state.bulkOrderInfo);

    const additionalImages = computed(() => [
      bulkOrderInfo.value?.image || 'https://via.placeholder.com/90x90',
      bulkOrderInfo.value?.additionalImage1 || 'https://via.placeholder.com/90x90',
      bulkOrderInfo.value?.additionalImage2 || 'https://via.placeholder.com/90x90',
      'https://via.placeholder.com/90x90',
    ]);

    const breadcrumbItems = computed(() => [
      { title: 'Home', to: '/' },
      { title: 'Bulk Orders', to: '/bulk-meat' },
      { title: bulkOrderInfo.value?.animalType || 'Invite', disabled: true },
    ]);

    const formattedOrderProcess = computed(() => {
      if (!bulkOrderInfo.value?.orderProcess) return '';
      return bulkOrderInfo.value.orderProcess
        .split('\n')
        .map(line => line.trim())
        .filter(line => line !== '')
        .join('<br><br>');
    });

    const joinOrder = () => {
      if (selectedQuantity.value !== null && selectedCut.value !== null) {
        const selectedQuantityValue = bulkOrderInfo.value.priceChart[selectedQuantity.value].quantity;
        const selectedCutValue = bulkOrderInfo.value.cutOptions[selectedCut.value].cut;
        router.push({
          path: `/bulk-order-deposit/${route.params.id}`,
          query: { quantity: selectedQuantityValue, cut: selectedCutValue }
        });
      } else {
        alert('Please select a cut and quantity');
      }
    };

    const showPriceChart = () => {
      priceChartDialog.value = true;
    };

    const getStatusColor = (status) => {
      switch (status) {
        case 'Open': return 'green';
        case 'Closed': return 'orange';
        case 'Fulfilled': return 'red';
        default: return 'grey';
      }
    };

    // Fetch bulk order info when component is created
    onMounted(async () => {
      await store.dispatch('fetchBulkOrderInfo', route.params.id);
      await store.dispatch('fetchSellers');  // Changed from fetchFarms to fetchSellers
    });

    return {
      bulkOrderInfo,
      breadcrumbItems,
      selectedQuantity,
      selectedCut,
      formattedOrderProcess,
      joinOrder,
      priceChartDialog,
      showCutsDialog,
      showPriceChart,
      getStatusColor,
      selectedImage,
      additionalImages,
    };
  },
};
</script>
