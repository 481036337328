<!-- ./frontend/src/views/CheckoutPage.vue -->
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <h1>Review order</h1>
        
        <!-- Pickup/Delivery Information -->
        <v-card class="mb-4">
          <v-card-text>
            <div v-if="quantityDelivery > 0" class="delivery-info mb-4">
              <v-icon left size="24">mdi-truck-delivery</v-icon>
              <span class="info-text">
                Deliver {{ quantityDelivery }} items on {{ formattedOrderDeliveryDateRef }}
                <template v-if="user && user.addresses && user.addresses.length > 0">
                  to {{ user.addresses[0].street }}, {{ user.addresses[0].city }}
                </template>
              </span>
            </div>
            <v-btn
              v-if="quantityDelivery > 0 && user && user.addresses && user.addresses.length > 0"
              text
              @click="changeAddress"
              class="mb-4"
            >
              Change address
            </v-btn>

            <!-- Shipping Information Form -->
            <v-form v-if="!user || !user.addresses || user.addresses.length === 0" class="mt-4">
              <h3 class="mb-4">Shipping Information</h3>
              <v-text-field
                v-model="shippingInfo.firstName"
                label="First name"
                required
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.lastName"
                label="Surname"
                required
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.email"
                label="Email"
                type="email"
                required
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.street"
                label="Street address"
                required
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.apt"
                label="Apt, suite, etc."
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.city"
                label="City"
                required
              ></v-text-field>
              <v-select
                v-model="shippingInfo.province"
                :items="provinces"
                label="Province/Territory"
                required
              ></v-select>
              <v-text-field
                v-model="shippingInfo.postalCode"
                label="Postal code"
                required
              ></v-text-field>
              <v-text-field
                v-model="shippingInfo.phone"
                label="Phone number"
                required
              ></v-text-field>
              <v-textarea
                v-model="shippingInfo.deliveryInstructions"
                label="Delivery instructions"
                counter="250"
              ></v-textarea>
            </v-form>

            <template v-if="quantityPickUp > 0">
              <div v-for="(sellerData, sellerName) in pickupSchedules" :key="sellerName" class="pickup-info mb-4">
                <v-icon left size="24">mdi-store</v-icon>
                <span class="info-text">
                  Pickup {{ sellerData.quantity }} items from {{ sellerName }}
                </span>
              </div>
              <v-data-table
                v-for="(sellerData, sellerName) in pickupSchedules"
                :key="sellerName"
                class="bg-transparent mb-6"
                :headers="pickupHeaders"
                :items="sellerData.schedules"
                hide-default-footer
              >
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
        
        <!-- Substitution Preferences -->
        <v-card class="mb-4">
          <v-card-title>
            <v-icon left>mdi-swap-horizontal</v-icon>
            Substitution Preferences
          </v-card-title>
          <v-card-text>
            <v-switch
              v-model="substitutionPreference"
              :label="substitutionPreference ? 'By default, allow substitutions.' : 'By default, do not allow substitutions.'"
              @change="updateUserSubstitutionPreference"
            ></v-switch>
            <v-data-table
              :headers="substitutionHeaders"
              :items="cartItems"
              item-key="_id"
              class="elevation-1"
            >
              <template #[`item.allowSubstitution`]="{ item }">
                <v-checkbox
                  v-model="item.substitution.allowed"
                  @change="updateItemSubstitution(item)"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        
        <!-- Payment Method Section -->
        <v-card class="mb-4">
          <v-card-title>
            <v-icon left>mdi-credit-card</v-icon>
            Payment Method
          </v-card-title>
          <v-card-text>
            <template v-if="defaultPaymentMethod">
              <v-card class="mb-4" :color="getCardColor(defaultPaymentMethod.card.brand)" dark>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <div class="text-subtitle-2">{{ defaultPaymentMethod.card.brand }}</div>
                      <v-chip color="charcoal" small class="mt-1">Default</v-chip>
                    </div>
                  </div>
                  <div class="text-body-2 mt-2">
                    Card ending in {{ defaultPaymentMethod.card.last4 }}
                  </div>
                  <div class="d-flex justify-space-between mt-4">
                    <div>{{ defaultPaymentMethod.billing_details.name }}</div>
                    <div>{{ defaultPaymentMethod.card.exp_month }}/{{ defaultPaymentMethod.card.exp_year }}</div>
                  </div>
                </v-card-text>
              </v-card>
              <v-btn text @click="changePaymentMethod">Change payment method</v-btn>
            </template>
            <template v-else>
              <div id="card-element"></div>
              <div id="card-errors" role="alert"></div>
            </template>
          </v-card-text>
        </v-card>
        
        <!-- Mobile Contact -->
        <v-card class="mb-4">
          <v-card-title>
            <v-icon left>mdi-cellphone</v-icon>
            Mobile Contact
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="formattedPhoneNumber"
              label="Phone number (10 digits)"
              :rules="[v => !!v || 'Phone number is required']"
              @input="phoneNumber = $event.target.value.replace(/\D/g, '')"
            ></v-text-field>
            <v-checkbox
              v-model="receiveTextUpdates"
              label="I want to receive text updates about the status of my order."
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <v-btn 
              color="#4CAF50" 
              block 
              @click="placeOrder" 
              :disabled="!isFormValid || processingPayment"
              :loading="processingPayment"
              class="mb-4"
            >
              Place order for ${{ total.toFixed(2) }}
            </v-btn>
            <div class="d-flex justify-space-between mb-2">
              <p class="text-body-1">Subtotal ({{ cartItemCount }} items)</p>
              <p>${{ subtotal.toFixed(2) }}</p>
            </div>
            <div v-if="isPremiumSubscribed" class="d-flex justify-space-between mb-2">
              <p class="text-body-1">Loquo Premium</p>
              <p>$9.99</p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="text-body-1">Taxes (13% HST)</p>
              <p>${{ taxes.toFixed(2) }}</p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="text-body-1" :style="{ color: '#1E88E5' }">Shipping</p>
              <p :style="{ color: '#1E88E5' }">
                {{ isPremiumUser ? 'FREE' : `$${shippingCost.toFixed(2)}` }}
              </p>
            </div>
            <v-divider class="my-2"></v-divider>
            <div class="d-flex justify-space-between mb-4">
              <p class="text-body-1 font-weight-bold">Estimated total</p>
              <p class="font-weight-bold">${{ total.toFixed(2) }}</p>
            </div>
            <template v-if="user && userOrders.length === 0">
              <p class="text-caption" :style="{ color: '#1E88E5' }">*You qualify for free shipping on your first order!</p>
            </template>
            <template v-if="!user">
              <v-btn
                color="#1E88E5"
                block
                @click="toggleAccountMenu"
                class="mt-4"
              >
                Sign Up for free shipping on your first order
              </v-btn>
            </template>
            <template v-else-if="user && userOrders.length > 0 && !isPremiumUser">
              <v-btn
                color="#1E88E5"
                block
                @click="subscribeToPremium"
                class="mt-4"
                style="white-space: normal; height: auto;"
              >
                Sign up for Loquo Premium for $9.99 per month and get free shipping on all orders. Cancel at any time.
              </v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Premium subscription dialog -->
    <v-dialog v-model="showPremiumDialog" max-width="500px">
      <v-card>
        <v-card-title>Subscribe to Loquo Premium</v-card-title>
        <v-card-text>
          <p>Enjoy free shipping on all orders for just $9.99 per month!</p>
          <div id="card-element"></div>
          <div id="card-errors" role="alert"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="processPremiumSubscription" :loading="processingPayment">Subscribe</v-btn>
          <v-btn text @click="showPremiumDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
    <v-snackbar v-model="snackbarState.show" :timeout="2000">
      {{ snackbarState.text }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarState.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { productDeliveryDate, orderDeliveryDate as calculateOrderDeliveryDate } from '@/utils/dateUtils'
import getStripe from '@/utils/stripe'

export default {
  name: 'CheckoutPage',
  setup() {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user)
    const substitutionPreference = ref(user.value?.substitutionPreference ?? true)

    const defaultPaymentMethod = ref(null)
    const cardNumber = ref('')
    const cardholderName = ref('')
    const expiryMonth = ref('')
    const expiryYear = ref('')
    const cvv = ref('')
    const saveCard = ref(false)

    const monthsArray = Array.from({length: 12}, (_, i) => i + 1)
    const currentYear = new Date().getFullYear()
    const yearsArray = Array.from({length: 10}, (_, i) => currentYear + i)

    const cartItemCount = computed(() => store.getters.cartItemCount)
    const subtotal = computed(() => store.getters.cartTotal)
    const savings = ref(6.79) // This should be calculated based on the actual savings
    const taxes = computed(() => subtotal.value * 0.13)
    const userOrders = computed(() => store.state.orders)
    const isCartEmpty = computed(() => cartItemCount.value === 0)

    const phoneNumber = ref(user.value?.phone || '')
    const receiveTextUpdates = ref(true)

    const quantityDelivery = computed(() => {
      return store.state.cart.filter(item => item.deliveryOption === 'Delivery').reduce((sum, item) => sum + item.quantity, 0)
    })

    const quantityPickUp = computed(() => {
      return store.state.cart.filter(item => item.deliveryOption === 'Pickup').reduce((sum, item) => sum + item.quantity, 0)
    })

    const pickupSchedules = computed(() => {
      const schedules = {};
      store.state.cart.forEach(item => {
        if (item.deliveryOption === 'Pickup') {
          const seller = store.state.sellers.find(s => s._id === item.seller.sellerId);
          if (seller && seller.pickupSchedule && seller.pickupSchedule.length > 0) {
            if (!schedules[seller.name]) {
              schedules[seller.name] = {
                quantity: 0,
                schedules: []
              };
            }
            schedules[seller.name].quantity += item.quantity;
            schedules[seller.name].schedules = seller.pickupSchedule.map(schedule => ({
              ...schedule,
              sellerName: seller.name
            }));
          }
        }
      });
      return schedules;
    });

    const pickupHeaders = ref([
      { title: 'Pickup Day', key: 'pickupDay' },
      { title: 'Start Time', key: 'pickupStartTime' },
      { title: 'End Time', key: 'pickupEndTime' },
      { title: 'Location', key: 'pickupLocation' },
      { title: 'Instructions', key: 'pickupInstructions' },
    ]);

    const substitutionHeaders = [
      { title: 'Product', key: 'name' },
      { title: 'Allow Substitution', key: 'allowSubstitution' }
    ]

    const cartItems = computed(() => store.state.cart)

    const isFormValid = computed(() => {
      if (!user.value || !user.value.addresses || user.value.addresses.length === 0) {
        return shippingInfo.value.firstName &&
               shippingInfo.value.lastName &&
               shippingInfo.value.email &&
               shippingInfo.value.street &&
               shippingInfo.value.city &&
               shippingInfo.value.province &&
               shippingInfo.value.postalCode &&
               shippingInfo.value.phone &&
               phoneNumber.value &&
               (defaultPaymentMethod.value || (cardNumber.value && cardholderName.value && expiryMonth.value && expiryYear.value));
      }
      return phoneNumber.value && 
             user.value && 
             user.value._id && 
             user.value.addresses && 
             user.value.addresses.length > 0 &&
             (defaultPaymentMethod.value || (cardNumber.value && cardholderName.value && expiryMonth.value && expiryYear.value));
    });

    const formatDate = (date) => {
      if (!date) return 'Not available';
      return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })
    }

    const orderDeliveryDate = ref(null);
    const formattedOrderDeliveryDateRef = ref('Not available');

    const updateOrderDeliveryDate = async () => {
      const deliveryItems = store.state.cart.filter(item => item.deliveryOption === 'Delivery');
      orderDeliveryDate.value = await calculateOrderDeliveryDate(deliveryItems);
      formattedOrderDeliveryDateRef.value = formatDate(orderDeliveryDate.value);
    };

    const changeAddress = () => {
      router.push('/account#addresses');
    }

    const updateUserSubstitutionPreference = async () => {
      try {
        await store.dispatch('updateUserSubstitutionPreference', substitutionPreference.value)
        cartItems.value.forEach(item => {
          if (!item.substitution) {
            item.substitution = {};
          }
          item.substitution.allowed = substitutionPreference.value
        })
      } catch (error) {
        console.error('Error updating user substitution preference:', error)
      }
    }

    const updateItemSubstitution = (item) => {
      // The cart item is already updated due to v-model binding
      // You might want to persist this change to the backend if necessary
    }

    const getCardColor = (cardType) => {
      switch (cardType) {
        case 'Visa': return 'blue darken-2';
        case 'Mastercard': return 'red darken-2';
        case 'American Express': return 'indigo';
        default: return 'grey darken-2';
      }
    }

    const changePaymentMethod = () => {
      router.push('/account#wallet');
    }

    const formattedPhoneNumber = computed(() => {
      if (phoneNumber.value) {
        const digits = phoneNumber.value.replace(/\D/g, '').slice(-10);
        return digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
      return '';
    })

    const savePaymentMethod = async () => {
      console.log('Payment method will be saved after order placement if user chooses to do so');
    }

    const shippingInfo = ref({
      firstName: '',
      lastName: '',
      email: '',
      street: '',
      apt: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      deliveryInstructions: ''
    })

    const provinces = [
      'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
      'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia',
      'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'
    ]

    const placeOrder = async () => {
      if (!isFormValid.value) {
        console.log('Form is not valid');
        return;
      }

      try {
        const orderItems = await Promise.all(store.state.cart.map(async (item) => {
          const dropOffDate = item.deliveryOption === 'Pickup' ? null : await productDeliveryDate(item);
          return {
            productId: item._id,
            quantity: item.quantity,
            price: item.price,
            seller: {
              sellerId: item.seller.sellerId,
              name: item.seller.name
            },
            deliveryOption: item.deliveryOption,
            dropOffDate: dropOffDate,
            substitution: {
              allowed: item.substitution.allowed,
              halal: item.halal,
              certifiedOrganic: item.certifiedOrganic
            }
          };
        }));

        const order = {
          userId: user.value?._id,
          items: orderItems,
          totalAmount: total.value,
          status: 'Pending',
          phoneNumber: phoneNumber.value,
          receiveTextUpdates: receiveTextUpdates.value,
          deliveryAddress: user.value?.addresses?.find(addr => addr.isPreferred) || user.value?.addresses?.[0] || shippingInfo.value,
          pickupSchedules: Object.values(pickupSchedules.value).flatMap(sellerData => 
            sellerData.schedules.map(schedule => ({
              ...schedule,
              quantity: sellerData.quantity
            }))
          ),
          paymentMethod: defaultPaymentMethod.value || {
            cardNumber: cardNumber.value,
            cardholderName: cardholderName.value,
            expiryMonth: expiryMonth.value,
            expiryYear: expiryYear.value,
            cvv: cvv.value
          },
          email: user.value?.email || shippingInfo.value.email,
          deliveryDate: orderDeliveryDate.value,
        };

        // Create a PaymentIntent
        const paymentIntentResponse = await store.dispatch('createPaymentIntent', Math.round(total.value * 100));
        if (!paymentIntentResponse || !paymentIntentResponse.clientSecret) {
          throw new Error('Failed to create payment intent. Client secret is missing.');
        }
        
        const clientSecret = paymentIntentResponse.clientSecret;

        // Confirm the PaymentIntent
        const stripe = await getStripe();
        let paymentMethod;

        // Create a new payment method
        const { paymentMethod: newPaymentMethod, error: newPaymentMethodError } = await stripe.createPaymentMethod({
          type: 'card',
          card: {
            number: cardNumber.value,
            exp_month: expiryMonth.value,
            exp_year: expiryYear.value,
            cvc: cvv.value,
          },
          billing_details: {
            name: cardholderName.value,
          },
        });

        if (newPaymentMethodError) {
          throw new Error(newPaymentMethodError.message);
        }

        paymentMethod = newPaymentMethod.id;

        const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod,
        });

        if (paymentError) {
          throw new Error(paymentError.message);
        }

        // Add the PaymentIntent ID to the order
        order.stripePaymentIntentId = paymentIntent.id;

        const createdOrder = await store.dispatch('createOrder', order);

        store.dispatch('clearCart');
        router.push(`/order-confirmation/${createdOrder._id}`);
      } catch (error) {
        console.error('Error creating order:', error);
        console.error('Error details:', JSON.stringify(error, null, 2));
        // Show error message to user
        alert('Failed to process order. Please try again.');
      }
    };

    const fetchPaymentMethods = async () => {
      try {
        const paymentMethods = await store.dispatch('fetchPaymentMethods');
        defaultPaymentMethod.value = paymentMethods.find(pm => pm.isDefault) || null;
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    }

    const toggleAccountMenu = () => {
      store.dispatch('toggleAccountMenu');
    }

    const isPremiumUser = computed(() => store.getters.isPremiumUser)
    const showPremiumDialog = ref(false)
    const processingPayment = ref(false)
    let stripe
    let card

    const shippingCost = computed(() => {
      if (isPremiumUser.value || (user.value && userOrders.value.length === 0)) {
        return 0
      }
      return 12.99 // Your default shipping cost
    })

    const total = computed(() => {
      let calculatedTotal = subtotal.value + taxes.value + shippingCost.value
      if (isPremiumUser.value) {
        calculatedTotal += 9.99
      }
      return calculatedTotal
    })

    const subscribeToPremium = async () => {
      showPremiumDialog.value = true
      await initializeStripe()
    }

    const initializeStripe = async () => {
      try {
        stripe = await getStripe();
        if (!stripe) {
          throw new Error('Failed to initialize Stripe');
        }
        const elements = stripe.elements();
        card = elements.create('card');
        card.mount('#card-element');
      } catch (error) {
        console.error('Error initializing Stripe:', error);
      }
    }

    const processPremiumSubscription = async () => {
      processingPayment.value = true
      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
        })

        if (result.error) {
          const errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
        } else {
          await store.dispatch('subscribeToPremium', result.paymentMethod.id)
          showPremiumDialog.value = false
          snackbarState.value = {
            show: true,
            text: 'Successfully subscribed to Loquo Premium!'
          }
        }
      } catch (error) {
        console.error('Error processing premium subscription:', error)
        snackbarState.value = {
          show: true,
          text: 'An error occurred while processing your subscription. Please try again.'
        }
      } finally {
        processingPayment.value = false
      }
    }

    const snackbarState = ref({
      show: false,
      text: ''
    })

    onMounted(async () => {
      if (user.value) {
        phoneNumber.value = user.value.phone || '';
        await fetchPaymentMethods();
        await store.dispatch('fetchOrders');
      }
      await store.dispatch('fetchSellers');
      await updateOrderDeliveryDate();

      // Initialize substitution preferences for cart items
      cartItems.value.forEach(item => {
        if (!item.substitution) {
          item.substitution = {};
        }
        item.substitution.allowed = user.value?.substitutionPreference || true
      })
    })

    watch(() => store.state.cart, async () => {
      await updateOrderDeliveryDate();
    }, { deep: true })

    watch(user, async (newUser) => {
      if (newUser) {
        phoneNumber.value = newUser.phone || '';
        await fetchPaymentMethods();
        await store.dispatch('fetchOrders');
      } else {
        defaultPaymentMethod.value = null;
      }
    })

    return {
      user,
      cartItemCount,
      subtotal,
      savings,
      taxes,
      total,
      defaultPaymentMethod,
      cardNumber,
      cardholderName,
      expiryMonth,
      expiryYear,
      cvv,
      saveCard,
      monthsArray,
      yearsArray,
      phoneNumber,
      formattedPhoneNumber,
      receiveTextUpdates,
      isFormValid,
      quantityDelivery,
      quantityPickUp,
      pickupSchedules,
      pickupHeaders,
      changeAddress,
      getCardColor,
      changePaymentMethod,
      placeOrder,
      shippingInfo,
      provinces,
      savePaymentMethod,
      formattedOrderDeliveryDateRef,
      userOrders,
      shippingCost,
      toggleAccountMenu,
      subscribeToPremium,
      processPremiumSubscription,
      showPremiumDialog,
      isPremiumUser,
      substitutionHeaders,
      cartItems,
      substitutionPreference,
      updateUserSubstitutionPreference,
      updateItemSubstitution,
      processingPayment,
      snackbarState,
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.delivery-info,
.pickup-info {
  display: flex;
  align-items: center;
}

.info-text {
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
}
</style>
