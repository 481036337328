<!-- ./frontend/src/views/AdminRecommendationsPage.vue -->
<template>
  <v-container>
    <h1>Admin: Recommendations</h1>
    <v-data-table
      :headers="headers"
      :items="recommendations"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editRecommendation(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteRecommendation(item._id)">Delete</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save">
            <v-text-field v-model="editedItem.comment" label="Comment"></v-text-field>
            <v-select
              v-model="editedItem.type"
              :items="['recommendation', 'changedLife']"
              label="Type"
              multiple
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<!-- ./frontend/src/views/AdminRecommendationsPage.vue -->
<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminRecommendationsPage',
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const headers = [
      { text: 'User', value: 'userId.name' },
      { text: 'Product', value: 'productId.name' },
      { text: 'Comment', value: 'comment' },
      { text: 'Type', value: 'type' },
      { text: 'Actions', value: 'actions', sortable: false }
    ];
    const editedIndex = ref(-1);
    const editedItem = ref({
      comment: '',
      type: []
    });
    const defaultItem = {
      comment: '',
      type: []
    };

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Recommendation' : 'Edit Recommendation');
    const recommendations = computed(() => store.state.recommendations);

    const editRecommendation = (item) => {
      editedIndex.value = recommendations.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteRecommendation = async (id) => {
      if (confirm('Are you sure you want to delete this recommendation?')) {
        await store.dispatch('deleteRecommendation', { id });
        await fetchRecommendations();
      }
    };

    const close = () => {
      dialog.value = false;
      editedIndex.value = -1;
      editedItem.value = Object.assign({}, defaultItem);
    };

    const save = async () => {
      if (editedIndex.value > -1) {
        await store.dispatch('updateRecommendation', editedItem.value);
      } else {
        await store.dispatch('createRecommendation', editedItem.value);
      }
      close();
      await fetchRecommendations();
    };

    const fetchRecommendations = async () => {
      await store.dispatch('fetchRecommendations');
    };

    onMounted(() => {
      fetchRecommendations();
    });

    return {
      dialog,
      headers,
      editedIndex,
      editedItem,
      formTitle,
      recommendations,
      editRecommendation,
      deleteRecommendation,
      close,
      save
    };
  }
};
</script>
