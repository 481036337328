<!-- ./frontend/src/views/HomePage.vue -->
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card color="secondary" dark class="mb-6">
          <v-card-title class="text-h4">Go local with Loquo</v-card-title>
          <v-card-text>
            <p>Learn more</p>
          </v-card-text>
        </v-card>

        <v-card 
          class="mx-auto mb-6" 
          prepend-icon="mdi-magnify" 
          subtitle="Search for products and sellers" 
          flat
        >
          <template #title>
            <span class="font-weight-black">Quick Search</span>
          </template>
          <v-card-text>
            <v-btn
              v-for="section in categorySections"
              :key="section"
              @click="searchBySection(section)"
              class="ma-2"
            >
              {{ section }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card 
          class="mx-auto" 
          prepend-icon="mdi-food-steak" 
          subtitle="Buy meat from local farms in bulk" 
          outline
          @click="$router.push('/bulk-meat')"
        >
          <template #title>
            <span class="font-weight-black">Bulk Meat</span>
          </template>
          <v-img 
            src="https://storage.cloud.google.com/loquo-app/Images/Meat.jpg?authuser=1" 
            height="200" 
            cover
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card 
          class="mx-auto" 
          prepend-icon="mdi-basket" 
          subtitle="Subscribe to local farms on a flexible schedule" 
          outline
          @click="$router.push('/farm-subscriptions')"
        >
          <template #title>
            <span class="font-weight-black">Farm Subscriptions</span>
          </template>
          <v-img 
            src="https://storage.cloud.google.com/loquo-app/Images/CSA%20Basket.jpeg?authuser=1" 
            height="200" 
            cover
            @click="$router.push('/farm-subscriptions')"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          class="mx-auto"
          prepend-icon="mdi-book-open-variant"
          outline
          @click="$router.push('/recipes')"
        >
          <template #title>
            <span class="font-weight-black">Recipes</span>
          </template>
          <template #subtitle>
            <span class="text-wrap">Find recipes and buy the ingredients in 2-clicks or publish recipes and earn a commission on products sold.</span>
          </template>
          <v-img
            src="https://storage.cloud.google.com/loquo-app/Images/Recipes.jpg?authuser=1"
            height="200"
            cover
            @click="$router.push('/recipes')"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card
          class="mx-auto"
          prepend-icon="mdi-bottle-tonic"
          outline
          @click="$router.push('/naturopathy')"
        >
          <template #title>
            <span class="font-weight-black">Naturopathy</span>
          </template>
          <template #subtitle>
            <span class="text-wrap">Find remedies and purchase the ingredients in 2-clicks or publish remedies and earn a commission on products sold.</span>
          </template>
          <v-img
            src="https://storage.cloud.google.com/loquo-app/Images/Naturopathy.jpg?authuser=1"
            height="200"
            cover
            @click="$router.push('/naturopathy')"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          class="mx-auto"
          prepend-icon="mdi-account-tie"
          subtitle="Find local natural health practitioners."
          outline
          @click="$router.push('/health-practitioners')"
        >
          <template #title>
            <span class="font-weight-black">Health Practitioners</span>
          </template>
          <v-img
            src="https://storage.cloud.google.com/loquo-app/Images/Chiropractor.png?authuser=1"
            height="200"
            cover
            @click="$router.push('/naturopathy')"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card 
          class="mx-auto" 
          prepend-icon="mdi-human-pregnant" 
          subtitle="Resources for expecting parents"
          outline
        >
          <template #title>
            <span class="font-weight-black">Pregnancy</span>
          </template>
          <v-row>
            <v-col cols="6">
              <v-img 
                src="https://storage.cloud.google.com/loquo-app/Images/Pregnancy%20Kit.jpg?authuser=1" 
                alt="Pregnancy" 
                height="200" 
                cover
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-list density="compact">
                <v-list-item 
                  v-for="item in pregnancyItems" 
                  :key="item.title" 
                  :to="item.route"
                  link
                >
                  <template #prepend>
                    <v-icon :icon="item.icon" size="small"></v-icon>
                  </template>
                  <v-list-item-title class="text-caption text-wrap">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card 
          class="mx-auto" 
          prepend-icon="mdi-barn" 
          subtitle="Connect with local farms and eat local food"
          outline
        >
          <template #title>
            <span class="font-weight-black">Farms</span>
          </template>
          <v-row>
            <v-col cols="6">
              <v-img 
                src="https://storage.cloud.google.com/loquo-app/Images/Farm.jpg?authuser=1" 
                alt="Connect with local farms" 
                height="200" 
                cover
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-list density="compact">
                <v-list-item 
                  v-for="item in farmConnectItems" 
                  :key="item.title" 
                  :to="item.route"
                  link
                >
                  <template #prepend>
                    <v-icon :icon="item.icon" size="small"></v-icon>
                  </template>
                  <v-list-item-title class="text-caption text-wrap">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'HomePage',
  setup() {
    const store = useStore();
    const router = useRouter();

    const categorySections = computed(() => {
      return [...new Set(store.state.categories.map(category => category.section))];
    });

    const pregnancyItems = ref([
      { title: 'Organic baby registry', route: '/pregnancy/baby-registry', icon: 'mdi-baby-carriage' },
      { title: 'Online courses', route: '/pregnancy/courses', icon: 'mdi-school' },
      { title: 'Hire a local doulas', route: '/pregnancy/doulas', icon: 'mdi-human-female-female' },
      { title: 'Online and local groups', route: '/pregnancy/groups', icon: 'mdi-account-group' },
    ]);

    const farmConnectItems = ref([
      { title: 'Pick your own food', route: '/pyo', icon: 'mdi-food-apple' },
      { title: 'Farm tours', route: '/farm-tours', icon: 'mdi-tractor' },
      { title: 'Farmers markets', route: '/farmers-markets', icon: 'mdi-store' },
      { title: 'Restaurants', icon: 'mdi-silverware-fork-knife', to: '/restaurants' },
    ]);

    const searchBySection = (section) => {
      router.push({ 
        path: '/search', 
        query: { 
          viewAll: 'true',
          section: section
        } 
      });
    };

    onMounted(async () => {
      try {
        await store.dispatch('fetchCategories');
        console.log('Categories fetched:', store.state.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    });

    return {
      categorySections,
      pregnancyItems,
      farmConnectItems,
      searchBySection,
    };
  },
};
</script>
