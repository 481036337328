// frontend/src/store/modules/payment.js

import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../utils/axios'

const state = {
  stripe: null,
  paymentMethods: [],
};

const mutations = {
  SET_STRIPE(state, stripe) {
    state.stripe = stripe;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  setPaymentMethods(state, methods) {
    console.log('Vuex mutation: Setting payment methods', methods);
    state.paymentMethods = methods;
  },
};

const actions = {
  async createSetupIntent({ commit }) {
    try {
      const response = await axiosInstance.post('/api/payments/create-setup-intent');
      return response.data.clientSecret;
    } catch (error) {
      console.error('Error creating setup intent:', error);
      throw error;
    }
  },
  async initializeStripe({ commit, state }) {
    if (!state.stripe) {
      try {
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        commit('SET_STRIPE', stripe);
      } catch (error) {
        console.error('Failed to initialize Stripe:', error);
        throw error;
      }
    }
    return state.stripe;
  },
  async fetchPaymentMethods({ commit }) {
    console.log('Vuex: Fetching payment methods');
    try {
      const response = await axiosInstance.get('/api/users/payment-methods');
      console.log('Vuex: Payment methods response:', response.data);
      commit('setPaymentMethods', response.data);
    } catch (error) {
      console.error('Vuex: Error fetching payment methods:', error);
      throw error;
    }
  },
  async addPaymentMethod({ dispatch }, { paymentMethodId, isDefault }) {
    try {
      await axiosInstance.post('/api/users/payment-methods', { paymentMethodId, isDefault });
      // Refresh the payment methods list
      await dispatch('fetchPaymentMethods');
    } catch (error) {
      console.error('Failed to add payment method:', error);
      throw error;
    }
  },
  async removePaymentMethod({ dispatch }, paymentMethodId) {
    try {
      await axiosInstance.delete(`/api/users/payment-methods/${paymentMethodId}`);
      // Refresh the payment methods list
      await dispatch('fetchPaymentMethods');
    } catch (error) {
      console.error('Failed to remove payment method:', error);
      throw error;
    }
  },
  async setDefaultPaymentMethod({ dispatch }, paymentMethodId) {
    try {
      console.log('Vuex: Setting default payment method for new card', paymentMethodId);
      await axiosInstance.post('/api/users/payment-methods', { paymentMethodId, isDefault: true });
      await dispatch('fetchPaymentMethods');
      console.log('Vuex: Default payment method set successfully for new card');
    } catch (error) {
      console.error('Vuex: Failed to set default payment method for new card:', error);
      throw error;
    }
  },
};

const getters = {
  getStripe: (state) => state.stripe,
  getPaymentMethods: (state) => state.paymentMethods,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
