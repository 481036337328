<template>
  <div class="coming-soon">
    <h1>Coming Soon</h1>
    <p>This feature is not yet available. We're working hard to bring it to you!</p>
    <div class="notification-form">
      <h2>Get Notified</h2>
      <p>Leave your email address to be notified when this feature becomes available:</p>
      <form @submit.prevent="submitEmail">
        <input 
          v-model="email" 
          type="email" 
          placeholder="Enter your email"
          required
        >
        <button type="submit">Notify Me</button>
      </form>
      <p v-if="submitMessage" class="submit-message">{{ submitMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ComingSoon',
  setup() {
    const store = useStore()
    const email = ref('')
    const submitMessage = ref('')

    const submitEmail = async () => {
      try {
        await store.dispatch('subscribeNotification', { 
          email: email.value,
          category: 'coming soon'  // You can make this dynamic based on the route if needed
        })
        submitMessage.value = 'Thank you! We\'ll notify you when this feature is available.'
        email.value = ''  // Clear the input
      } catch (error) {
        submitMessage.value = 'Sorry, there was an error. Please try again later.'
        console.error('Error submitting email:', error)
      }
    }

    return {
      email,
      submitMessage,
      submitEmail
    }
  }
}
</script>

<style scoped>
.coming-soon {
  text-align: center;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  color: #623E2A;  /* Primary color from your color scheme */
}

.notification-form {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="email"] {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.5rem 1rem;
  background-color: #658D8B;  /* Secondary color from your color scheme */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4a6e6c;
}

.submit-message {
  margin-top: 1rem;
  font-weight: bold;
}
</style>
