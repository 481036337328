import axiosInstance from '../../utils/axios';

const state = {
  // We might not need any state here, but you can add if necessary
};

const mutations = {
  // We might not need any mutations here, but you can add if necessary
};

const actions = {
  async toggleMFA({ commit }, enabled) {
    try {
      const response = await axiosInstance.post('/api/users/toggle-mfa', { enabled });
      commit('user/UPDATE_USER', { mfaEnabled: enabled }, { root: true });
      return response.data;
    } catch (error) {
      console.error('Error toggling MFA:', error);
      throw error;
    }
  },
  async verifyMFA({ commit }, code) {
    try {
      const response = await axiosInstance.post('/api/users/verify-mfa', { code });
      return response.data;
    } catch (error) {
      console.error('Error verifying MFA:', error);
      throw error;
    }
  },
  async sendEmailVerification() {
    try {
      await axiosInstance.post('/api/user-verification/email');
    } catch (error) {
      console.error('Error sending email verification:', error);
      throw error;
    }
  },
  async sendPhoneVerification() {
    try {
      await axiosInstance.post('/api/user-verification/phone');
    } catch (error) {
      console.error('Error sending phone verification:', error);
      throw error;
    }
  },
  async confirmEmailVerification({ commit }, code) {
    try {
      const response = await axiosInstance.post('/api/user-verification/confirm-email', { code });
      if (response.data.verified) {
        commit('user/UPDATE_USER', { emailVerified: true }, { root: true });
      }
    } catch (error) {
      console.error('Error confirming email verification:', error);
      throw error;
    }
  },
  async confirmPhoneVerification({ commit }, code) {
    try {
      const response = await axiosInstance.post('/api/user-verification/confirm-phone', { code });
      if (response.data.verified) {
        commit('user/UPDATE_USER', { phoneVerified: true }, { root: true });
      }
      return response.data;
    } catch (error) {
      console.error('Error confirming phone verification:', error);
      throw error;
    }
  },
};

const getters = {
  // We might not need any getters here, but you can add if necessary
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
