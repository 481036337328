<template>
  <div>
    <h2>Bulk Meat Products</h2>
    <v-row>
      <v-col v-for="product in bulkMeatProducts" :key="product._id" cols="12" sm="6" md="4">
        <v-card>
          <v-card-title>{{ product.name }}</v-card-title>
          <v-card-text>
            <p>{{ product.description }}</p>
            <p>Average Dressed Weight: {{ product.averageDressedWeight }} lbs</p>
            <p>Pickup Months: {{ product.pickUpMonths.join(', ') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="showDetails(product)">View Details</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="detailsDialog" max-width="600px">
      <v-card v-if="selectedProduct">
        <v-card-title>{{ selectedProduct.name }}</v-card-title>
        <v-card-text>
          <h3>Specifications</h3>
          <ul>
            <li v-for="spec in selectedProduct.specifications" :key="spec">{{ spec }}</li>
          </ul>
          <p>{{ selectedProduct.specificationsDescription }}</p>

          <h3>Price Chart</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="price in selectedProduct.priceChart" :key="price.quantity">
                  <td>{{ price.quantity }} lbs</td>
                  <td>${{ price.price.toFixed(2) }} / lb</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <h3>Order Process</h3>
          <p>{{ selectedProduct.orderProcess }}</p>

          <h3>Pickup Process</h3>
          <p>{{ selectedProduct.pickUpProcess }}</p>

          <h3>Pickup Locations</h3>
          <ul>
            <li v-for="location in selectedProduct.pickupLocations" :key="location">{{ location }}</li>
          </ul>

          <h3>Cut Options</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Cut</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cut in selectedProduct.cutOptions" :key="cut.cut">
                  <td>{{ cut.cut }}</td>
                  <td>{{ cut.cutDescription }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      detailsDialog: false,
      selectedProduct: null
    }
  },
  computed: {
    ...mapState(['bulkMeatProducts'])
  },
  methods: {
    ...mapActions(['fetchBulkMeatProducts']),
    showDetails(product) {
      this.selectedProduct = product
      this.detailsDialog = true
    }
  },
  created() {
    this.fetchBulkMeatProducts()
  }
}
</script>
