<!-- ./frontend/src/views/NotificationsPage.vue -->
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <AccountManagementDrawer />
      </v-col>
      <v-col cols="12" md="9">
        <h1 class="text-h4 mb-6">Notifications</h1>
        <v-list v-if="notifications.length > 0">
          <v-list-item
            v-for="notification in notifications"
            :key="notification._id"
            :class="{ 'unread': !notification.isRead }"
          >
            <template #prepend>
              <v-icon :color="!notification.isRead ? 'primary' : 'grey'">
                {{ !notification.isRead ? 'mdi-email' : 'mdi-email-open' }}
              </v-icon>
            </template>
            <v-list-item-title>{{ notification.subject }}</v-list-item-title>
            <v-list-item-subtitle>{{ notification.content }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ formatDate(notification.createdAt) }}</v-list-item-subtitle>
            <template #append>
              <v-btn
                icon
                @click="markAsRead(notification._id)"
                v-if="!notification.isRead"
              >
                <v-icon>mdi-email-open</v-icon>
              </v-btn>
            </template>
          </v-list-item>
        </v-list>
        <v-alert v-else type="info">You have no notifications.</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import AccountManagementDrawer from '@/components/AccountManagementDrawer.vue'

export default {
  name: 'NotificationsPage',
  
  components: {
    AccountManagementDrawer
  },

  setup() {
    const store = useStore()
    const notifications = ref([])

    const fetchNotifications = async () => {
      try {
        const response = await store.dispatch('fetchNotifications')
        notifications.value = response
      } catch (error) {
        console.error('Error fetching notifications:', error)
      }
    }

    const markAsRead = async (id) => {
      try {
        await store.dispatch('markNotificationAsRead', id)
        await fetchNotifications()
      } catch (error) {
        console.error('Error marking notification as read:', error)
      }
    }

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString()
    }

    onMounted(fetchNotifications)

    return {
      notifications,
      markAsRead,
      formatDate,
    }
  }
}
</script>

<style scoped>
.unread {
  background-color: #e3f2fd;
}
</style>
