<!-- ./frontend/src/components/AccountWalletCard.vue -->
<template>
  <v-card outlined class="mb-6" id="wallet">
    <v-card-title class="d-flex justify-space-between align-center">
      <div>
        <v-icon left>mdi-wallet</v-icon>
        Wallet | Payment methods
      </div>
      <v-btn 
        text 
        color="primary" 
        @click="handleAddPaymentMethod"
        v-if="!showPaymentForm && (!paymentMethods || paymentMethods.length === 0)"
      >
        Add new payment method
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row v-if="paymentMethods && paymentMethods.length > 0">
        <v-col v-for="(method, index) in paymentMethods" :key="method.id" cols="12" md="6">
          <v-card :color="getCardColor(method.card.brand)" dark>
            <v-card-text>
              <div class="d-flex justify-space-between align-center">
                <div class="text-subtitle-2">{{ method.card.brand }}</div>
                <v-btn icon small @click="removePaymentMethod(method.id)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-space-between align-center mt-2">
                <div class="text-body-2">
                  Card ending in {{ method.card.last4 }}
                </div>
                <v-chip 
                  v-if="index === 0" 
                  color="white" 
                  text-color="blue darken-2" 
                  x-small
                  class="ml-2"
                >
                  Default
                </v-chip>
              </div>
              <div class="d-flex justify-space-between mt-4">
                <div>{{ method.billing_details.name || 'No name provided' }}</div>
                <div>{{ method.card.exp_month }}/{{ method.card.exp_year }}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <p class="text-body-1">No payment methods added yet.</p>
        </v-col>
      </v-row>
      <v-btn 
        text 
        color="primary" 
        @click="showPaymentForm = true" 
        v-if="!showPaymentForm && paymentMethods && paymentMethods.length > 0"
        class="mt-4"
      >
        Add new payment method
      </v-btn>
    </v-card-text>

    <v-dialog v-model="showPaymentForm" max-width="500px">
      <v-card>
        <v-card-title>Add Payment Method</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="savePaymentMethod" ref="paymentForm">
            <v-text-field
              v-model="newPaymentMethod.name"
              label="Cardholder Name"
              required
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
            <div id="payment-element" ref="paymentElementRef"></div>
            <div id="payment-errors" role="alert" v-if="paymentError" class="error--text mt-2">{{ paymentError }}</div>
            <v-checkbox
              v-model="newPaymentMethod.isDefault"
              label="Set as default payment method"
              class="mt-4"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="savePaymentMethod" :loading="processingPayment" :disabled="!stripe || !elements">Save</v-btn>
          <v-btn text @click="cancelPaymentForm">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'AccountWalletCard',
  props: {
    paymentMethods: {
      type: Array,
      required: true
    }
  },
  emits: ['add-payment-method', 'remove-payment-method'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const showPaymentForm = ref(false);
    const processingPayment = ref(false);
    const stripe = ref(null);
    const elements = ref(null);
    const paymentError = ref('');
    const paymentElementRef = ref(null);

    const user = computed(() => store.state.user);

    const newPaymentMethod = ref({
      name: '',
      isDefault: false
    });

    const getCardColor = (cardType) => {
      switch (cardType.toLowerCase()) {
        case 'visa': return 'blue darken-2';
        case 'mastercard': return 'red darken-2';
        case 'american express': return 'indigo';
        default: return 'grey darken-2';
      }
    };

    const initializeStripe = async () => {
      try {
        console.log('AccountWalletCard: Initializing Stripe');
        if (!stripe.value) {
          stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        }
        console.log('AccountWalletCard: Stripe initialized successfully');
      } catch (error) {
        console.error('AccountWalletCard: Error initializing Stripe:', error);
        paymentError.value = 'Failed to initialize payment system. Please try again later.';
      }
    };

    const fetchSetupIntent = async () => {
      try {
        console.log('AccountWalletCard: Fetching setup intent...');
        const clientSecret = await store.dispatch('payment/createSetupIntent');
        console.log('AccountWalletCard: Setup intent created successfully');
        return clientSecret;
      } catch (error) {
        console.error('AccountWalletCard: Error fetching setup intent:', error);
        paymentError.value = 'Failed to initialize payment form. Please try again.';
        throw error;
      }
    };

    const mountPaymentElement = async () => {
      console.log('AccountWalletCard: Mounting payment element');
      await nextTick();  // Ensure DOM is updated
      if (stripe.value && paymentElementRef.value) {
        try {
          const clientSecret = await fetchSetupIntent();
          if (!clientSecret) {
            throw new Error('Failed to fetch client secret');
          }

          elements.value = stripe.value.elements({
            clientSecret,
            appearance: {
              theme: 'stripe',
            },
          });

          const paymentElement = elements.value.create('payment', {
            fields: {
              billingDetails: {
                name: 'never',
                email: 'never',
                phone: 'never',
                address: {
                  country: 'never',
                  postalCode: 'auto',
                  line1: 'never',
                  line2: 'never',
                  city: 'never',
                  state: 'never',
                }
              }
            },
            wallets: {
              applePay: 'never',
              googlePay: 'never'
            }
          });

          paymentElement.mount(paymentElementRef.value);
          console.log('AccountWalletCard: Payment element mounted successfully');
        } catch (error) {
          console.error('AccountWalletCard: Error mounting payment element:', error);
          paymentError.value = 'Failed to load payment form. Please try again.';
        }
      } else {
        console.log('AccountWalletCard: Payment element not mounted', { 
          stripe: !!stripe.value, 
          elements: !!elements.value, 
          paymentElementRef: !!paymentElementRef.value 
        });
      }
    };

    const unmountPaymentElement = () => {
      if (elements.value) {
        console.log('AccountWalletCard: Unmounting payment element');
        elements.value.getElement('payment').unmount();
        elements.value = null;
        console.log('AccountWalletCard: Payment element unmounted');
      }
    };

    const savePaymentMethod = async () => {
      console.log('AccountWalletCard: Starting savePaymentMethod');
      if (!stripe.value || !elements.value) {
        console.error('AccountWalletCard: Stripe or elements not initialized');
        paymentError.value = 'Payment system is not ready. Please try again.';
        return;
      }

      if (!newPaymentMethod.value.name) {
        paymentError.value = 'Please enter the cardholder name.';
        return;
      }

      processingPayment.value = true;
      try {
        const returnUrl = `${window.location.origin}${router.resolve({ name: 'Account' }).href}`;
        console.log('AccountWalletCard: Return URL:', returnUrl);

        console.log('AccountWalletCard: Preparing billing details');
        const billingDetails = {
          name: newPaymentMethod.value.name,
          email: user.value.email,
          phone: user.value.phone,
          address: {
            country: 'CA',
            state: user.value.address?.province || '',
            city: user.value.address?.city || '',
            line1: user.value.address?.street || '',
            line2: user.value.address?.apt || '',
          }
        };
        console.log('AccountWalletCard: Billing details prepared:', JSON.stringify(billingDetails, null, 2));

        console.log('AccountWalletCard: Confirming setup with Stripe');
        console.log('AccountWalletCard: confirmSetup params:', {
          elements: !!elements.value,
          returnUrl,
          billingDetailsProvided: !!billingDetails
        });

        console.log('AccountWalletCard: Calling stripe.confirmSetup');
        const { error, setupIntent } = await stripe.value.confirmSetup({
          elements: elements.value,
          confirmParams: {
            return_url: returnUrl,
            payment_method_data: {
              billing_details: billingDetails
            }
          },
          redirect: 'if_required'
        });
        console.log('AccountWalletCard: stripe.confirmSetup completed');

        if (error) {
          console.error('AccountWalletCard: Error confirming setup', error);
          paymentError.value = error.message;
          return;
        }

        if (setupIntent.status === 'requires_action') {
          console.log('AccountWalletCard: Additional action required, redirecting...');
          // The setup intent needs additional action, Stripe will handle the redirect
          return;
        }

        await handleSetupIntentResult(setupIntent);
      } catch (error) {
        console.error('AccountWalletCard: Error in stripe.confirmSetup:', error);
        console.log('AccountWalletCard: Error details:', JSON.stringify(error, null, 2));
        paymentError.value = 'An error occurred while processing your payment method. Please try again.';
      } finally {
        processingPayment.value = false;
        console.log('AccountWalletCard: savePaymentMethod completed');
      }
    };

    const handleSetupIntentResult = async (setupIntent) => {
      console.log('AccountWalletCard: Handling setup intent result', JSON.stringify(setupIntent, null, 2));
      if (setupIntent.status === 'succeeded') {
        console.log('AccountWalletCard: Setup intent succeeded');
        await emit('add-payment-method', {
          paymentMethodId: setupIntent.payment_method,
          isDefault: newPaymentMethod.value.isDefault || props.paymentMethods.length === 0,
        });
        showPaymentForm.value = false;
        resetPaymentForm();
        console.log('AccountWalletCard: Payment method saved successfully');
      } else {
        console.error('AccountWalletCard: Unexpected setup intent status', setupIntent.status);
        paymentError.value = 'Unexpected error occurred. Please try again.';
      }
    };

    const cancelPaymentForm = () => {
      console.log('AccountWalletCard: Cancelling payment form');
      showPaymentForm.value = false;
      resetPaymentForm();
    };

    const resetPaymentForm = () => {
      console.log('AccountWalletCard: Resetting payment form');
      if (elements.value) {
        const paymentElement = elements.value.getElement('payment');
        if (paymentElement) {
          paymentElement.clear();
        }
      }
      newPaymentMethod.value = {
        name: '',
        isDefault: false
      };
      paymentError.value = '';
      console.log('AccountWalletCard: Payment form reset completed');
    };

    const removePaymentMethod = (paymentMethodId) => {
      console.log('AccountWalletCard: Removing payment method', paymentMethodId);
      emit('remove-payment-method', paymentMethodId);
    };

    const handleAddPaymentMethod = () => {
      console.log('Add new payment method button clicked');
      showPaymentForm.value = true;
    };

    onMounted(async () => {
      console.log('AccountWalletCard: Component mounted');
      await initializeStripe();
      console.log('AccountWalletCard: Initial payment methods:', props.paymentMethods);

      // Check for Stripe redirect result
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );
      if (clientSecret) {
        console.log('AccountWalletCard: Detected return from Stripe redirect');
        const { setupIntent } = await stripe.value.retrieveSetupIntent(clientSecret);
        await handleSetupIntentResult(setupIntent);
        // Clear the URL parameters
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    });

    watch(() => props.paymentMethods, (newMethods) => {
      console.log('AccountWalletCard: Payment methods updated', newMethods);
    }, { deep: true });

    watch(showPaymentForm, async (newVal) => {
      console.log('AccountWalletCard: showPaymentForm changed', newVal);
      if (newVal) {
        await nextTick(); // Ensure the dialog is rendered
        await mountPaymentElement();
      } else {
        unmountPaymentElement();
      }
    });

    return {
      showPaymentForm,
      processingPayment,
      newPaymentMethod,
      paymentError,
      stripe,
      elements,
      paymentElementRef,
      getCardColor,
      savePaymentMethod,
      cancelPaymentForm,
      removePaymentMethod,
      handleAddPaymentMethod
    };
  }
};
</script>

<style scoped>
#payment-element {
  margin-top: 20px;
}
#payment-errors {
  color: #fa755a;
  margin-top: 10px;
}
</style>
