<!-- ./frontend/src/views/SearchPage.vue -->
<template>
  <v-container class="pa-4" fluid>
    <v-row>
      <!-- Left drawer -->
      <v-navigation-drawer
        v-model="drawer"
        :permanent="$vuetify.display.mdAndUp"
        :temporary="$vuetify.display.smAndDown"
        class="pt-4"
      >
        <v-list>
          <v-list-subheader class="d-flex align-center justify-space-between">
            Categories
            <v-icon>mdi-filter</v-icon>
          </v-list-subheader>
          <v-list-group
            v-for="section in categoryTree"
            :key="section.name"
            :value="section.name"
          >
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <v-checkbox
                  v-model="selectedSections"
                  :label="section.name"
                  :value="section.name"
                  @change="applyFilters"
                  hide-details
                ></v-checkbox>
              </v-list-item>
            </template>

            <v-list-group
              v-for="category in section.categories"
              :key="category.name"
              :value="category.name"
              sub-group
            >
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <v-checkbox
                    v-model="selectedCategories"
                    :label="category.name"
                    :value="category.name"
                    @change="applyFilters"
                    hide-details
                  ></v-checkbox>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subcategory in category.subcategories"
                :key="subcategory"
                link
              >
                <v-checkbox
                  v-model="selectedSubcategories"
                  :label="subcategory"
                  :value="subcategory"
                  @change="applyFilters"
                  hide-details
                ></v-checkbox>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <!-- Main content -->
      <v-col fluid>
        <!-- Toggle drawer button for mobile -->
        <v-btn
          v-if="$vuetify.display.smAndDown"
          icon="mdi-menu"
          @click="drawer = !drawer"
          class="mb-4"
        ></v-btn>

        <!-- Quick filters -->
        <v-chip-group>
          <v-select
            v-model="selectedSeller"
            :items="sellers"
            label="Seller"
            item-title="name"
            item-value="id"
            clearable
            @update:model-value="applyFilters"
            style="max-width: 200px;"
          ></v-select>
          <v-select
            v-model="selectedOptions"
            :items="options"
            label="Options"
            multiple
            chips
            clearable
            @update:model-value="applyFilters"
            style="max-width: 200px;"
          ></v-select>
        </v-chip-group>

        <!-- Active filters -->
        <v-chip-group>
          <v-chip
            v-for="filter in activeFilters"
            :key="filter.type + filter.value"
            closable
            @click:close="removeFilter(filter)"
          >
            {{ filter.type }}: {{ filter.value }}
          </v-chip>
        </v-chip-group>

        <!-- Sort dropdown -->
        <v-select
          v-model="sortBy"
          :items="sortOptions"
          label="Sort by"
          item-title="text"
          item-value="value"
          dense
          outlined
          class="mb-4"
          style="max-width: 200px;"
        ></v-select>

        <!-- Search results -->
        <template v-if="isViewingAll">
          <h2 class="mb-4">All Products</h2>
          <v-row>
            <v-col v-for="product in allProducts" :key="product._id" cols="12" sm="6" md="4" lg="3">
              <ProductCard
                :product="product"
                :cart-quantity="cartQuantities[product._id] || 0"
                @navigate-to-product="navigateToProduct"
                @add-to-cart="addToCart"
                @increase-quantity="increaseQuantity"
                @decrease-quantity="decreaseQuantity"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <template v-if="filteredSearchResults.length > 0">
            <h2 class="mb-4">Search Results for "{{ searchQuery }}"</h2>
            <v-row>
              <v-col v-for="product in filteredSearchResults" :key="product._id" cols="12" sm="6" md="4" lg="3">
                <ProductCard
                  :product="product"
                  :cart-quantity="cartQuantities[product._id] || 0"
                  @navigate-to-product="navigateToProduct"
                  @add-to-cart="addToCart"
                  @increase-quantity="increaseQuantity"
                  @decrease-quantity="decreaseQuantity"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <p>No results found for "{{ searchQuery }}"</p>
          </template>
          
          <h2 class="mt-8 mb-4">All Products</h2>
          <v-row>
            <v-col v-for="product in filteredAllProducts" :key="product._id" cols="12" sm="6" md="4" lg="3">
              <ProductCard
                :product="product"
                :cart-quantity="cartQuantities[product._id] || 0"
                @navigate-to-product="navigateToProduct"
                @add-to-cart="addToCart"
                @increase-quantity="increaseQuantity"
                @decrease-quantity="decreaseQuantity"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'SearchPage',
  components: {
    ProductCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const display = useDisplay()
    
    const drawer = ref(display.mdAndUp.value)
    const searchQuery = ref('')
    const isViewingAll = computed(() => route.query.viewAll === 'true')
    const searchResults = computed(() => store.state.searchResults)
    const allProducts = computed(() => store.state.products)
    
    const categoryTree = computed(() => store.state.categoryTree)

    const sortBy = ref('relevance')
    const sortOptions = [
      { text: 'Relevance', value: 'relevance' },
      { text: 'Price: Low to High', value: 'price_asc' },
      { text: 'Price: High to Low', value: 'price_desc' }
    ]

    const selectedSeller = ref(null)
    const selectedOptions = ref([])
    const activeFilters = ref([])
    const selectedSections = ref([])
    const selectedCategories = ref([])
    const selectedSubcategories = ref([])

    const sellers = computed(() => {
      const uniqueSellers = new Set()
      allProducts.value.forEach(product => {
        if (product.seller && product.seller.name) uniqueSellers.add(product.seller.name)
      })
      return Array.from(uniqueSellers).map(name => ({ name, id: name }))
    })

    const options = computed(() => {
      const allOptions = new Set()
      allProducts.value.forEach(product => {
        if (product.options) {
          product.options.forEach(option => allOptions.add(option.option))
        }
        if (product.halal) {
          allOptions.add('Halal')
        }
        if (product.certifiedOrganic) {
          allOptions.add('Certified Organic')
        }
      })
      return Array.from(allOptions)
    })

    const filteredSearchResults = computed(() => {
      return applyFiltersToProducts(searchResults.value)
    })

    const filteredAllProducts = computed(() => {
      const searchResultIds = new Set(searchResults.value.map(product => product._id))
      return applyFiltersToProducts(allProducts.value.filter(product => !searchResultIds.has(product._id)))
    })

    const applyFiltersToProducts = (products) => {
      let filtered = products.filter(product => 
        (!selectedSeller.value || product.seller.name === selectedSeller.value) &&
        (selectedOptions.value.length === 0 || selectedOptions.value.every(option => {
          if (option === 'Halal') return product.halal
          if (option === 'Certified Organic') return product.certifiedOrganic
          return product.options && product.options.some(productOption => productOption.option === option)
        })) &&
        activeFilters.value.every(filter => {
          switch (filter.type) {
            case 'Section':
              return product.category && categoryTree.value.find(section => 
                section.name === filter.value && section.categories.some(cat => cat.name === product.category)
              )
            case 'Category':
              return product.category === filter.value
            case 'Subcategory':
              return product.subcategory === filter.value
            default:
              return true
          }
        })
      )

      if (sortBy.value === 'price_asc') {
        filtered.sort((a, b) => (a.weightAdjusted ? a.pricePerMeasure : a.price) - (b.weightAdjusted ? b.pricePerMeasure : b.price))
      } else if (sortBy.value === 'price_desc') {
        filtered.sort((a, b) => (b.weightAdjusted ? b.pricePerMeasure : b.price) - (a.weightAdjusted ? a.pricePerMeasure : a.price))
      }

      return filtered
    }

    const performSearch = async () => {
      console.log('performSearch called in SearchPage.vue')
      console.log('searchQuery:', searchQuery.value)
      console.log('isViewingAll:', isViewingAll.value)
      if (isViewingAll.value) {
        await store.dispatch('fetchProducts')
        await store.dispatch('saveSearchQuery', 'View All Products')
      } else if (searchQuery.value) {
        await store.dispatch('searchProducts', searchQuery.value)
        await store.dispatch('saveSearchQuery', searchQuery.value)
      }
    }

    const applyFilters = () => {
      activeFilters.value = [
        ...selectedSections.value.map(section => ({ type: 'Section', value: section })),
        ...selectedCategories.value.map(category => ({ type: 'Category', value: category })),
        ...selectedSubcategories.value.map(subcategory => ({ type: 'Subcategory', value: subcategory }))
      ]
    }

    const removeFilter = (filter) => {
      switch (filter.type) {
        case 'Section':
          selectedSections.value = selectedSections.value.filter(s => s !== filter.value)
          break
        case 'Category':
          selectedCategories.value = selectedCategories.value.filter(c => c !== filter.value)
          break
        case 'Subcategory':
          selectedSubcategories.value = selectedSubcategories.value.filter(s => s !== filter.value)
          break
      }
      applyFilters()
    }

    const cartQuantities = computed(() => {
      const quantities = {}
      store.state.cart.forEach(item => {
        quantities[item._id] = item.quantity
      })
      return quantities
    })

    const addToCart = (product) => {
      store.dispatch('addToCart', product)
    }

    const increaseQuantity = (product) => {
      store.dispatch('updateCartItemQuantity', { 
        productId: product._id, 
        quantity: (cartQuantities.value[product._id] || 0) + 1 
      })
    }

    const decreaseQuantity = (product) => {
      const currentQuantity = cartQuantities.value[product._id] || 0
      if (currentQuantity > 0) {
        store.dispatch('updateCartItemQuantity', { 
          productId: product._id, 
          quantity: currentQuantity - 1 
        })
      }
    }

    const navigateToProduct = (product) => {
      router.push(`/${product.category.toLowerCase()}/${product._id}`)
    }

    onMounted(() => {
      console.log('SearchPage mounted')
      searchQuery.value = route.query.q || ''
      performSearch()
      store.dispatch('fetchCategories')
      store.dispatch('fetchProducts')
    })

    watch(() => route.query, (newQuery) => {
      console.log('Watch triggered in SearchPage')
      console.log('New query:', newQuery)
      searchQuery.value = newQuery.q || ''
      performSearch()
    })

    return {
      drawer,
      searchQuery,
      isViewingAll,
      searchResults,
      allProducts,
      categoryTree,
      sortBy,
      sortOptions,
      filteredSearchResults,
      filteredAllProducts,
      selectedSeller,
      selectedOptions,
      sellers,
      options,
      activeFilters,
      selectedSections,
      selectedCategories,
      selectedSubcategories,
      applyFilters,
      removeFilter,
      addToCart,
      cartQuantities,
      increaseQuantity,
      decreaseQuantity,
      navigateToProduct,
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

@media (max-width: 600px) {
  .v-chip-group {
    flex-wrap: wrap;
  }

  .v-select {
    width: 100%;
    max-width: none !important;
    margin-bottom: 8px;
  }
}
</style>
