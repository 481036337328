// ./frontend/src/store/modules/address.js

import axiosInstance from '../../utils/axios'

const state = {
  addresses: [],
  temporaryPostalCode: localStorage.getItem('temporaryPostalCode') || null
};

const mutations = {
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses;
  },
  ADD_ADDRESS(state, address) {
    state.addresses.push(address);
  },
  UPDATE_ADDRESS(state, { addressId, address }) {
    const index = state.addresses.findIndex(addr => addr._id === addressId);
    if (index !== -1) {
      state.addresses.splice(index, 1, address);
    }
  },
  REMOVE_ADDRESS(state, addressId) {
    state.addresses = state.addresses.filter(address => address._id !== addressId);
  },
  SET_TEMPORARY_POSTAL_CODE(state, postalCode) {
    state.temporaryPostalCode = postalCode;
    localStorage.setItem('temporaryPostalCode', postalCode);
  },
  CLEAR_TEMPORARY_POSTAL_CODE(state) {
    state.temporaryPostalCode = null;
    localStorage.removeItem('temporaryPostalCode');
  }
};

const actions = {
  async fetchAddresses({ commit }) {
    try {
      const response = await axiosInstance.get('/api/addresses');
      commit('SET_ADDRESSES', response.data);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      throw error;
    }
  },

  async addAddress({ commit }, address) {
    try {
      const response = await axiosInstance.post('/api/addresses', address);
      commit('ADD_ADDRESS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding address:', error);
      throw error;
    }
  },

  async addPostalCodeAddress({ commit }, postalCode) {
    try {
      const response = await axiosInstance.post('/api/addresses/postal-code', {
        postalCode
      });
      commit('SET_TEMPORARY_POSTAL_CODE', postalCode);
      commit('ADD_ADDRESS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding postal code address:', error);
      throw error;
    }
  },

  async updateAddress({ commit }, { addressId, address }) {
    try {
      const response = await axiosInstance.put(`/api/addresses/${addressId}`, address);
      commit('UPDATE_ADDRESS', { addressId, address: response.data });
      return response.data;
    } catch (error) {
      console.error('Error updating address:', error);
      throw error;
    }
  },

  async removeAddress({ commit }, addressId) {
    try {
      await axiosInstance.delete(`/api/addresses/${addressId}`);
      commit('REMOVE_ADDRESS', addressId);
    } catch (error) {
      console.error('Error removing address:', error);
      throw error;
    }
  },

  async setPreferredAddress({ commit, dispatch }, addressId) {
    try {
      await axiosInstance.put(`/api/addresses/${addressId}/preferred`);
      await dispatch('fetchAddresses'); // Refresh the addresses list
    } catch (error) {
      console.error('Error setting preferred address:', error);
      throw error;
    }
  }
};

const getters = {
  getAddresses: (state) => state.addresses,
  getPreferredAddress: (state) => state.addresses.find(addr => addr.isPreferred) || null,
  getTemporaryPostalCode: (state) => state.temporaryPostalCode,
  getSortedAddresses: (state) => {
    return [...state.addresses].sort((a, b) => {
      if (a.isPreferred) return -1;
      if (b.isPreferred) return 1;
      return 0;
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
