<!-- ./frontend/src/views/AdminLocalShippingZones.vue -->
<template>
  <v-container>
    <h1>Admin: Local Shipping Zones</h1>
    <v-btn color="primary" @click="openAddDialog" class="mb-4">Add New Local Shipping Zone</v-btn>
    <v-data-table
      :headers="headers"
      :items="localShippingZones"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editLocalShippingZone(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteLocalShippingZone(item._id)">Delete</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveLocalShippingZone" ref="form">
            <v-text-field
              v-model="editedLocalShippingZone.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLocalShippingZone.province"
              label="Province"
              :rules="[v => !!v || 'Province is required']"
              required
            ></v-text-field>
            <v-textarea
              v-model="editedLocalShippingZone.description"
              label="Description"
            ></v-textarea>
            <v-combobox
              v-model="editedLocalShippingZone.RSACodes"
              label="RSA Codes"
              multiple
              chips
              small-chips
            ></v-combobox>
            <v-switch
              v-model="editedLocalShippingZone.isActive"
              label="Active"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveLocalShippingZone">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminLocalShippingZones',
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Province', value: 'province' },
        { text: 'Description', value: 'description' },
        { text: 'RSA Codes', value: 'RSACodes' },
        { text: 'Active', value: 'isActive' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedLocalShippingZone: {
        name: '',
        province: '',
        description: '',
        RSACodes: [],
        isActive: true
      },
      defaultLocalShippingZone: {
        name: '',
        province: '',
        description: '',
        RSACodes: [],
        isActive: true
      }
    };
  },
  computed: {
    ...mapState(['localShippingZones']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Local Shipping Zone' : 'Edit Local Shipping Zone';
    }
  },
  methods: {
    ...mapActions(['fetchLocalShippingZones', 'createLocalShippingZone', 'updateLocalShippingZone', 'deleteLocalShippingZone']),
    editLocalShippingZone(localShippingZone) {
      this.editedIndex = this.localShippingZones.indexOf(localShippingZone);
      this.editedLocalShippingZone = Object.assign({}, localShippingZone);
      this.dialog = true;
    },
    openAddDialog() {
      this.editedIndex = -1;
      this.editedLocalShippingZone = Object.assign({}, this.defaultLocalShippingZone);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedLocalShippingZone = Object.assign({}, this.defaultLocalShippingZone);
        this.editedIndex = -1;
      });
    },
    async saveLocalShippingZone() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedIndex > -1) {
            await this.updateLocalShippingZone(this.editedLocalShippingZone);
          } else {
            await this.createLocalShippingZone(this.editedLocalShippingZone);
          }
          this.closeDialog();
          await this.fetchLocalShippingZones();
        } catch (error) {
          console.error('Error saving local shipping zone:', error);
        }
      }
    }
  },
  created() {
    this.fetchLocalShippingZones();
  }
};
</script>
