import store from '@/store';

function getNextDayOccurrence(dayOfWeek) {
  const today = new Date();
  const targetDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(dayOfWeek.toLowerCase());
  const daysUntilTarget = (targetDay + 7 - today.getDay()) % 7;
  const nextOccurrence = new Date(today);
  nextOccurrence.setDate(today.getDate() + daysUntilTarget);
  return nextOccurrence;
}

export async function nextProductPickupDateAndTimes(product) {
  if (!product.seller || !product.seller.sellerId) {
    return null;
  }

  try {
    const sellerInfo = await store.dispatch('fetchSellerInfoById', product.seller.sellerId);
    if (!sellerInfo || !sellerInfo.pickupSchedule || sellerInfo.pickupSchedule.length === 0) {
      return null;
    }

    const now = new Date();
    const today = now.getDay();
    const currentTime = now.getHours() * 60 + now.getMinutes();

    let nextPickup = null;
    let nextPickupStartTime = '';
    let nextPickupEndTime = '';

    for (let i = 0; i < 7; i++) {
      const checkDay = (today + i) % 7;
      const schedule = sellerInfo.pickupSchedule.find(s => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][checkDay].toLowerCase() === s.pickupDay.toLowerCase());

      if (schedule) {
        const [startHour, startMinute] = schedule.pickupStartTime.split(':').map(Number);
        const [endHour, endMinute] = schedule.pickupEndTime.split(':').map(Number);
        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;

        if (i === 0 && currentTime <= endTime) {
          nextPickup = now;
          nextPickupStartTime = schedule.pickupStartTime;
          nextPickupEndTime = schedule.pickupEndTime;
          break;
        } else if (i > 0) {
          nextPickup = new Date(now);
          nextPickup.setDate(nextPickup.getDate() + i);
          nextPickupStartTime = schedule.pickupStartTime;
          nextPickupEndTime = schedule.pickupEndTime;
          break;
        }
      }
    }

    return { nextPickup, nextPickupStartTime, nextPickupEndTime };
  } catch (error) {
    console.error('Error calculating next product pickup date:', error);
    return null;
  }
}

export async function productDeliveryDate(product) {
  if (!product.seller || !product.seller.sellerId) {
    return null;
  }

  try {
    const sellerInfo = await store.dispatch('fetchSellerInfoById', product.seller.sellerId);
    if (!sellerInfo || !sellerInfo.deliverySchedule || sellerInfo.deliverySchedule.length === 0) {
      return null;
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const validSchedule = sellerInfo.deliverySchedule.find(schedule => {
      const nextDropOff = getNextDayOccurrence(schedule.dropOffDay);
      return nextDropOff >= tomorrow;
    });

    if (!validSchedule) {
      return null;
    }

    const nextDropOff = getNextDayOccurrence(validSchedule.dropOffDay);
    const readyForDelivery = getNextDayOccurrence(validSchedule.readyForDeliveryDay);

    const estimatedDeliveryDate = readyForDelivery > nextDropOff ? readyForDelivery : nextDropOff;

    return estimatedDeliveryDate;
  } catch (error) {
    console.error('Error calculating product delivery date:', error);
    return null;
  }
}

export async function productDropOffDate(product) {
  if (!product.seller || !product.seller.sellerId) {
    return null;
  }

  try {
    const sellerInfo = await store.dispatch('fetchSellerInfoById', product.seller.sellerId);
    if (!sellerInfo || !sellerInfo.deliverySchedule || sellerInfo.deliverySchedule.length === 0) {
      return null;
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const validSchedule = sellerInfo.deliverySchedule.find(schedule => {
      const nextDropOff = getNextDayOccurrence(schedule.dropOffDay);
      return nextDropOff >= tomorrow;
    });

    if (!validSchedule) {
      return null;
    }

    const nextDropOff = getNextDayOccurrence(validSchedule.dropOffDay);

    return nextDropOff;
  } catch (error) {
    console.error('Error calculating product drop-off date:', error);
    return null;
  }
}

export async function orderDeliveryDate(products) {
  const deliveryDatePromises = products.map(product => productDeliveryDate(product));
  const deliveryDates = await Promise.all(deliveryDatePromises);

  const validDates = deliveryDates.filter(date => date !== null);

  if (validDates.length === 0) {
    return null;
  }

  const latestDate = new Date(Math.max.apply(null, validDates));
  return latestDate;
}
