--- ./frontend/src/views/InvitePage.vue ---
<template>
  <v-container class="pa-md-6" fluid v-if="bulkOrderInfo">
    <v-breadcrumbs class="px-0 pt-0 text-body-2" :items="breadcrumbItems">
      <template #divider>
        <v-icon class="mx-n2" icon="mdi-chevron-right" />
      </template>
    </v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex flex-column ga-6">
          <v-img
            :src="bulkOrderInfo.image || 'https://via.placeholder.com/600x600'"
            height="600"
            rounded="lg"
          />

          <v-item-group
            v-model="selectedImage"
            class="d-flex ga-4 align-center mb-4"
            mandatory="force"
          >
            <v-item v-for="(image, index) in additionalImages" :key="index">
              <template #default="{ toggle, isSelected }">
                <v-img
                  :src="image"
                  class="flex-1-1 cursor-pointer border-md"
                  :class="isSelected ? 'border-primary border-opacity-50': 'border-opacity-0'"
                  height="90"
                  rounded="lg"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>
        </div>
      </v-col>

      <v-col class="px-md-4" cols="12" md="6">
        <div class="d-flex justify-space-between align-center">
          <div class="text-md-h4 font-weight-bold mb-2 text-h5">{{ bulkOrderInfo.animalType }} Deposit</div>
          <div class="text-h4 font-weight-light">$200</div>
        </div>

        <div class="d-flex flex-wrap align-center mb-6">
          <v-chip class="mr-2 mb-2" :color="getStatusColor(bulkOrderInfo.status)">
            {{ bulkOrderInfo.status }}
          </v-chip>
          <v-chip v-for="spec in bulkOrderInfo.specificationChart" :key="spec.specification" class="mr-2 mb-2">
            {{ spec.specification }}
          </v-chip>
          <v-chip v-if="bulkOrderInfo.halalOption" class="mr-2 mb-2">Halal</v-chip>
        </div>

        <div class="text-body-1 mb-4 text-medium-emphasis">
          {{ bulkOrderInfo.meatTypeDescription }}
        </div>

        <div class="d-flex align-center mb-2">
          <v-label class="mr-2">Cuts</v-label>
          <v-btn
            icon="mdi-information-outline"
            variant="text"
            density="comfortable"
            size="small"
            @click="showCutsDialog = true"
          />
        </div>

        <v-item-group
          v-model="selectedCut"
          class="d-flex flex-wrap ga-4 mb-6"
          mandatory
        >
          <v-item v-for="(cut, index) in bulkOrderInfo.cutOptions" :key="index" v-slot="{ isSelected, toggle }">
            <v-btn
              :color="isSelected ? 'primary' : 'surface-variant'"
              :variant="isSelected ? 'flat' : 'outlined'"
              class="text-body-2 flex-grow-1"
              rounded="lg"
              @click="toggle"
            >
              {{ cut.cut }}
            </v-btn>
          </v-item>
        </v-item-group>

        <div class="d-flex justify-space-between align-start mb-2">
          <v-label>Quantity</v-label>
          <v-btn
            class="text-none"
            color="primary"
            height="24"
            size="small"
            slim
            text="Price chart"
            variant="plain"
            @click="showPriceChart"
          />
        </div>

        <v-item-group v-model="selectedQuantity" class="d-flex ga-4 align-center mb-6 flex-wrap" mandatory="force">
          <v-item v-for="(item, index) in bulkOrderInfo.priceChart" :key="index">
            <template #default="{ toggle, isSelected }">
              <v-btn
                class="text-body-2 flex-1-1"
                :color="isSelected ? 'primary' : 'surface-variant'"
                :variant="isSelected ? 'flat' : 'outlined'"
                rounded="lg"
                @click="toggle"
              >
                {{ item.quantity }}
              </v-btn>
            </template>
          </v-item>
        </v-item-group>

        <v-sheet
          border
          class="d-flex flex-column justify-center align-center mb-4"
          color="surface-light"
          min-height="100"
          rounded="lg"
        >
          <v-icon color="medium-emphasis" icon="mdi-calendar" />
          <p class="font-weight-bold mt-2">Estimated Pickup Date</p>
          <p class="text-caption">{{ bulkOrderInfo.estimatedPickupDate }}</p>
        </v-sheet>

        <v-btn
          block
          class="text-none"
          color="primary"
          flat
          rounded="lg"
          size="large"
          text="Join Bulk Order"
          @click="joinOrder"
        />

        <v-expansion-panels
          collapse-icon="mdi-minus"
          expand-icon="mdi-plus"
          flat
          multiple
          static
          variant="accordion"
        >
          <v-expansion-panel class="py-2">
            <template #title>
              Order Process
            </template>
            <template #text>
              <p class="text-body-2 mb-4 text-medium-emphasis" v-html="formattedOrderProcess"></p>
            </template>
          </v-expansion-panel>

          <v-expansion-panel class="py-2">
            <template #title>
              Pickup Process
            </template>
            <template #text>
              <p class="text-body-2 mb-4 text-medium-emphasis">{{ bulkOrderInfo.pickupProcess }}</p>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="priceChartDialog" max-width="500px">
      <v-card>
        <v-card-title>Price Chart</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in bulkOrderInfo.priceChart" :key="item.quantity">
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.comment }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="priceChartDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCutsDialog" max-width="500px">
      <v-card>
        <v-card-title>Cuts</v-card-title>
        <v-card-text>
          <v-list density="compact" :lines="false" slim>
            <v-list-item v-for="cut in bulkOrderInfo.cutOptions" :key="cut.cut" class="mb-4">
              <template #prepend>
                <v-icon icon="mdi-circle-small" />
              </template>
              <v-list-item-title class="font-weight-bold">{{ cut.cut }}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">{{ cut.cutDescription }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showCutsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'InvitePage',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedQuantity = ref(null);
    const selectedCut = ref(null);
    const priceChartDialog = ref(false);
    const showCutsDialog = ref(false);
    const selectedImage = ref(0);

    const bulkOrderInfo = computed(() => store.state.bulkOrderInfo);

    const additionalImages = computed(() => [
      bulkOrderInfo.value?.image || 'https://via.placeholder.com/90x90',
      bulkOrderInfo.value?.additionalImage1 || 'https://via.placeholder.com/90x90',
      bulkOrderInfo.value?.additionalImage2 || 'https://via.placeholder.com/90x90',
      'https://via.placeholder.com/90x90',
    ]);

    const breadcrumbItems = computed(() => [
      { title: 'Home', to: '/' },
      { title: 'Bulk Orders', to: '/bulk-meat' },
      { title: bulkOrderInfo.value?.animalType || 'Invite', disabled: true },
    ]);

    const formattedOrderProcess = computed(() => {
      if (!bulkOrderInfo.value?.orderProcess) return '';
      return bulkOrderInfo.value.orderProcess
        .split('\n')
        .map(line => line.trim())
        .filter(line => line !== '')
        .join('<br><br>');
    });

    const joinOrder = () => {
      if (selectedQuantity.value !== null && selectedCut.value !== null) {
        const selectedQuantityValue = bulkOrderInfo.value.priceChart[selectedQuantity.value].quantity;
        const selectedCutValue = bulkOrderInfo.value.cutOptions[selectedCut.value].cut;
        router.push({
          path: `/bulk-order-deposit/${route.params.id}`,
          query: { quantity: selectedQuantityValue, cut: selectedCutValue }
        });
      } else {
        alert('Please select a cut and quantity');
      }
    };

    const showPriceChart = () => {
      priceChartDialog.value = true;
    };

    const getStatusColor = (status) => {
      switch (status) {
        case 'Open': return 'green';
        case 'Closed': return 'orange';
        case 'Fulfilled': return 'red';
        default: return 'grey';
      }
    };

    // Fetch bulk order info when component is created
    store.dispatch('fetchBulkOrderInfo', route.params.id);

    return {
      bulkOrderInfo,
      breadcrumbItems,
      selectedQuantity,
      selectedCut,
      formattedOrderProcess,
      joinOrder,
      priceChartDialog,
      showCutsDialog,
      showPriceChart,
      getStatusColor,
      selectedImage,
      additionalImages,
    };
  },
};
</script>

<style scoped>
.v-btn-group .v-btn {
  height: auto;
  min-width: 0;
  padding: 0.5rem;
}
</style>
