<!-- ./frontend/src/views/AdminOrdersView.vue -->
<template>
  <v-container class="pa-md-12">
    <section>
      <h3 class="d-flex justify-space-between align-center text-subtitle-1 font-weight-bold">
        Orders
        <v-btn
          class="text-none"
          color="primary"
          prepend-icon="mdi-file-export"
          rounded="lg"
          slim
          text="Export Orders"
          variant="flat"
          @click="exportOrders"
        />
      </h3>
      <div class="text-body-2 text-medium-emphasis mb-4 w-100 w-md-75">
        A list of all orders placed by users.
      </div>

      <!-- Summary Section -->
      <v-card class="mb-4">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div>
              <p class="text-h6">Total Orders: {{ orders.length }}</p>
              <p class="text-h6">Total Revenue: {{ formatCurrency(totalRevenue) }}</p>
            </div>
            <div>
              <p>Pending: {{ ordersByStatus.Pending || 0 }}</p>
              <p>Paid: {{ ordersByStatus.Paid || 0 }}</p>
              <p>Delivered: {{ ordersByStatus.Delivered || 0 }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- Search and Filter Section -->
      <v-card class="mb-4">
        <v-card-text>
          <v-text-field
            v-model="search"
            label="Search orders"
            prepend-icon="mdi-magnify"
            @input="applyFilters"
          ></v-text-field>
          <v-select
            v-model="statusFilter"
            :items="['All', 'Pending', 'Paid', 'Delivered']"
            label="Filter by Status"
            @change="applyFilters"
          ></v-select>
          <v-row align="center">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="minAmountInput"
                label="Min Amount"
                type="number"
                prefix="$"
                @input="updateAmountRange"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="maxAmountInput"
                label="Max Amount"
                type="number"
                prefix="$"
                @input="updateAmountRange"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <p>Range: {{ formattedAmountRange }}</p>
            </v-col>
          </v-row>
          <v-range-slider
            v-model="amountRange"
            :max="maxAmount"
            :min="0"
            :step="0.01"
            label="Total Amount Range"
            @update:model-value="updateAmountInputs"
          ></v-range-slider>
          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-data-table
        :headers="headers"
        :items="filteredOrders"
        :items-per-page="10"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template #[`item.totalAmount`]="{ item }">
          {{ formatCurrency(item.totalAmount) }}
        </template>
        <template #[`item.status`]="{ item }">
          <v-select
            v-model="item.status"
            :items="['Pending', 'Paid', 'Delivered']"
            @change="updateOrderStatus(item)"
          ></v-select>
        </template>
        <template #[`item.paymentMethod`]="{ item }">
          {{ item.paymentMethod && item.paymentMethod.lastFour ? `${item.paymentMethod.lastFour}` : 'N/A' }}
        </template>
        <template #[`item.deliveryDate`]="{ item }">
          {{ item.deliveryDate ? formatDate(item.deliveryDate) : 'N/A' }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            class="text-none mr-2"
            color="primary"
            min-width="0"
            slim
            text="View"
            variant="text"
            @click="viewOrder(item)"
          />
          <v-btn
            class="text-none"
            color="error"
            min-width="0"
            slim
            text="Delete"
            variant="text"
            @click="deleteOrder(item)"
          />
        </template>
      </v-data-table>
    </section>

    <v-dialog v-model="dialogVisible" max-width="900px">
      <v-card v-if="selectedOrder">
        <v-card-title>Order Details</v-card-title>
        <v-card-text>
          <p><strong>Order ID:</strong> {{ selectedOrder._id }}</p>
          <p><strong>User:</strong> {{ selectedOrder.userId ? selectedOrder.userId.name : 'N/A' }}</p>
          <p><strong>Total Amount:</strong> {{ formatCurrency(selectedOrder.totalAmount) }}</p>
          <p><strong>Status:</strong> {{ selectedOrder.status }}</p>
          <p><strong>Created At:</strong> {{ formatDate(selectedOrder.createdAt) }}</p>
          <p><strong>Phone Number:</strong> {{ selectedOrder.phoneNumber }}</p>
          <p><strong>Receive Text Updates:</strong> {{ selectedOrder.receiveTextUpdates ? 'Yes' : 'No' }}</p>
          <p><strong>Email:</strong> {{ selectedOrder.email }}</p>
          <p><strong>Delivery Date:</strong> {{ selectedOrder.deliveryDate ? formatDate(selectedOrder.deliveryDate) : 'N/A' }}</p>
          
          <v-divider class="my-4"></v-divider>
          
          <h4>Delivery Address:</h4>
          <p>{{ formatAddress(selectedOrder.deliveryAddress) }}</p>
          
          <v-divider class="my-4"></v-divider>
          
          <h4>Payment Method:</h4>
          <p v-if="selectedOrder.paymentMethod">
            Card Number: **** **** **** {{ selectedOrder.paymentMethod.lastFour || 'N/A' }}
          </p>
          <p v-if="selectedOrder.paymentMethod">Cardholder Name: {{ selectedOrder.paymentMethod.cardholderName }}</p>
          <p v-if="selectedOrder.paymentMethod">Expiry: {{ selectedOrder.paymentMethod.expiryMonth }}/{{ selectedOrder.paymentMethod.expiryYear }}</p>
          
          <v-divider class="my-4"></v-divider>
          
          <h4>Items:</h4>
          <v-data-table
            :headers="itemHeaders"
            :items="selectedOrder.items"
            hide-default-footer
          >
            <template #[`item.dropOffDate`]="{ item }">
              {{ item.dropOffDate ? formatDate(item.dropOffDate) : 'N/A' }}
            </template>
            <template #[`item.substitution.allowed`]="{ item }">
              {{ item.substitution.allowed ? 'Yes' : 'No' }}
            </template>
            <template #[`item.substitution.halal`]="{ item }">
              {{ item.substitution.halal ? 'Yes' : 'No' }}
            </template>
            <template #[`item.substitution.certifiedOrganic`]="{ item }">
              {{ item.substitution.certifiedOrganic ? 'Yes' : 'No' }}
            </template>
          </v-data-table>
          
          <v-divider class="my-4"></v-divider>
          
          <h4>Pickup Schedules:</h4>
          <v-data-table
            v-if="selectedOrder.pickupSchedules && selectedOrder.pickupSchedules.length > 0"
            :headers="pickupHeaders"
            :items="selectedOrder.pickupSchedules"
            hide-default-footer
          ></v-data-table>
          <p v-else>No pickup schedules for this order.</p>
          
          <v-divider class="my-4"></v-divider>
          
          <h4>Order Notes:</h4>
          <v-textarea
            v-model="orderNotes"
            label="Admin Notes"
            @input="saveOrderNotes"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ snackbar.text }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AdminOrdersView',
  setup() {
    console.log('AdminOrdersView setup function called')
    const store = useStore()
    const dialogVisible = ref(false)
    const selectedOrder = ref(null)
    const loading = ref(false)
    const search = ref('')
    const statusFilter = ref('All')
    const amountRange = ref([0, 1000])
    const minAmountInput = ref('0')
    const maxAmountInput = ref('1000')
    const startDate = ref(null)
    const endDate = ref(null)
    const startDateMenu = ref(false)
    const endDateMenu = ref(false)
    const orderNotes = ref('')
    const snackbar = ref({
      show: false,
      text: '',
      color: 'success'
    })

    const headers = [
      { title: 'Order ID', key: '_id' },
      { title: 'User', key: 'userId.name' },
      { title: 'Total Amount', key: 'totalAmount' },
      { title: 'Status', key: 'status' },
      { title: 'Created At', key: 'createdAt' },
      { title: 'Phone Number', key: 'phoneNumber' },
      { title: 'Email', key: 'email' },
      { title: 'Payment Method (last 4 digits)', key: 'paymentMethod' },
      { title: 'Delivery Date', key: 'deliveryDate' },
      { title: 'Actions', key: 'actions', sortable: false }
    ]

    const itemHeaders = [
      { title: 'Product Name', key: 'productId.name' },
      { title: 'Seller Name', key: 'seller.name' },
      { title: 'Quantity', key: 'quantity' },
      { title: 'Allow Substitutions', key: 'substitution.allowed' },
      { title: 'Halal', key: 'substitution.halal' },
      { title: 'Certified Organic', key: 'substitution.certifiedOrganic' },
      { title: 'Delivery Option', key: 'deliveryOption' },
      { title: 'Drop Off Date', key: 'dropOffDate' }
    ]

    const pickupHeaders = [
      { title: 'Seller Name', key: 'sellerName' },
      { title: 'Pickup Day', key: 'pickupDay' },
      { title: 'Start Time', key: 'pickupStartTime' },
      { title: 'End Time', key: 'pickupEndTime' },
      { title: 'Location', key: 'pickupLocation' },
      { title: 'Quantity', key: 'quantity' }
    ]

    const orders = computed(() => store.state.orders)
    const filteredOrders = ref([])

    const totalRevenue = computed(() => {
      console.log('Calculating total revenue')
      return orders.value.reduce((sum, order) => sum + order.totalAmount, 0)
    })

    const ordersByStatus = computed(() => {
      console.log('Calculating orders by status')
      return orders.value.reduce((acc, order) => {
        acc[order.status] = (acc[order.status] || 0) + 1
        return acc
      }, {})
    })

    const maxAmount = computed(() => {
      console.log('Calculating max amount')
      return Math.max(...orders.value.map(order => order.totalAmount))
    })

    const formattedAmountRange = computed(() => {
      return `${formatCurrency(amountRange.value[0])} - ${formatCurrency(amountRange.value[1])}`
    })

    const formatDate = (dateString) => {
      console.log('Formatting date:', dateString)
      return new Date(dateString).toLocaleString()
    }

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    }

    const formatAddress = (address) => {
      console.log('Formatting address:', address)
      if (!address) return 'N/A'
      return `${address.firstName} ${address.lastName}, ${address.street}, ${address.apt || ''} ${address.city}, ${address.province} ${address.postalCode}`
    }

    const viewOrder = (order) => {
      console.log('Viewing order:', order)
      selectedOrder.value = order
      dialogVisible.value = true
      orderNotes.value = order.adminNotes || ''
    }

    const deleteOrder = async (order) => {
      console.log('Deleting order:', order)
      if (confirm('Are you sure you want to delete this order?')) {
        try {
          await store.dispatch('deleteOrder', order._id)
          await fetchOrders()
          showSnackbar('Order deleted successfully', 'success')
        } catch (error) {
          console.error('Error deleting order:', error)
          showSnackbar('Error deleting order', 'error')
        }
      }
    }

    const updateOrderStatus = async (order) => {
      console.log('Updating order status:', order)
      try {
        await store.dispatch('updateOrderStatus', { id: order._id, status: order.status })
        showSnackbar('Order status updated successfully', 'success')
      } catch (error) {
        console.error('Error updating order status:', error)
        showSnackbar('Error updating order status', 'error')
      }
    }

    const fetchOrders = async () => {
      console.log('Fetching orders')
      loading.value = true
      try {
        await store.dispatch('user/fetchOrders')
        applyFilters()
      } catch (error) {
        console.error('Error fetching orders:', error)
        showSnackbar('Error fetching orders', 'error')
      } finally {
        loading.value = false
      }
    }

    const applyFilters = () => {
      console.log('Applying filters')
      console.log('Current filters:', { search: search.value, statusFilter: statusFilter.value, amountRange: amountRange.value, startDate: startDate.value, endDate: endDate.value })
      filteredOrders.value = orders.value.filter(order => {
        const matchesSearch = search.value === '' || 
          (order._id && order._id.toLowerCase().includes(search.value.toLowerCase())) ||
          (order.userId?.name && order.userId.name.toLowerCase().includes(search.value.toLowerCase())) ||
          (order.email && order.email.toLowerCase().includes(search.value.toLowerCase())) ||
          (order.phoneNumber && order.phoneNumber.includes(search.value))
        const matchesStatus = statusFilter.value === 'All' || order.status === statusFilter.value
        const matchesAmount = order.totalAmount >= amountRange.value[0] && order.totalAmount <= amountRange.value[1]
        
        const orderDate = new Date(order.createdAt)
        const matchesDate = (!startDate.value || orderDate >= new Date(startDate.value)) &&
                            (!endDate.value || orderDate <= new Date(endDate.value))

        return matchesSearch && matchesStatus && matchesAmount && matchesDate
      })
      console.log('Filtered orders:', filteredOrders.value.length)
    }

    const updateAmountRange = () => {
      const min = parseFloat(minAmountInput.value)
      const max = parseFloat(maxAmountInput.value)
      if (!isNaN(min) && !isNaN(max) && min <= max) {
        amountRange.value = [min, max]
      }
    }

    const updateAmountInputs = () => {
      minAmountInput.value = amountRange.value[0].toString()
      maxAmountInput.value = amountRange.value[1].toString()
    }

    const exportOrders = () => {
      console.log('Exporting orders')
      // Implement CSV export logic here
      console.log('Orders exported')
    }

    const saveOrderNotes = async () => {
      console.log('Saving order notes')
      try {
        await store.dispatch('updateOrderNotes', { id: selectedOrder.value._id, notes: orderNotes.value })
        showSnackbar('Order notes saved successfully', 'success')
      } catch (error) {
        console.error('Error saving order notes:', error)
        showSnackbar('Error saving order notes', 'error')
      }
    }

    const showSnackbar = (text, color) => {
      console.log('Showing snackbar:', { text, color })
      snackbar.value = {
        show: true,
        text,
        color
      }
    }

    onMounted(() => {
      console.log('AdminOrdersView mounted')
      fetchOrders()
    })

    watch([search, statusFilter, amountRange, startDate, endDate], () => {
      console.log('Filters changed, applying filters')
      applyFilters()
    })

    watch([minAmountInput, maxAmountInput], updateAmountRange)
    watch(amountRange, updateAmountInputs)

    return {
      dialogVisible,
      selectedOrder,
      headers,
      itemHeaders,
      pickupHeaders,
      orders,
      filteredOrders,
      loading,
      search,
      statusFilter,
      amountRange,
      minAmountInput,
      maxAmountInput,
      startDate,
      endDate,
      startDateMenu,
      endDateMenu,
      orderNotes,
      snackbar,
      totalRevenue,
      ordersByStatus,
      maxAmount,
      formattedAmountRange,
      formatDate,
      formatCurrency,
      formatAddress,
      viewOrder,
      deleteOrder,
      updateOrderStatus,
      applyFilters,
      updateAmountRange,
      updateAmountInputs,
      exportOrders,
      saveOrderNotes
    }
  }
}
</script>

<style scoped>
.v-data-table {
  background-color: transparent;
}
</style>
