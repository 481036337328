// frontend/src/utils/facebook-sdk.js

export function initFacebookSdk() {
  return new Promise((resolve) => {
    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie     : true,
        xfbml      : true,
        version    : 'v11.0'
      });
      
      window.FB.AppEvents.logPageView();
      resolve(window.FB);
    };
  });
}
