<!-- ./frontend/src/views/ProductOverviewPage.vue -->
<template>
  <v-container class="pa-md-6" fluid v-if="product">
    <v-breadcrumbs class="px-0 pt-0 text-body-2" :items="breadcrumbItems">
      <template #divider>
        <v-icon class="mx-n2" icon="mdi-chevron-right" />
      </template>
    </v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex flex-column ga-6">
          <v-img
            :src="currentImage"
            height="600"
            rounded="lg"
          />

          <v-item-group
            v-model="selectedImage"
            class="d-flex ga-4 align-center mb-4"
            mandatory="force"
          >
            <v-item v-for="(image, index) in allImages" :key="index">
              <template #="{ toggle, isSelected }">
                <v-img
                  :src="image"
                  class="flex-1-1 cursor-pointer border-md"
                  :class="isSelected ? 'border-primary border-opacity-50': 'border-opacity-0'"
                  height="90"
                  rounded="lg"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>
        </div>
      </v-col>

      <v-col class="px-md-4" cols="12" md="6">
        <div class="d-flex justify-space-between align-center">
          <div class="text-md-h4 font-weight-bold mb-2 text-h5">{{ product.name }}</div>
          <div class="text-h4 font-weight-light" v-if="product.weightAdjusted">
            ${{ product.price }}*
          </div>
          <div class="text-h4 font-weight-light" v-else>${{ product.price }}</div>
        </div>

        <div v-if="product.weightAdjusted" class="text-right">
          {{ product.pricePerMeasure }} / {{ product.weightMeasure }}
        </div>

        <div class="d-flex flex-wrap align-center mb-6">
          <v-chip class="mr-2 mb-2" color="charcoal">
            {{ product.subcategory }}
          </v-chip>
          <v-chip v-for="spec in product.specifications" :key="spec" class="mr-2 mb-2">
            {{ spec }}
          </v-chip>
          <v-chip v-if="product.halal" class="mr-2 mb-2">Halal</v-chip>
        </div>

        <div class="text-body-1 mb-4 text-medium-emphasis">
          {{ product.description }}
        </div>

        <div class="d-flex align-center mb-2" v-if="product.options && product.options.length > 0">
          <v-label class="mr-2">Options</v-label>
          <v-select
            v-model="selectedOption"
            :items="product.options"
            item-title="option"
            item-value="option"
            return-object
            @update:model-value="updatePrice"
          ></v-select>
        </div>

        <v-sheet
          v-if="seller"
          border
          class="mb-4 cursor-pointer seller-button"
          color="white"
          rounded="lg"
        >
          <div class="d-flex align-center pa-4">
            <v-icon color="primary" icon="mdi-store" class="mr-2" />
            <router-link 
              :to="sellerPageUrl"
              class="font-weight-medium seller-name"
            >
              {{ seller.name }}
            </router-link>
          </div>
          <v-img
            v-if="seller.coverPhoto"
            :src="seller.coverPhoto"
            height="200"
            cover
            class="seller-cover-photo"
          />
        </v-sheet>

        <div class="mb-4">
          <div v-if="product.deliveryOptions.includes('Delivery')" class="d-flex align-center mb-2">
            <v-icon color="primary" class="mr-2">mdi-truck-delivery</v-icon>
            <span class="font-weight-medium">Next delivery date:</span>
            <span class="ml-2">{{ formattedDeliveryDate }}</span>
          </div>
          <div v-if="product.deliveryOptions.includes('Pickup')" class="d-flex align-center">
            <v-icon color="primary" class="mr-2">mdi-store-clock</v-icon>
            <span class="font-weight-medium" v-html="formattedPickupDate"></span>
          </div>
        </div>

        <div v-if="product.weightAdjusted" class="mb-4 text-caption">
          *Price shown is an estimate based on average weight. The final price may be adjusted up/down based on the final weight.
        </div>

        <div v-if="cartQuantity > 0" class="d-flex align-center mb-4 quantity-control">
          <v-btn 
            icon="mdi-minus" 
            @click="decrementQuantity"
            variant="text"
            :class="{ 'active-icon': lastClicked === 'minus' }"
          ></v-btn>
          <span class="mx-2">{{ cartQuantity }}</span>
          <v-btn 
            icon="mdi-plus" 
            @click="incrementQuantity"
            variant="text"
            :class="{ 'active-icon': lastClicked === 'plus' }"
          ></v-btn>
        </div>
        <v-btn
          v-else
          block
          class="text-none mb-4"
          color="success"
          flat
          rounded="lg"
          size="large"
          text="Add to Cart"
          @click="addToCart"
        />

        <div class="d-flex justify-space-between mb-4">
          <v-btn
            :color="isRecommended || isLifeChanging ? 'primary' : ''"
            @click="handleRecommendClick"
          >
            <v-icon left class="mr-2">mdi-thumb-up</v-icon>
            Recommend
          </v-btn>
          <v-btn
            :color="isSaved ? 'primary' : ''"
            @click="toggleSaveProduct"
          >
            <v-icon left class="mr-2">mdi-bookmark</v-icon>
            {{ isSaved ? 'Remove from saved' : 'Save item' }}
          </v-btn>
          <v-btn @click="shareProduct">
            <v-icon left class="mr-2">mdi-share-variant</v-icon>
            Share
          </v-btn>
        </div>

        <v-expansion-panels
          collapse-icon="mdi-minus"
          expand-icon="mdi-plus"
          flat
          multiple
          static
          variant="accordion"
        >
          <v-expansion-panel class="py-2">
            <template #title>
              <v-icon left color="charcoal" class="mr-2">mdi-thumb-up</v-icon>
              Recommendations ({{ product.countRecommendations }})
            </template>
            <template #text>
              <p class="text-body-2 mb-4 text-medium-emphasis">
                This product has been recommended {{ product.countRecommendations }} times.
              </p>
            </template>
          </v-expansion-panel>

          <v-expansion-panel class="py-2">
            <template #title>
              <v-icon left color="charcoal" class="mr-2">mdi-heart</v-icon>
              Lives Changed ({{ product.countChangedMyLife }})
            </template>
            <template #text>
              <p class="text-body-2 mb-4 text-medium-emphasis">
                This product has changed {{ product.countChangedMyLife }} lives.
              </p>
            </template>
          </v-expansion-panel>

          <v-expansion-panel class="py-2">
            <template #title>
              Product Details
            </template>
            <template #text>
              <p class="text-body-2 mb-4 text-medium-emphasis">
                Category: {{ product.category }}<br>
                Subcategory: {{ product.subcategory }}<br>
                Weight Adjusted: {{ product.weightAdjusted ? 'Yes' : 'No' }}<br>
                Weight Measure: {{ product.weightMeasure }}<br>
                Average Weight: {{ product.averageWeight }} {{ product.weightMeasure }}<br>
                Halal: {{ product.halal ? 'Yes' : 'No' }}
              </p>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="commentDialog" max-width="500px">
      <v-card>
        <v-card-title>Leave a recommendation</v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="isLifeChanging"
            label="Recommend as a life changing product"
          ></v-checkbox>
          <v-textarea v-model="commentText" label="Comment"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submitRecommendation">Submit</v-btn>
          <v-btn text @click="cancelRecommendation">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { productDeliveryDate, nextProductPickupDateAndTimes } from '@/utils/dateUtils';

export default {
  name: 'ProductOverviewPage',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const product = ref(null);
    const seller = ref(null);
    const selectedImage = ref(0);
    const snackbar = ref(false);
    const snackbarMessage = ref('');
    const selectedOption = ref(null);
    const commentDialog = ref(false);
    const commentText = ref('');
    const isRecommended = ref(false);
    const isLifeChanging = ref(false);
    const isSaved = ref(false);
    const lastClicked = ref(null);
    const nextDeliveryDate = ref(null);
    const nextPickup = ref(null);

    const allImages = computed(() => {
      if (!product.value) return [];
      return [product.value.productImage, ...(product.value.otherImages || [])].filter(Boolean);
    });

    const currentImage = computed(() => allImages.value[selectedImage.value] || '');

    const cartQuantity = computed(() => {
      const cartItem = store.state.cart.find(item => item._id === product.value?._id);
      return cartItem ? cartItem.quantity : 0;
    });

    const sellerPageUrl = computed(() => {
      if (seller.value && seller.value.url) {
        return `/seller/${seller.value.url}`;
      }
      return null;
    });

    const breadcrumbItems = computed(() => [
      { title: 'Home', to: '/' },
      { title: product.value?.category || 'Product', to: `/${product.value?.category.toLowerCase()}` },
      { title: product.value?.name || 'Product Details', disabled: true },
    ]);

    const formattedDeliveryDate = computed(() => {
      if (!nextDeliveryDate.value) return 'Not available';
      return nextDeliveryDate.value.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
    });

    const formatTime = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };

    const formattedPickupDate = computed(() => {
      if (!nextPickup.value || !nextPickup.value.nextPickup) return 'Not available';
      const { nextPickup: date, nextPickupStartTime, nextPickupEndTime } = nextPickup.value;
      const formattedStartTime = formatTime(nextPickupStartTime);
      const formattedEndTime = formatTime(nextPickupEndTime);
      return `Next pickup date: ${date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })} from ${formattedStartTime} to ${formattedEndTime}`;
    });

    const updatePrice = () => {
      if (product.value.weightAdjusted && selectedOption.value) {
        const basePrice = product.value.pricePerMeasure * product.value.averageWeight;
        const adjustmentFactor = selectedOption.value.weightedPriceAdjustment || 1;
        product.value.price = basePrice * adjustmentFactor;
      } else if (selectedOption.value) {
        product.value.price += selectedOption.value.priceAdjustment || 0;
      }
    };

    const addToCart = () => {
      if (product.value) {
        store.dispatch('addToCart', { ...product.value, quantity: 1 });
        snackbarMessage.value = 'Item added to cart';
        snackbar.value = true;
      }
    };

    const incrementQuantity = () => {
      store.dispatch('updateCartItemQuantity', { 
        productId: product.value._id, 
        quantity: cartQuantity.value + 1 
      });
    };

    const decrementQuantity = () => {
      if (cartQuantity.value > 0) {
        store.dispatch('updateCartItemQuantity', { 
          productId: product.value._id, 
          quantity: cartQuantity.value - 1 
        });
      }
    };

    const shareProduct = (event) => {
      event.preventDefault();
      const productUrl = `${window.location.origin}${route.path}`;
      navigator.clipboard.writeText(productUrl).then(() => {
        snackbarMessage.value = 'Product link copied to clipboard';
        snackbar.value = true;
      });
    };

    const toggleSaveProduct = async (event) => {
      event.preventDefault();
      if (isSaved.value) {
        await store.dispatch('removeFromSavedItems', product.value._id);
        snackbarMessage.value = 'Product removed from saved items';
      } else {
        await store.dispatch('addToSavedItems', product.value);
        snackbarMessage.value = 'Product added to saved items';
      }
      isSaved.value = !isSaved.value;
      snackbar.value = true;
    };

    const handleRecommendClick = async (event) => {
      event.preventDefault();
      if (isRecommended.value || isLifeChanging.value) {
        try {
          await store.dispatch('deleteRecommendation', { 
            productId: product.value._id, 
            type: isLifeChanging.value ? 'changedLife' : 'recommendation' 
          });
          isRecommended.value = false;
          isLifeChanging.value = false;
          snackbarMessage.value = 'Recommendation removed successfully';
          snackbar.value = true;
          await updateRecommendationCounts();
        } catch (error) {
          console.error('Error removing recommendation:', error);
          snackbarMessage.value = 'Error removing recommendation';
          snackbar.value = true;
        }
      } else {
        commentDialog.value = true;
      }
    };

    const submitRecommendation = async () => {
      try {
        const recommendationType = isLifeChanging.value ? 'changedLife' : 'recommendation';
        await store.dispatch('createRecommendation', {
          productId: product.value._id,
          comment: commentText.value,
          type: [recommendationType]
        });
        isRecommended.value = true;
        commentDialog.value = false;
        commentText.value = '';
        snackbarMessage.value = 'Recommendation submitted successfully';
        snackbar.value = true;
        await updateRecommendationCounts();
      } catch (error) {
        console.error('Error submitting recommendation:', error);
        snackbarMessage.value = 'Error submitting recommendation';
        snackbar.value = true;
      }
    };

    const cancelRecommendation = () => {
      commentDialog.value = false;
      commentText.value = '';
    };

    const updateRecommendationCounts = async () => {
      try {
        const updatedProduct = await store.dispatch('fetchProductById', product.value._id);
        product.value.countRecommendations = updatedProduct.countRecommendations;
        product.value.countChangedMyLife = updatedProduct.countChangedMyLife;
      } catch (error) {
        console.error('Error updating recommendation counts:', error);
      }
    };

    const fetchSellerData = async (sellerId) => {
      try {
        if (!sellerId) {
          console.error('Seller ID is undefined');
          return;
        }
        const sellerData = await store.dispatch('fetchSellerById', sellerId);
        seller.value = sellerData;
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    };

    onMounted(async () => {
      const productId = route.params.id;
      try {
        const productData = await store.dispatch('fetchProductById', productId);
        product.value = productData;
        if (product.value && product.value.seller && product.value.seller.sellerId) {
          await fetchSellerData(product.value.seller.sellerId);
        }
        if (product.value.options && product.value.options.length > 0) {
          selectedOption.value = product.value.options[0];
        }
        const userRecommendations = await store.dispatch('getUserRecommendations', product.value._id);
        isRecommended.value = userRecommendations.some(r => r.type.includes('recommendation'));
        isLifeChanging.value = userRecommendations.some(r => r.type.includes('changedLife'));

        const savedItems = await store.dispatch('fetchSavedItems');
        isSaved.value = savedItems.some(item => item._id === product.value._id);

        // Calculate next delivery and pickup dates
        nextDeliveryDate.value = await productDeliveryDate(product.value);
        nextPickup.value = await nextProductPickupDateAndTimes(product.value);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    });

    return {
      product,
      seller,
      sellerPageUrl,
      selectedImage,
      snackbar,
      snackbarMessage,
      allImages,
      currentImage,
      addToCart,
      selectedOption,
      updatePrice,
      cartQuantity,
      incrementQuantity,
      decrementQuantity,
      shareProduct,
      isRecommended,
      isLifeChanging,
      handleRecommendClick,
      submitRecommendation,
      cancelRecommendation,
      commentDialog,
      commentText,
      isSaved,
      toggleSaveProduct,
      formattedDeliveryDate,
      formattedPickupDate,
      lastClicked,
      breadcrumbItems,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.seller-button {
  overflow: hidden;
}

.seller-cover-photo {
  width: 100%;
}

.seller-name {
  text-decoration: none;
  color: inherit;
}

.font-weight-medium {
  font-size: 1rem;
}

.quantity-control {
  background-color: #4CAF50;
  border-radius: 9999px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 40px;
}

.quantity-control .v-btn {
  color: white !important;
  min-width: 36px;
  width: 36px;
  height: 36px;
}

.quantity-control span {
  color: white;
  flex-grow: 1;
  text-align: center;
}

.active-icon {
  color: #2E7D32 !important;
}
</style>
