import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_API_URL || 'https://loquo-app-001f963206e5.herokuapp.com'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true
})

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, error => Promise.reject(error))

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token')
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
