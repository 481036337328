<!-- ./frontend/src/views/SubscriptionDetailsPage.vue -->
<template>
  <v-container>
    <h1 class="text-h4 mb-6">Subscription Details</h1>
    <v-card v-if="user && user.subscription && user.subscription.status === 'active'" class="mb-6">
      <v-card-text>
        <p><strong>Status:</strong> {{ user.subscription.status }}</p>
        <p><strong>Plan:</strong> {{ user.subscription.plan.name }}</p>
        <p><strong>Amount:</strong> ${{ (user.subscription.plan.amount / 100).toFixed(2) }} / {{ user.subscription.plan.interval }}</p>
        <p><strong>Current Period Start:</strong> {{ formatDate(user.subscription.currentPeriodStart) }}</p>
        <p><strong>Current Period End:</strong> {{ formatDate(user.subscription.currentPeriodEnd) }}</p>
        <p v-if="user.subscription.cancelAt">
          <strong>Cancels On:</strong> {{ formatDate(user.subscription.cancelAt) }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          color="error" 
          @click="cancelSubscription" 
          :disabled="user.subscription.cancelAt !== null"
        >
          {{ user.subscription.cancelAt ? 'Cancellation Scheduled' : 'Cancel Subscription' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-alert v-else-if="!isPremiumSubscriber" type="info">
      You don't have an active subscription.
      <v-btn 
        color="primary" 
        class="mt-4"
        @click="subscribeToPremium"
      >
        Subscribe to Loquo Premium
      </v-btn>
    </v-alert>
    <v-alert v-else-if="loading" type="info">
      Loading subscription details...
    </v-alert>
    <v-alert v-else type="error">
      An error occurred while fetching subscription details. Please try again later.
    </v-alert>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SubscriptionDetailsPage',
  setup() {
    console.log('SubscriptionDetailsPage setup function called')
    const store = useStore()
    const loading = ref(true)
    const error = ref(null)

    const user = computed(() => store.state.user)
    console.log('User data:', user.value)

    const isPremiumSubscriber = computed(() => {
      const isPremium = store.getters.isPremiumUser
      console.log('isPremiumSubscriber computed:', isPremium)
      return isPremium
    })

    const fetchUserDetails = async () => {
      console.log('fetchUserDetails called')
      loading.value = true
      error.value = null
      try {
        await store.dispatch('user/fetchUserDetails')
        await store.dispatch('syncSubscriptionDetails')
      } catch (err) {
        console.error('Error fetching user details:', err)
        error.value = err
      } finally {
        loading.value = false
        console.log('fetchUserDetails completed. Loading:', loading.value)
      }
    }

    const cancelSubscription = async () => {
      console.log('cancelSubscription called')
      try {
        console.log('Dispatching cancelSubscription action')
        await store.dispatch('cancelSubscription')
        console.log('Subscription cancelled, fetching updated details')
        await fetchUserDetails()
      } catch (err) {
        console.error('Error cancelling subscription:', err)
        // Handle error (e.g., show an error message to the user)
      }
    }

    const subscribeToPremium = async () => {
      console.log('subscribeToPremium called')
      try {
        // Implement the logic to start a new subscription
        // This might involve redirecting to a payment page or opening a modal
        console.log('Subscribing to Loquo Premium')
        // Example: await store.dispatch('startNewSubscription')
      } catch (err) {
        console.error('Error starting new subscription:', err)
        // Handle error (e.g., show an error message to the user)
      }
    }

    const formatDate = (date) => {
      console.log('formatDate called with:', date)
      if (!date) return 'N/A'
      const formattedDate = new Date(date).toLocaleDateString()
      console.log('Formatted date:', formattedDate)
      return formattedDate
    }

    onMounted(() => {
      console.log('SubscriptionDetailsPage mounted')
      fetchUserDetails()
    })

    watch(user, (newValue, oldValue) => {
      console.log('user watcher triggered')
      console.log('Old user value:', oldValue)
      console.log('New user value:', newValue)
    })

    watch(loading, (newValue, oldValue) => {
      console.log('loading watcher triggered')
      console.log('Old loading value:', oldValue)
      console.log('New loading value:', newValue)
    })

    watch(error, (newValue, oldValue) => {
      console.log('error watcher triggered')
      console.log('Old error value:', oldValue)
      console.log('New error value:', newValue)
    })

    return {
      user,
      isPremiumSubscriber,
      loading,
      error,
      cancelSubscription,
      subscribeToPremium,
      formatDate
    }
  }
}
</script>
