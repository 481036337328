<!-- ./frontend/src/views/SavedRecommendedItemsPage.vue -->
<template>
  <v-container fluid>
    <v-row>
        <AccountManagementDrawer />

      <v-col fluid>
        <h1 class="text-h4 mb-6">Your Saved/Recommended Items</h1>

        <h2 class="text-h5 mb-4">Saved Items</h2>
        <v-row v-if="savedItems.length">
          <v-col v-for="item in savedItems" :key="item._id" cols="12" sm="6" md="4">
            <ProductCard
              v-if="item"
              :product="item"
              :cart-quantity="cartQuantities[item._id] || 0"
              @navigate-to-product="navigateToProduct"
              @add-to-cart="addToCart"
              @increase-quantity="increaseQuantity"
              @decrease-quantity="decreaseQuantity"
            />
          </v-col>
        </v-row>
        <p v-else>You have no saved items.</p>

        <h2 class="text-h5 mb-4 mt-6">Recommended Items</h2>
        <v-row v-if="recommendedItems.length">
          <v-col v-for="item in recommendedItems" :key="item._id" cols="12" sm="6" md="4">
            <ProductCard
              v-if="item.productId"
              :product="item.productId"
              :cart-quantity="cartQuantities[item.productId._id] || 0"
              @navigate-to-product="navigateToProduct"
              @add-to-cart="addToCart"
              @increase-quantity="increaseQuantity"
              @decrease-quantity="decreaseQuantity"
            />
          </v-col>
        </v-row>
        <p v-else>You have no recommended items.</p>

        <h2 class="text-h5 mb-4 mt-6">Life-Changing Items</h2>
        <v-row v-if="lifeChangingItems.length">
          <v-col v-for="item in lifeChangingItems" :key="item._id" cols="12" sm="6" md="4">
            <ProductCard
              v-if="item.productId"
              :product="item.productId"
              :cart-quantity="cartQuantities[item.productId._id] || 0"
              @navigate-to-product="navigateToProduct"
              @add-to-cart="addToCart"
              @increase-quantity="increaseQuantity"
              @decrease-quantity="decreaseQuantity"
            />
          </v-col>
        </v-row>
        <p v-else>You have no life-changing items.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AccountManagementDrawer from '@/components/AccountManagementDrawer.vue';
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'SavedRecommendedItemsPage',
  components: {
    AccountManagementDrawer,
    ProductCard
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const savedItems = computed(() => store.state.savedItems || []);
    const recommendedItems = computed(() => 
      (store.state.userRecommendations || [])
        .filter(r => r.type.includes('recommendation') && r.productId)
    );
    const lifeChangingItems = computed(() => 
      (store.state.userRecommendations || [])
        .filter(r => r.type.includes('changedLife') && r.productId)
    );
    const cartQuantities = computed(() => store.getters.cartQuantities || {});

    const navigateToProduct = (product) => {
      router.push(`/${product.category.toLowerCase()}/${product._id}`);
    };

    const addToCart = (product) => {
      store.dispatch('addToCart', product);
    };

    const increaseQuantity = (product) => {
      store.dispatch('updateCartItemQuantity', { 
        productId: product._id, 
        quantity: (cartQuantities.value[product._id] || 0) + 1 
      });
    };

    const decreaseQuantity = (product) => {
      const currentQuantity = cartQuantities.value[product._id] || 0;
      if (currentQuantity > 0) {
        store.dispatch('updateCartItemQuantity', { 
          productId: product._id, 
          quantity: currentQuantity - 1 
        });
      }
    };

    onMounted(async () => {
      await store.dispatch('fetchSavedItems');
      await store.dispatch('fetchUserRecommendations');
    });

    return {
      savedItems,
      recommendedItems,
      lifeChangingItems,
      cartQuantities,
      navigateToProduct,
      addToCart,
      increaseQuantity,
      decreaseQuantity
    };
  }
};
</script>
