<template>
  <v-container>
    <h1>Admin: All Bulk Orders</h1>
    <v-data-table
      :headers="headers"
      :items="bulkOrders"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.participants`]="{ item }">
        {{ item.participants.length }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-select
          v-model="item.status"
          :items="statusOptions"
          dense
        ></v-select>
        <v-btn
          small
          color="primary"
          @click="updateStatus(item._id, item.status)"
        >
          Update Status
        </v-btn>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          small
          color="primary"
          @click="viewDetails(item)"
        >
          View Details
        </v-btn>
        <v-btn
          small
          color="error"
          @click="confirmDelete(item._id)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="detailsDialog" max-width="800px">
      <v-card>
        <v-card-title>Bulk Order Details</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <tbody>
                <tr v-for="(value, key) in selectedOrder" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="detailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this bulk order?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteConfirmed">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminBulkOrdersView',
  data() {
    return {
      headers: [
        { text: 'Meat Type', align: 'start', sortable: true, value: 'meatType' },
        { text: 'Location', align: 'start', sortable: true, value: 'location' },
        { text: 'Target Quantity', align: 'end', sortable: true, value: 'targetQuantity' },
        { text: 'Current Quantity', align: 'end', sortable: true, value: 'currentQuantity' },
        { text: 'Status', align: 'start', sortable: true, value: 'status' },
        { text: 'Participants', align: 'end', sortable: true, value: 'participants' },
        { text: 'Estimated Pickup Date', align: 'start', sortable: true, value: 'estimatedPickupDate' },
        { text: 'Actions', align: 'center', sortable: false, value: 'actions' },
      ],
      detailsDialog: false,
      deleteDialog: false,
      selectedOrder: null,
      orderToDeleteId: null,
      statusOptions: ['Open', 'Closed', 'Fulfilled'],
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      }
    };
  },
  computed: {
    ...mapState(['bulkOrders']),
  },
  methods: {
    ...mapActions(['fetchAllBulkOrders', 'deleteBulkOrder', 'updateBulkOrderStatus']),
    viewDetails(item) {
      this.selectedOrder = item;
      this.detailsDialog = true;
    },
    async updateStatus(id, newStatus) {
      try {
        console.log('Updating status:', id, newStatus);
        await this.updateBulkOrderStatus({ id, status: newStatus });
        console.log('Status updated successfully');
        this.showSnackbar('Status updated successfully', 'success');
        await this.fetchAllBulkOrders();
      } catch (error) {
        console.error('Error updating status:', error);
        this.showSnackbar('Failed to update status', 'error');
      }
    },
    confirmDelete(id) {
      this.orderToDeleteId = id;
      this.deleteDialog = true;
    },
    async deleteConfirmed() {
      if (this.orderToDeleteId) {
        try {
          await this.deleteBulkOrder(this.orderToDeleteId);
          this.showSnackbar('Bulk order deleted successfully', 'success');
          this.deleteDialog = false;
          this.orderToDeleteId = null;
          await this.fetchAllBulkOrders();
        } catch (error) {
          console.error('Error deleting bulk order:', error);
          this.showSnackbar('Failed to delete bulk order', 'error');
        }
      }
    },
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    }
  },
  created() {
    this.fetchAllBulkOrders();
  },
};
</script>
