<!-- ./frontend/src/App.vue -->
<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app width="240">
      <v-list-item class="px-2 py-1">
        <div class="d-flex align-center">
          <v-btn icon @click="drawer = !drawer" class="transparent mr-2">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <router-link to="/" class="text-decoration-none">
            <v-img
              :src="logoLight"
              :width="140"
              :height="38"
              contain
              class="mr-2"
              @load="onLogoLoad"
              @error="onLogoError"
            ></v-img>
          </router-link>
        </div>
      </v-list-item>
      <v-list-item @click="viewAll" link>
        <template #prepend>
          <v-icon size="small">mdi-shopping</v-icon>
        </template>
        <v-list-item-title>All products</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <template v-for="section in sections" :key="section.title">
          <v-list-subheader>{{ section.title }}</v-list-subheader>
          <v-list-item v-for="item in section.items" :key="item.title" :to="item.to" link>
            <template #prepend>
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :color="'#FFFFFF'" app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      
      <router-link to="/" class="text-decoration-none d-flex align-center">
        <v-img
          :src="logoLight"
          :width="140"
          :height="38"
          contain
          class="mr-2"
          @load="onLogoLoad"
          @error="onLogoError"
        ></v-img>
      </router-link>

      <v-spacer class="d-none d-sm-flex"></v-spacer>

      <v-form @submit.prevent="performSearch" class="d-none d-sm-flex align-center search-form">
        <v-text-field
          v-model="searchQuery"
          bg-color="transparent"
          density="compact"
          flat
          hide-details
          label="Search"
          single-line
          variant="outlined"
          class="custom-search-input"
        >
          <template #append-inner>
            <v-btn
              icon
              variant="text"
              color="surface-light"
              class="search-icon-btn"
              @click="performSearch"
            >
              <v-icon color="black">mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-form>

      <!-- Location Button for desktop -->
      <v-btn
        class="mr-2 location-btn d-none d-sm-flex"
        variant="text"
        @click="openLocationDialog"
      >
        <v-icon left>mdi-map-marker</v-icon>
        {{ currentLocation }}
      </v-btn>

      <v-spacer class="d-none d-sm-flex"></v-spacer>

      <v-menu v-if="isAdmin" open-on-hover offset-y>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon color="black">mdi-shield-account-outline</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300" class="nav-menu" elevation="5" rounded="xl">
          <v-card-text class="pa-6">
            <h2 class="text-h5 font-weight-bold mb-6">Admin</h2>
            <v-list density="compact" nav>
              <v-list-item
                v-for="item in adminItems"
                :key="item.title"
                :to="item.to"
                rounded="lg"
                class="mb-2"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
      
      <v-menu v-if="isSeller" offset-y>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon color="#658D8B">mdi-store</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300" class="nav-menu" elevation="5" rounded="xl">
          <v-card-text class="pa-6">
            <h2 class="text-h5 font-weight-bold mb-6">Seller</h2>
            <v-list density="compact" nav>
              <v-list-item
                v-for="item in sellerItems"
                :key="item.title"
                :to="item.to"
                rounded="lg"
                class="mb-2"
              >
                <template #prepend>
                  <v-icon>{{ item.icon }}</v-icon>
                </template>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-btn icon @click="goToCart">
        <v-icon color="black">mdi-cart-outline</v-icon>
        <v-badge
          v-if="cartItemCount > 0"
          :content="cartItemCount"
          color="error"
        ></v-badge>
      </v-btn>

      <AccountMenu />
    </v-app-bar>

    <!-- Mobile search bar -->
    <v-toolbar color="white" flat dense class="d-sm-none px-2 mobile-search-bar">
      <v-text-field
        v-model="searchQuery"
        bg-color="white"
        color="black"
        density="compact"
        hide-details
        label="Search"
        single-line
        variant="outlined"
        class="custom-search-input"
        @keyup.enter="performSearch"
      >
        <template #append-inner>
          <v-btn
            icon
            variant="text"
            color="black"
            class="search-icon-btn"
            @click="performSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      
      <!-- Location Button for mobile -->
      <v-btn
        class="ml-2 location-btn"
        variant="text"
        density="comfortable"
        @click="openLocationDialog"
      >
        <v-icon>mdi-map-marker</v-icon>
        <span class="text-truncate" style="max-width: 80px">{{ currentLocation }}</span>
      </v-btn>
    </v-toolbar>

    <!-- Location Dialog -->
    <v-dialog v-model="locationDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Choose your location
          <v-spacer></v-spacer>
          <v-btn icon @click="locationDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="mb-4">Delivery options and delivery speeds may vary for different locations</p>
          <template v-if="isLoggedIn">
            <v-list v-if="userAddresses.length">
              <v-list-item
                v-for="address in userAddresses"
                :key="address._id"
                @click="selectAddress(address)"
                class="mb-2"
              >
                <v-list-item-title>
                  {{ address.street ? `${address.street}, ${address.city}, ${address.province} ${address.postalCode}` : address.postalCode }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="address.isPreferred">Default address</v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-btn
              color="primary"
              block
              class="mt-4"
              @click="manageAddresses"
            >
              Manage Addresses
            </v-btn>
          </template>
          <template v-else>
            <div class="d-flex align-center mb-4">
              <AccountMenu @close="locationDialog = false" />
              <span class="ml-2">Sign in to see your addresses</span>
            </div>
          </template>
          <v-divider class="my-4"></v-divider>
          <v-text-field
            v-model="postalCode"
            label="Or enter a Canada postal code"
            outlined
            dense
            maxlength="6"
            @input="handlePostalCodeInput"
            :rules="[
              v => !v || /^[A-Z0-9]*$/.test(v) || 'Only letters and numbers allowed'
            ]"
            hint="Example: K1N8S7"
          ></v-text-field>
          <v-btn
            color="primary"
            block
            @click="applyPostalCode"
          >
            Apply
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer
      v-show="showFooter"
      app
      :color="secondaryColor"
      class="d-sm-flex d-none footer"
    >
      <span>&copy; {{ new Date().getFullYear() }} Loquo - Local. Natural. Connected.</span>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AccountMenu from '@/components/AccountMenu.vue'
import logoLight from '@/assets/logo.png'

export default {
  name: 'App',
  
  components: {
    AccountMenu,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const drawer = ref(false)
    const searchQuery = ref('')
    const locationDialog = ref(false)
    const postalCode = ref('')
    const showFooter = ref(false)

    const DEFAULT_POSTAL_CODE = 'K1N8S7'
    const primaryColor = "#623E2A"
    const secondaryColor = "#658D8B"

    const isAdmin = computed(() => store.getters['user/isAdmin'])
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
    const user = computed(() => store.state.user.user)
    const isSeller = computed(() => user.value && user.value.isSeller)
    const cartItemCount = computed(() => store.getters.cartItemCount)
    const userAddresses = computed(() => store.getters['address/getSortedAddresses'])
    const temporaryPostalCode = computed(() => store.getters['address/getTemporaryPostalCode'] || DEFAULT_POSTAL_CODE)
    const currentLocation = computed(() => {
      if (isLoggedIn.value) {
        const preferredAddress = store.getters['address/getPreferredAddress']
        return preferredAddress ? preferredAddress.postalCode : DEFAULT_POSTAL_CODE
      }
      return temporaryPostalCode.value
    })

    const adminItems = [
      { title: 'Categories', to: '/admin/categories' },
      { title: 'Products', to: '/admin/products' },
      { title: 'Orders', to: '/admin/orders' },
      { title: 'Bulk Meat Products', to: '/admin/bulk-meat-products' },
      { title: 'Bulk Orders', to: '/admin/bulk-orders' },
      { title: 'Sellers', to: '/admin/sellers' },
      { title: 'Local Shipping Zones', to: '/admin/local-shipping-zones' },
      { title: 'Users', to: '/admin/users' },
      { title: 'Recommendations', to: '/admin/recommendations' },
      { title: 'Search History', to: '/admin/search-history' }
    ]

    const sellerItems = computed(() => [
      { title: 'Your Seller Info', icon: 'mdi-information', to: '/seller-info' },
      { title: 'Your Inventory', icon: 'mdi-package-variant', to: '/seller-inventory' },
      { title: 'Your Recommendations', icon: 'mdi-star', to: '/seller-recommendations' },
      { title: 'Your Seller Page', icon: 'mdi-storefront', to: '/seller' },
      { title: 'Your Seller Orders', icon: 'mdi-clipboard-text', to: '/seller-orders' },
      { title: 'Your Shipments', icon: 'mdi-truck-delivery', to: '/seller-shipments' },
    ])

    const sections = [
      {
        title: 'Food',
        items: [
          { title: 'Bulk meat', icon: 'mdi-food-steak', to: '/bulk-meat' },
          { title: 'Farm subscriptions', icon: 'mdi-basket', to: '/farm-subscriptions' },
          { title: 'Recipes', icon: 'mdi-book-open-variant', to: '/recipes' },
        ]
      },
      {
        title: 'Health',
        items: [
          { title: 'Naturopathy', icon: 'mdi-bottle-tonic', to: '/naturopathy' },
          { title: 'Health Practitioners', icon: 'mdi-account-tie', to: '/health-practitioners' },
        ]
      },
      {
        title: 'Pregnancy',
        items: [
          { title: 'Organic baby registry', icon: 'mdi-baby-carriage', to: '/pregnancy/baby-registry' },
          { title: 'Education', icon: 'mdi-school', to: '/pregnancy/education' },
          { title: 'Doulas', icon: 'mdi-human-female-female', to: '/pregnancy/doulas' },
          { title: 'Groups', icon: 'mdi-account-group', to: '/pregnancy/groups' },
        ]
      },
      {
        title: 'Connect',
        items: [
          { title: 'Pick your own food', icon: 'mdi-food-apple', to: '/pyo' },
          { title: 'Farm tours', icon: 'mdi-tractor', to: '/farm-tours' },
          { title: 'Farmers markets', icon: 'mdi-store', to: '/farmers-markets' },
          { title: 'Restaurants', icon: 'mdi-silverware-fork-knife', to: '/restaurants' },
        ]
      },
    ]

    const handlePostalCodeInput = (e) => {
      if (!e) {
        postalCode.value = ''
        return
      }
      // Convert to uppercase and remove any spaces
      const formatted = e.toUpperCase().replace(/\s+/g, '')
      // Limit to 6 characters
      postalCode.value = formatted.slice(0, 6)
    }

    const applyPostalCode = async () => {
      if (postalCode.value) {
        const formattedPostalCode = postalCode.value.toUpperCase().replace(/\s+/g, '')
        if (formattedPostalCode.length === 6 && /^[A-Z0-9]+$/.test(formattedPostalCode)) {
          try {
            await store.dispatch('address/addPostalCodeAddress', formattedPostalCode)
            locationDialog.value = false
            postalCode.value = ''
          } catch (error) {
            console.error('Error applying postal code:', error)
          }
        }
      }
    }

    const checkScrollPosition = () => {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
      showFooter.value = bottomOfWindow
    }

    const performSearch = () => {
      if (searchQuery.value.trim()) {
        router.push({ path: '/search', query: { q: searchQuery.value.trim() } })
      }
    }

    const viewAll = () => {
      searchQuery.value = ''
      router.push({ path: '/search', query: { viewAll: 'true' } })
    }

    const goToCart = () => {
      router.push('/cart')
    }

    const openLocationDialog = () => {
      locationDialog.value = true
      if (isLoggedIn.value) {
        store.dispatch('address/fetchAddresses')
      }
    }

    const manageAddresses = () => {
      router.push('/account#addresses')
      locationDialog.value = false
    }

    const selectAddress = async (address) => {
      try {
        await store.dispatch('address/setPreferredAddress', address._id)
        locationDialog.value = false
      } catch (error) {
        console.error('Error selecting address:', error)
      }
    }

    const onLogoLoad = () => {
      console.log('Logo loaded successfully')
    }

    const onLogoError = (error) => {
      console.error('Error loading logo:', error)
    }

    onMounted(() => {
      console.log('App component mounted')
      window.addEventListener('scroll', checkScrollPosition)
      checkScrollPosition()
      
      if (isLoggedIn.value) {
        store.dispatch('address/fetchAddresses')
      } else if (!temporaryPostalCode.value) {
        store.dispatch('address/addPostalCodeAddress', DEFAULT_POSTAL_CODE)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', checkScrollPosition)
    })

    watch(isLoggedIn, (newValue) => {
      if (newValue) {
        store.dispatch('address/fetchAddresses')
        if (temporaryPostalCode.value) {
          store.commit('address/CLEAR_TEMPORARY_POSTAL_CODE')
        }
      }
    })

    return {
      drawer,
      searchQuery,
      primaryColor,
      secondaryColor,
      isAdmin,
      isLoggedIn,
      user,
      isSeller,
      cartItemCount,
      adminItems,
      sellerItems,
      sections,
      performSearch,
      viewAll,
      goToCart,
      onLogoLoad,
      onLogoError,
      logoLight,
      locationDialog,
      currentLocation,
      postalCode,
      userAddresses,
      openLocationDialog,
      selectAddress,
      manageAddresses,
      applyPostalCode,
      handlePostalCodeInput,
      showFooter,
    }
  }
}
</script>

<style scoped>
.nav-menu {
  width: 300px;
}
.search-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto; /* Center the search form */
}
.custom-search-input {
  border-radius: 9999px !important;
}
.custom-search-input ::v-deep .v-field__outline {
  color: black !important;
}
.custom-search-input ::v-deep .v-field__input {
  caret-color: black !important;
}
.search-icon-btn {
  margin-right: -12px !important;
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}
.v-list-item {
  min-height: 40px;
}
.v-list-item__prepend {
  margin-right: 16px;
}
.v-list-item__title {
  font-size: 14px;
}
.v-list-subheader {
  font-size: 14px;
  font-weight: bold;
  height: 36px;
}
.v-divider {
  margin: 8px 0;
}
.v-img {
  max-width: 140px;
  width: auto;
  height: auto;
}
.v-btn.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}
.v-btn.location-btn {
  text-transform: none;
  border: none !important;
  box-shadow: none !important;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .v-main {
    padding-top: calc(56px + 56px) !important; /* App bar height + search bar height */
  }
  
  .v-app-bar {
    height: 56px !important;
    border-bottom: 1px solid #e0e0e0;
  }

  .mobile-search-bar {
    top: 56px !important;
    position: fixed !important;
    z-index: 4;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .custom-search-input {
    flex: 1;
  }

  .custom-search-input ::v-deep .v-field__outline {
    color: #e0e0e0 !important;
  }

  .custom-search-input ::v-deep .v-field__input {
    color: black !important;
  }

  .location-btn {
    padding: 0 8px;
  }
}
</style>
