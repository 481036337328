<!-- ./frontend/src/views/PrivacyPolicyPage.vue -->

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <h1 class="text-h3 mb-6">Privacy Policy</h1>
        
        <section>
          <h2 class="text-h4 mb-4">1. Introduction</h2>
          <p>Welcome to Loquo. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.</p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">2. Information We Collect</h2>
          <p>We collect information that you provide directly to us, such as when you create an account, make a purchase, or contact us for support. This may include:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Billing and shipping address</li>
            <li>Payment information</li>
          </ul>
        </section>

        <section>
          <h2 class="text-h4 mb-4">3. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Process transactions and send related information</li>
            <li>Send you technical notices, updates, security alerts, and support messages</li>
            <li>Respond to your comments, questions, and requests</li>
            <li>Communicate with you about products, services, offers, and events</li>
          </ul>
        </section>

        <section>
          <h2 class="text-h4 mb-4">4. Data Sharing and Disclosure</h2>
          <p>We may share your information with:</p>
          <ul>
            <li>Service providers who perform services on our behalf</li>
            <li>Business partners with whom we jointly offer products or services</li>
            <li>Legal authorities when required by law or to protect our rights</li>
          </ul>
        </section>

        <section>
          <h2 class="text-h4 mb-4">5. Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect the security of your personal information.</p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">6. Your Rights</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data.</p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">7. Third-Party Login Services</h2>
          <p>We offer the ability to sign up and log in to our platform using third-party services such as Google and Facebook.</p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">8. Data Deletion</h2>
          <h3 class="text-h5 mb-3">Facebook Data Deletion</h3>
          <p>
            If you would like to delete your data associated with our Facebook app, 
            you can do so by following these steps:
          </p>
          <ol>
            <li>Go to your Facebook Account's Settings & Privacy.</li>
            <li>Click "Settings".</li>
            <li>Look for "Apps and Websites" and click it.</li>
            <li>Find our app in the list and click the "Remove" button.</li>
            <li>Confirm by clicking "Remove" again.</li>
          </ol>
          <p>
            Alternatively, you can use this link to manage your app permissions:
            <a href="https://www.facebook.com/settings?tab=applications" target="_blank" rel="noopener noreferrer">
              Manage App Permissions
            </a>
          </p>
          <p>
            Upon receiving a deletion request, we will process it and delete your Facebook-associated data from our systems within 14 days.
          </p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">9. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </section>

        <section>
          <h2 class="text-h4 mb-4">10. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>Email: privacy@loquo.com</p>
          <p>Address: 123 Privacy Street, Data City, 12345, Country</p>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
  metaInfo: {
    title: 'Privacy Policy - Loquo',
    meta: [
      { name: 'description', content: 'Loquo privacy policy, including information on data collection, use, sharing, and deletion procedures.' }
    ]
  }
}
</script>

<style scoped>
section {
  margin-bottom: 2rem;
}
h2 {
  color: #623E2A;
}
ul, ol {
  padding-left: 1.5rem;
}
</style>
