<!-- ./frontend/src/components/AccountAddressesCard.vue -->
<template>
  <v-card outlined class="mb-6" id="addresses">
    <v-card-title class="d-flex justify-space-between align-center">
      <div>
        <v-icon left>mdi-map-marker</v-icon>
        Addresses
      </div>
      <v-btn 
        text 
        color="primary" 
        @click="showAddressForm = true" 
        v-if="!showAddressForm && (!addresses || addresses.length === 0)"
      >
        Add address
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row v-if="addresses && addresses.length > 0">
        <v-col cols="12" md="6" v-for="(address, index) in addresses" :key="address._id || index">
          <v-card outlined class="mb-4">
            <v-card-text>
              <div class="d-flex justify-space-between align-center mb-2">
                <span class="text-subtitle-2">{{ address.firstName }} {{ address.lastName }}</span>
                <v-chip v-if="address.isPreferred" color="primary" small>Preferred</v-chip>
              </div>
              <div class="text-h6 mb-2">{{ address.street }}</div>
              <div class="text-body-1">{{ address.city }}, {{ address.province }} {{ address.postalCode }}</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="editAddress(index)">Edit</v-btn>
              <v-btn text color="error" @click="removeAddress(address._id)">Remove</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <p class="text-body-1">No addresses added yet.</p>
        </v-col>
      </v-row>
      <v-btn 
        text 
        color="primary" 
        @click="showAddressForm = true" 
        v-if="!showAddressForm && addresses && addresses.length > 0"
        class="mt-4"
      >
        Add address
      </v-btn>
    </v-card-text>

    <v-dialog v-model="showAddressForm" max-width="500px">
      <v-card>
        <v-card-title>
          {{ editingAddressIndex === null ? 'Add Address' : 'Edit Address' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="addressForm" v-model="addressFormValid" @submit.prevent="saveAddress">
            <v-text-field v-model="addressData.firstName" label="First name" required></v-text-field>
            <v-text-field v-model="addressData.lastName" label="Surname" required></v-text-field>
            <v-text-field v-model="addressData.street" label="Street address" required></v-text-field>
            <v-text-field v-model="addressData.apt" label="Apt, suite, etc."></v-text-field>
            <v-text-field v-model="addressData.city" label="City" required></v-text-field>
            <v-select
              v-model="addressData.province"
              :items="provinces"
              label="Province/Territory"
              required
            ></v-select>
            <v-text-field v-model="addressData.postalCode" label="Postal code" required></v-text-field>
            <v-text-field v-model="addressData.phone" label="Phone number" required></v-text-field>
            <v-textarea v-model="addressData.deliveryInstructions" label="Delivery instructions" counter="250"></v-textarea>
            <v-checkbox v-model="addressData.isPreferred" label="Set as my preferred delivery address"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveAddress">Save</v-btn>
          <v-btn text @click="cancelAddressForm">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AccountAddressesCard',
  setup() {
    const store = useStore();
    const showAddressForm = ref(false);
    const addressFormValid = ref(true);
    const editingAddressIndex = ref(null);

    const addresses = computed(() => store.state.address.addresses);

    const addressData = reactive({
      firstName: '',
      lastName: '',
      street: '',
      apt: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      deliveryInstructions: '',
      isPreferred: false
    });

    const provinces = [
      'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
      'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia',
      'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'
    ];

    const editAddress = (index) => {
      editingAddressIndex.value = index;
      Object.assign(addressData, addresses.value[index]);
      showAddressForm.value = true;
    };

    const removeAddress = async (addressId) => {
      try {
        await store.dispatch('address/removeAddress', addressId);
        // The store will be updated with the new list of addresses,
        // including the new preferred address if applicable
      } catch (error) {
        console.error('Error removing address:', error);
      }
    };

    const saveAddress = async () => {
      if (addressFormValid.value) {
        try {
          if (editingAddressIndex.value !== null) {
            const addressId = addresses.value[editingAddressIndex.value]._id;
            await store.dispatch('address/updateAddress', { addressId, address: { ...addressData } });
          } else {
            await store.dispatch('address/addAddress', { ...addressData });
          }
          showAddressForm.value = false;
          editingAddressIndex.value = null;
          resetAddressForm();
        } catch (error) {
          console.error('Error saving address:', error);
        }
      }
    };

    const cancelAddressForm = () => {
      showAddressForm.value = false;
      editingAddressIndex.value = null;
      resetAddressForm();
    };

    const resetAddressForm = () => {
      Object.keys(addressData).forEach(key => {
        addressData[key] = key === 'isPreferred' ? false : '';
      });
    };

    return {
      showAddressForm,
      addressFormValid,
      editingAddressIndex,
      addressData,
      provinces,
      addresses,
      editAddress,
      removeAddress,
      saveAddress,
      cancelAddressForm
    };
  }
};
</script>
