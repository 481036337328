<!-- ./frontend/src/components/AccountProfileCard.vue -->
<template>
  <v-card outlined class="mb-6">
    <v-card-title>
      <v-icon left>mdi-account</v-icon>
      User Profile
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Full name</v-list-item-subtitle>
            <v-list-item-title>{{ userData.name || 'Not set' }}</v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('name')"
          >
            Edit
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'name'">
          <v-form @submit.prevent="saveField('name')">
            <v-text-field 
              v-model="editedName.firstName" 
              label="First name" 
              required
            ></v-text-field>
            <v-text-field 
              v-model="editedName.lastName" 
              label="Surname" 
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Password</v-list-item-subtitle>
            <v-list-item-title>••••••••</v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('password')"
          >
            Change
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'password'">
          <v-form @submit.prevent="saveField('password')">
            <v-text-field
              v-model="editedPassword.current"
              label="Current password"
              :type="showCurrentPassword ? 'text' : 'password'"
              required
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showCurrentPassword = !showCurrentPassword"
            ></v-text-field>
            <v-text-field
              v-model="editedPassword.new"
              label="New password"
              :type="showNewPassword ? 'text' : 'password'"
              required
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showNewPassword = !showNewPassword"
            ></v-text-field>
            <v-text-field
              v-model="editedPassword.confirm"
              label="Confirm new password"
              :type="showConfirmPassword ? 'text' : 'password'"
              required
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
            <v-list-item-title class="d-flex align-center">
              {{ userData.email || 'Not set' }}
              <v-spacer></v-spacer>
              <v-chip
                :color="userData.emailVerified ? 'success' : 'warning'"
                small
                class="ml-2"
              >
                {{ userData.emailVerified ? 'Verified' : 'Unverified' }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('email')"
          >
            Edit
          </v-btn>
          <v-btn
            v-if="!userData.emailVerified"
            class="text-none px-0 ml-2"
            color="secondary"
            text
            @click="sendEmailVerification"
          >
            Verify
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'email'">
          <v-form @submit.prevent="saveField('email')">
            <v-text-field 
              v-model="editedEmail" 
              label="Email" 
              type="email" 
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>
        <v-list-item v-if="showEmailVerification">
          <v-form @submit.prevent="verifyEmail">
            <v-text-field 
              v-model="emailVerificationCode" 
              label="Verification Code" 
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Verify Email</v-btn>
            <v-btn text @click="cancelEmailVerification">Cancel</v-btn>
          </v-form>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Phone Number</v-list-item-subtitle>
            <v-list-item-title class="d-flex align-center">
              {{ userData.phone || 'Not set' }}
              <v-spacer></v-spacer>
              <v-chip
                :color="userData.phoneVerified ? 'success' : 'warning'"
                small
                class="ml-2"
              >
                {{ userData.phoneVerified ? 'Verified' : 'Unverified' }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('phone')"
          >
            Edit
          </v-btn>
          <v-btn
            v-if="!userData.phoneVerified"
            class="text-none px-0 ml-2"
            color="secondary"
            text
            @click="sendPhoneVerification"
          >
            Verify
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'phone'">
          <v-form @submit.prevent="saveField('phone')">
            <v-text-field 
              v-model="editedPhone" 
              label="Phone Number" 
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>
        <v-list-item v-if="showPhoneVerification">
          <v-form @submit.prevent="verifyPhone">
            <v-text-field 
              v-model="phoneVerificationCode" 
              label="Verification Code" 
              required
            ></v-text-field>
            <v-btn color="primary" type="submit">Verify Phone</v-btn>
            <v-btn text @click="cancelPhoneVerification">Cancel</v-btn>
          </v-form>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Language Preference</v-list-item-subtitle>
            <v-list-item-title>{{ userData.languagePreference || 'Not set' }}</v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('language')"
          >
            Edit
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'language'">
          <v-form @submit.prevent="saveField('language')">
            <v-select
              v-model="editedLanguage"
              :items="languageOptions"
              label="Language Preference"
              required
            ></v-select>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Dietary Restrictions</v-list-item-subtitle>
            <v-list-item-title>{{ userData.dietaryRestrictions ? userData.dietaryRestrictions.join(', ') : 'None' }}</v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn 
            class="text-none px-0" 
            color="primary" 
            text
            @click="editField('dietary')"
          >
            Edit
          </v-btn>
        </v-list-item>
        <v-list-item v-if="editingField === 'dietary'">
          <v-form @submit.prevent="saveField('dietary')">
            <v-select
              v-model="editedDietary"
              :items="dietaryOptions"
              label="Dietary Restrictions"
              multiple
              chips
            ></v-select>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn text @click="cancelEdit">Cancel</v-btn>
          </v-form>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: 'AccountProfileCard',
  
  props: {
    userData: {
      type: Object,
      required: true
    },
    showPhoneVerification: {
      type: Boolean,
      default: false
    },
    showEmailVerification: {
      type: Boolean,
      default: false
    }
  },

  emits: ['updateProfile', 'verifyEmail', 'verifyPhone', 'updateEmailVerificationStatus'],

  setup(props, { emit }) {
    const editingField = ref(null);
    const editedName = ref({ firstName: '', lastName: '' });
    const editedEmail = ref('');
    const editedPhone = ref('');
    const editedPassword = ref({ current: '', new: '', confirm: '' });
    const editedLanguage = ref('');
    const editedDietary = ref([]);

    const showCurrentPassword = ref(false);
    const showNewPassword = ref(false);
    const showConfirmPassword = ref(false);

    const emailVerificationCode = ref('');
    const phoneVerificationCode = ref('');

    const languageOptions = ['English', 'French'];
    const dietaryOptions = ['Vegetarian', 'Vegan', 'Gluten-free', 'Dairy-free', 'Nut-free', 'Halal'];

    const fullName = computed(() => {
      const nameParts = props.userData.name ? props.userData.name.split(' ') : ['', ''];
      return {
        firstName: nameParts[0],
        lastName: nameParts.slice(1).join(' ')
      };
    });

    const editField = (field) => {
      editingField.value = field;
      if (field === 'name') {
        editedName.value = { ...fullName.value };
      } else if (field === 'email') {
        editedEmail.value = props.userData.email || '';
      } else if (field === 'phone') {
        editedPhone.value = props.userData.phone || '';
      } else if (field === 'language') {
        editedLanguage.value = props.userData.languagePreference || '';
      } else if (field === 'dietary') {
        editedDietary.value = [...(props.userData.dietaryRestrictions || [])];
      }
    };

    const saveField = (field) => {
      let updatedData = {};
      if (field === 'name') {
        updatedData = { name: `${editedName.value.firstName} ${editedName.value.lastName}`.trim() };
      } else if (field === 'email') {
        updatedData = { email: editedEmail.value };
        emit('updateEmailVerificationStatus', false);
      } else if (field === 'phone') {
        updatedData = { phone: editedPhone.value };
      } else if (field === 'password') {
        if (editedPassword.value.new !== editedPassword.value.confirm) {
          alert('New passwords do not match');
          return;
        }
        updatedData = { 
          currentPassword: editedPassword.value.current,
          newPassword: editedPassword.value.new 
        };
      } else if (field === 'language') {
        updatedData = { languagePreference: editedLanguage.value };
      } else if (field === 'dietary') {
        updatedData = { dietaryRestrictions: editedDietary.value };
      }

      emit('updateProfile', updatedData);
      editingField.value = null;
    };

    const cancelEdit = () => {
      editingField.value = null;
      editedPassword.value = { current: '', new: '', confirm: '' };
    };

    const sendEmailVerification = () => {
      emit('verifyEmail');
    };

    const sendPhoneVerification = () => {
      emit('verifyPhone');
    };

    const verifyEmail = () => {
      emit('verifyEmail', emailVerificationCode.value);
      emailVerificationCode.value = '';
    };

    const verifyPhone = () => {
      emit('verifyPhone', phoneVerificationCode.value);
      phoneVerificationCode.value = '';
    };

    const cancelEmailVerification = () => {
      emit('verifyEmail', null);
      emailVerificationCode.value = '';
    };

    const cancelPhoneVerification = () => {
      emit('verifyPhone', null);
      phoneVerificationCode.value = '';
    };

    return {
      editingField,
      editedName,
      editedEmail,
      editedPhone,
      editedPassword,
      editedLanguage,
      editedDietary,
      showCurrentPassword,
      showNewPassword,
      showConfirmPassword,
      languageOptions,
      dietaryOptions,
      emailVerificationCode,
      phoneVerificationCode,
      editField,
      saveField,
      cancelEdit,
      sendEmailVerification,
      sendPhoneVerification,
      verifyEmail,
      verifyPhone,
      cancelEmailVerification,
      cancelPhoneVerification
    };
  }
};
</script>

<style scoped>
.v-list-item {
  padding: 0;
}
</style>
