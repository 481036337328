<template>
  <v-container>
    <h1>Admin: Categories</h1>
    <v-btn color="primary" @click="openDialog" class="mb-4">Add New Category</v-btn>
    <v-data-table
      :headers="headers"
      :items="categories"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editCategory(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteCategory(item._id)">Delete</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form">
            <v-text-field
              v-model="editedItem.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedItem.section"
              label="Section"
              :rules="[v => !!v || 'Section is required']"
              required
            ></v-text-field>
            <v-combobox
              v-model="editedItem.subcategories"
              label="Subcategories"
              multiple
              chips
              small-chips
            ></v-combobox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminCategoriesView',
  setup() {
    const store = useStore();
    const dialog = ref(false);
    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Section', value: 'section' },
      { text: 'Subcategories', value: 'subcategories' },
      { text: 'Actions', value: 'actions', sortable: false }
    ];
    const editedIndex = ref(-1);
    const editedItem = ref({
      name: '',
      section: '',
      subcategories: []
    });
    const defaultItem = {
      name: '',
      section: '',
      subcategories: []
    };

    const formTitle = computed(() => editedIndex.value === -1 ? 'New Category' : 'Edit Category');
    const categories = computed(() => store.state.categories);

    const openDialog = () => {
      editedIndex.value = -1;
      editedItem.value = Object.assign({}, defaultItem);
      dialog.value = true;
    };

    const editCategory = (item) => {
      editedIndex.value = categories.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteCategory = async (id) => {
      if (confirm('Are you sure you want to delete this category?')) {
        try {
          await store.dispatch('deleteCategory', id);
          await store.dispatch('fetchCategories');
        } catch (error) {
          console.error('Error deleting category:', error);
        }
      }
    };

    const close = () => {
      dialog.value = false;
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    };

    const save = async () => {
      try {
        if (editedIndex.value > -1) {
          await store.dispatch('updateCategory', editedItem.value);
        } else {
          await store.dispatch('createCategory', editedItem.value);
        }
        close();
        await store.dispatch('fetchCategories');
      } catch (error) {
        console.error('Error saving category:', error);
      }
    };

    onMounted(() => {
      store.dispatch('fetchCategories');
    });

    return {
      dialog,
      headers,
      editedIndex,
      editedItem,
      formTitle,
      categories,
      openDialog,
      editCategory,
      deleteCategory,
      close,
      save
    };
  }
};
</script>
