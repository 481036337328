<template>
  <v-container>
    <h1 class="text-h4 mb-4">Admin: Search History</h1>
    <v-data-table
      :headers="headers"
      :items="searchQueries"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.timestamp`]="{ item }">
        {{ formatDate(item.timestamp) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AdminSearchHistory',
  setup() {
    const store = useStore()
    const searchQueries = ref([])
    const headers = [
      { text: 'Query', value: 'query' },
      { text: 'Timestamp', value: 'timestamp' },
      { text: 'User Name', value: 'user.name' },
      { text: 'User Email', value: 'user.email' }
    ]

    const fetchSearchHistory = async () => {
      try {
        const response = await store.dispatch('fetchAdminSearchHistory')
        searchQueries.value = response
      } catch (error) {
        console.error('Error fetching search history:', error)
      }
    }

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString()
    }

    onMounted(fetchSearchHistory)

    return {
      searchQueries,
      headers,
      formatDate
    }
  }
}
</script>
