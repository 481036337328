<template>
  <v-container v-if="$vuetify">
    <h1>Bulk Meat Orders</h1>
    
    <!-- My Bulk Meat Orders section -->
    <v-card class="mb-6">
      <v-card-title>My Bulk Meat Orders</v-card-title>
      <v-card-text>
        <div v-if="isLoggedIn">
          <v-table v-if="myBulkOrders.length > 0">
            <thead>
              <tr>
                <th>Meat Type</th>
                <th>Specifications</th>
                <th>Location</th>
                <th>My Quantity</th>
                <th>Total Quantity</th>
                <th>Status</th>
                <th>Estimated Pickup Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in myBulkOrders" :key="order._id">
                <td>{{ order.meatType }}</td>
                <td>{{ order.specifications.join(', ') }}</td>
                <td>{{ order.location }}</td>
                <td>{{ getUserQuantity(order) }}lbs</td>
                <td>{{ order.currentQuantity }}/{{ order.targetQuantity }}lbs</td>
                <td>{{ order.status }}</td>
                <td>{{ order.estimatedPickupDate }}</td>
                <td>
                  <v-btn
                    color="primary"
                    small
                    @click="generateInviteLink(order._id)"
                  >
                    Invite
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <p v-else>You are not currently participating in any bulk meat orders.</p>
        </div>
        <div v-else>
          <p>Sign in to view your bulk meat orders.</p>
          <v-btn color="primary" @click="navigateToLogin">Sign In</v-btn>
        </div>
      </v-card-text>
    </v-card>
    
    <!-- Find a bulk order form -->
    <v-card class="mb-6">
      <v-card-title>Find a bulk order</v-card-title>
      <v-card-text>
        <v-form ref="filterForm" v-model="filterFormValid">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.location"
                :items="locationOptions"
                item-title="text"
                item-value="value"
                label="Location"
                :rules="[v => !!v || 'Location is required']"
                required
                @update:model-value="updateLocation"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.meatType"
                :items="availableMeatTypes"
                label="Meat Type"
                :rules="[v => !!v || 'Meat Type is required']"
                required
                @update:model-value="updateMeatType"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.preferredSellers"
                :items="availableSellers"
                item-title="name"
                item-value="name"
                label="Preferred Sellers"
                multiple
                chips
                clearable
                @update:model-value="applyFilters"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.specifications"
                :items="availableSpecifications"
                label="Specifications"
                multiple
                chips
                clearable
                @update:model-value="updateSpecifications"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.estimatedPickupDate"
                :items="availablePickupDates"
                label="Estimated Pickup Date"
                :rules="[v => !!v || 'Estimated Pickup Date is required']"
                required
                @update:model-value="applyFilters"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filterCriteria.quantity"
                :items="quantityOptions"
                item-title="text"
                item-value="value"
                label="Quantity"
                :rules="[v => !!v || 'Quantity is required']"
                required
                @update:model-value="applyFilters"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    
    <!-- Show big "Create New Order" button if no filtered bulk orders -->
    <v-row v-if="filteredBulkOrders.length === 0" justify="center" class="mt-6">
      <v-col cols="12" sm="6" md="4">
        <v-btn
          color="primary"
          x-large
          block
          @click="createNewOrder"
        >
          Create New Order
        </v-btn>
      </v-col>
    </v-row>

    <!-- Table for current bulk orders -->
    <v-table v-else>
      <thead>
        <tr>
          <th>Meat Type</th>
          <th>Specifications</th>
          <th>Location</th>
          <th>Preferred Sellers</th>
          <th>Available Quantity</th>
          <th>Estimated Pickup Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in filteredBulkOrders" :key="order._id">
          <td>{{ order.meatType }}</td>
          <td>{{ order.specifications.join(', ') }}</td>
          <td>{{ order.location }}</td>
          <td>{{ order.preferredSellers.join(', ') }}</td>
          <td>{{ order.availableQuantity }}lbs</td>
          <td>{{ order.estimatedPickupDate }}</td>
          <td>
            <v-btn color="primary" @click="joinOrder(order)">Join</v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>

    <!-- Join Order Dialog -->
    <v-dialog v-model="joinDialog" max-width="500px">
      <v-card>
        <v-card-title>Join Bulk Order</v-card-title>
        <v-card-text>
          <v-form ref="joinForm" v-model="joinFormValid">
            <v-select
              v-model="joinQuantity"
              :items="quantityOptions"
              item-title="text"
              item-value="value"
              label="Quantity"
              :rules="[v => !!v || 'Quantity is required']"
              required
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeJoinDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="confirmJoinOrder" :disabled="!joinFormValid">Join</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Order Dialog -->
    <v-dialog v-model="newOrderDialog" max-width="500px">
      <v-card>
        <v-card-title>Create New Order</v-card-title>
        <v-card-text>
          <v-form ref="newOrderForm" v-model="newOrderFormValid">
            <v-select
              v-if="!filterCriteria.location"
              v-model="newOrder.location"
              :items="locationOptions"
              item-title="text"
              item-value="value"
              label="Location"
              :rules="[v => !!v || 'Location is required']"
              required
            ></v-select>
            <v-select
              v-if="!filterCriteria.meatType"
              v-model="newOrder.meatType"
              :items="availableMeatTypes"
              label="Meat Type"
              :rules="[v => !!v || 'Meat Type is required']"
              required
            ></v-select>
            <v-select
              v-if="!filterCriteria.estimatedPickupDate"
              v-model="newOrder.estimatedPickupDate"
              :items="availablePickupDates"
              label="Estimated Pickup Date"
              :rules="[v => !!v || 'Estimated Pickup Date is required']"
              required
            ></v-select>
            <v-select
              v-if="!filterCriteria.quantity"
              v-model="newOrder.quantity"
              :items="quantityOptions"
              item-title="text"
              item-value="value"
              label="Quantity"
              :rules="[v => !!v || 'Quantity is required']"
              required
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeNewOrderDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="submitNewOrder" :disabled="!newOrderFormValid">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add this dialog for the invite link -->
    <v-dialog v-model="showInviteDialog" max-width="500px">
     <v-card>
      <v-card-title>Invite Link</v-card-title>
      <v-card-text>
       <p>Share this link with your friends to invite them to join the bulk order:</p>
       <v-text-field
         v-model="inviteLink"
         readonly
         append-outer-icon="mdi-content-copy"
         @click:append-outer="copyInviteLink"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="copyInviteLink">Copy</v-btn>
      <v-btn color="primary" text @click="showInviteDialog = false">Close</v-btn>
     </v-card-actions>
    </v-card>
   </v-dialog>

   <!-- Bulk Meat Products Section -->
   <v-card class="mt-6">
     <v-card-title>Available Bulk Meat Products</v-card-title>
     <v-card-text>
       <BulkMeatProductList />
     </v-card-text>
   </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BulkMeatProductList from '@/components/BulkMeatProductList.vue';

export default {
  name: 'BulkMeatPage',
  components: {
    BulkMeatProductList
  },
  data() {
    return {
      newOrder: {
        meatType: '',
        location: '',
        quantity: '',
        estimatedPickupDate: '',
      },
      filterCriteria: {
        location: '',
        meatType: '',
        preferredSellers: [],
        specifications: [],
        estimatedPickupDate: '',
        quantity: null,
      },
      joinDialog: false,
      newOrderDialog: false,
      joinQuantity: null,
      selectedOrder: null,
      joinFormValid: false,
      newOrderFormValid: false,
      filterFormValid: false,
      showInviteDialog: false,
      inviteLink: '',
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
    }
  },
  computed: {
    ...mapState(['bulkOrders', 'user', 'sellers', 'locations', 'bulkMeatProducts']),
    ...mapGetters(['isLoggedIn']),
    locationOptions() {
      return this.locations.map(location => ({
        text: location.name,
        value: location.name
      }));
    },
    availableMeatTypes() {
      if (!this.filterCriteria.location) return [];
      const sellersInLocation = this.sellers.filter(seller => seller.location.city === this.filterCriteria.location);
      const meatTypes = new Set();
      sellersInLocation.forEach(seller => {
        seller.bulkProducts.forEach(product => meatTypes.add(product));
      });
      return Array.from(meatTypes);
    },
    availableSellers() {
      return this.sellers.filter(seller => seller.location.city === this.filterCriteria.location);
    },
    availableSpecifications() {
      if (!this.filterCriteria.meatType) return [];
      const product = this.bulkMeatProducts.find(p => p.name === this.filterCriteria.meatType);
      if (!product) return [];
      
      let specs = product.specificationChart.map(spec => spec.specification);
      if (product.halalOption) {
        specs.push('Halal');
      }
      return specs;
    },
    availablePickupDates() {
      if (!this.filterCriteria.meatType) return [];
      const product = this.bulkMeatProducts.find(p => p.name === this.filterCriteria.meatType);
      if (!product) return [];
      
      let dates;
      if (this.filterCriteria.specifications.length > 0) {
        const selectedSpecs = product.specificationChart.filter(spec => 
          this.filterCriteria.specifications.includes(spec.specification)
        );
        const availableMonths = new Set();
        selectedSpecs.forEach(spec => {
          spec.monthsAvailable.forEach(month => availableMonths.add(month));
        });
        dates = Array.from(availableMonths);
      } else {
        dates = product.pickUpMonths;
      }
      
      // Sort dates
      dates.sort((a, b) => {
        const dateA = new Date(this.addYearToMonth(a));
        const dateB = new Date(this.addYearToMonth(b));
        return dateA - dateB;  // Sort in ascending order (oldest first)
      });
      
      return dates.map(month => this.addYearToMonth(month));
    },
    quantityOptions() {
      if (!this.filterCriteria.meatType) return [];
      const product = this.bulkMeatProducts.find(p => p.name === this.filterCriteria.meatType);
      if (!product) return [];
      
      return product.priceChart.map(item => ({
        value: item.quantity,
        text: `${item.quantity} ${item.measure} '${item.comment}' $${item.price}/${item.measure}`
      }));
    },
    myBulkOrders() {
      if (!this.user) return [];
      return this.bulkOrders.filter(order => 
        order.participants.some(participant => participant.userId === this.user._id)
      );
    },
    filteredBulkOrders() {
      return this.bulkOrders.filter(order => {
        return (
          (!this.filterCriteria.location || order.location === this.filterCriteria.location) &&
          (!this.filterCriteria.meatType || order.meatType === this.filterCriteria.meatType) &&
          (!this.filterCriteria.preferredSellers.length || this.filterCriteria.preferredSellers.some(seller => order.preferredSellers.includes(seller))) &&
          (!this.filterCriteria.specifications.length || this.filterCriteria.specifications.every(spec => order.specifications.includes(spec))) &&
          (!this.filterCriteria.estimatedPickupDate || order.estimatedPickupDate === this.filterCriteria.estimatedPickupDate) &&
          (!this.filterCriteria.quantity || order.availableQuantity >= this.filterCriteria.quantity)
        );
      });
    },
    allRequiredFieldsSelected() {
      return this.filterCriteria.location && 
             this.filterCriteria.meatType && 
             this.filterCriteria.estimatedPickupDate && 
             this.filterCriteria.quantity;
    }
  },
  methods: {
    ...mapActions(['fetchBulkOrders', 'createBulkOrder', 'joinBulkOrder', 'fetchSellers', 'fetchLocations', 'fetchBulkMeatProducts']),
    getUserQuantity(order) {
      const userParticipation = order.participants.find(p => p.userId === this.user._id);
      return userParticipation ? userParticipation.quantity : 0;
    },
    navigateToLogin() {
      this.$router.push('/login');
    },
    updateLocation() {
      this.filterCriteria.meatType = '';
      this.filterCriteria.preferredSellers = [];
      this.filterCriteria.specifications = [];
      this.filterCriteria.estimatedPickupDate = '';
      this.filterCriteria.quantity = null;
      this.applyFilters();
    },
    updateMeatType() {
      this.filterCriteria.specifications = [];
      this.filterCriteria.estimatedPickupDate = '';
      this.filterCriteria.quantity = null;
      this.applyFilters();
    },
    updateSpecifications() {
      this.filterCriteria.estimatedPickupDate = '';
      this.applyFilters();
    },
    applyFilters() {
      // This method is called whenever a filter criteria changes
      // The filteredBulkOrders computed property will automatically update
    },
    joinOrder(order) {
      if (this.filterCriteria.quantity) {
        this.confirmJoinOrder(order, this.filterCriteria.quantity);
      } else {
        this.selectedOrder = order;
        this.joinDialog = true;
      }
    },
    closeJoinDialog() {
      this.joinDialog = false;
      this.selectedOrder = null;
      this.joinQuantity = null;
    },
    async confirmJoinOrder(order, quantity) {
      try {
        await this.joinBulkOrder({
          orderId: order._id,
          quantity: quantity
        });
        alert('Successfully joined the bulk order!');
        this.closeJoinDialog();
        this.fetchBulkOrders();
      } catch (error) {
        console.error('Error joining bulk order:', error);
        alert('Failed to join bulk order. Please try again.');
      }
    },
    createNewOrder() {
      if (this.allRequiredFieldsSelected) {
        this.submitNewOrder();
      } else {
        this.newOrderDialog = true;
      }
    },
    closeNewOrderDialog() {
      this.newOrderDialog = false;
      this.newOrder = {
        meatType: '',
        location: '',
        quantity: '',
        estimatedPickupDate: '',
      };
      if (this.$refs.newOrderForm) {
        this.$refs.newOrderForm.resetValidation();
      }
    },
    async submitNewOrder() {
      try {
        const newOrderData = {
          meatType: this.filterCriteria.meatType || this.newOrder.meatType,
          location: this.filterCriteria.location || this.newOrder.location,
          specifications: this.filterCriteria.specifications,
          preferredSellers: this.filterCriteria.preferredSellers,
          currentQuantity: this.filterCriteria.quantity || this.newOrder.quantity,
          estimatedPickupDate: this.filterCriteria.estimatedPickupDate || this.newOrder.estimatedPickupDate
        };
        const result = await this.createBulkOrder(newOrderData);
        console.log('New bulk order created:', result);
        alert('New bulk order submitted successfully!');
        this.closeNewOrderDialog();
        this.fetchBulkOrders();
      } catch (error) {
        console.error('Error creating bulk order:', error);
        alert('Failed to create bulk order. Please try again.');
      }
    },
    async generateInviteLink(orderId) {
      try {
        const response = await this.$store.dispatch('generateInviteLink', orderId);
        this.inviteLink = `${window.location.origin}/bulk-order-landing/${response.inviteCode}`;
        this.showInviteDialog = true;
      } catch (error) {
        console.error('Error generating invite link:', error);
        alert('Failed to generate invite link. Please try again.');
      }
    },
    copyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink).then(() => {
        alert('Invite link copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy invite link:', err);
        alert('Failed to copy invite link. Please try copying manually.');
      });
    },
    async fetchData() {
      console.log('Fetching data...');
      try {
        await Promise.all([
          this.fetchBulkOrders(),
          this.fetchSellers(),
          this.fetchLocations(),
          this.fetchBulkMeatProducts()
        ]);
        console.log('Data fetched successfully');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    addYearToMonth(month) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const monthIndex = this.months.indexOf(month);
      
      let year = currentYear;
      if (monthIndex < currentMonth) {
        year += 1;
      }
      
      return `${month} ${year}`;
    }
  },
  async created() {
    console.log('BulkMeatPage component created');
    await this.fetchData();
  },
  mounted() {
    console.log('BulkMeatPage component mounted');
  },
  watch: {
    locations: {
      handler(newLocations) {
        console.log('Locations updated:', newLocations);
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.v-btn {
  margin-right: 8px;
}

.v-card {
  margin-bottom: 20px;
}

.v-table {
  margin-top: 20px;
}

.v-dialog {
  max-width: 500px;
}
</style>
