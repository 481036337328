<!-- ./frontend/src/views/AdminUsersView.vue -->
<template>
  <v-container>
    <h1 class="text-h4 mb-4">Admin: All Users</h1>
    
    <v-card class="mb-4">
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Search users"
          prepend-icon="mdi-magnify"
          @input="applyFilters"
        ></v-text-field>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filterPremium"
              :items="['All', 'Premium', 'Non-Premium']"
              label="Filter by Premium Status"
              @change="applyFilters"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filterSeller"
              :items="['All', 'Seller', 'Non-Seller']"
              label="Filter by Seller Status"
              @change="applyFilters"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filterAdmin"
              :items="['All', 'Admin', 'Non-Admin']"
              label="Filter by Admin Status"
              @change="applyFilters"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="filteredUsers"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.isAdmin`]="{ item }">
        <v-checkbox v-model="item.isAdmin" @change="updateUser(item)"></v-checkbox>
      </template>
      <template #[`item.isSeller`]="{ item }">
        <v-checkbox v-model="item.isSeller" @change="updateUser(item)"></v-checkbox>
      </template>
      <template #[`item.isPremium`]="{ item }">
        <v-checkbox v-model="item.isPremium" @change="updateUserPremium(item)"></v-checkbox>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editUser(item)" class="mr-2">Edit</v-btn>
        <v-btn small color="info" @click="viewUserDetails(item)" class="mr-2">Details</v-btn>
        <v-btn small color="error" @click="deleteUser(item._id)">Delete</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveUser">
            <v-text-field v-model="editedUser.name" label="Name"></v-text-field>
            <v-text-field v-model="editedUser.email" label="Email"></v-text-field>
            <v-text-field v-model="editedUser.phone" label="Phone"></v-text-field>
            <v-checkbox v-model="editedUser.isAdmin" label="Is Admin"></v-checkbox>
            <v-checkbox v-model="editedUser.isSeller" label="Is Seller"></v-checkbox>
            <v-checkbox v-model="editedUser.isPremium" label="Is Premium"></v-checkbox>
            <v-select
              v-model="editedUser.languagePreference"
              :items="['English', 'French']"
              label="Language Preference"
            ></v-select>
            <v-text-field
              v-if="editedUser.isPremium"
              v-model="editedUser.premiumStartDate"
              label="Premium Start Date"
              type="date"
            ></v-text-field>
            <v-text-field
              v-if="editedUser.isPremium"
              v-model="editedUser.premiumRenewalDate"
              label="Premium Renewal Date"
              type="date"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detailsDialog" max-width="800px">
      <v-card>
        <v-card-title>User Details</v-card-title>
        <v-card-text>
          <h3>Addresses</h3>
          <v-list>
            <v-list-item v-for="(address, index) in selectedUser.addresses" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ formatAddress(address) }}</v-list-item-title>
                <v-list-item-subtitle v-if="address.isPreferred">Preferred Address</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <h3 class="mt-4">Payment Methods</h3>
          <v-list>
            <v-list-item v-for="(method, index) in selectedUser.paymentMethods" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ method.cardType }} ending in {{ method.lastFour }}</v-list-item-title>
                <v-list-item-subtitle>Expires: {{ method.expiryMonth }}/{{ method.expiryYear }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <h3 class="mt-4">Saved Items</h3>
          <v-list>
            <v-list-item v-for="item in selectedUser.savedItems" :key="item._id">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <h3 class="mt-4">Notifications</h3>
          <v-data-table
            :headers="notificationHeaders"
            :items="selectedUser.notifications"
            :items-per-page="5"
          >
            <template #[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
          </v-data-table>

          <h3 class="mt-4">Search History</h3>
          <v-list>
            <v-list-item v-for="(search, index) in selectedUser.searchHistory" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ search.query }}</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(search.timestamp) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="detailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AdminUsersView',
  setup() {
    const store = useStore();
    const users = ref([]);
    const editDialog = ref(false);
    const detailsDialog = ref(false);
    const editedUser = ref({});
    const selectedUser = ref({});
    const search = ref('');
    const filterPremium = ref('All');
    const filterSeller = ref('All');
    const filterAdmin = ref('All');
    const notificationPage = ref(1);
    const notificationLimit = ref(10);
    const totalNotifications = ref(0);
    const totalPages = ref(0);

    const headers = [
      { title: 'Name', key: 'name' },
      { title: 'Email', key: 'email' },
      { title: 'Phone', key: 'phone' },
      { title: 'Admin', key: 'isAdmin' },
      { title: 'Seller', key: 'isSeller' },
      { title: 'Premium', key: 'isPremium' },
      { title: 'Language', key: 'languagePreference' },
      { title: 'Addresses', key: 'addresses.length' },
      { title: 'Payment Methods', key: 'paymentMethods.length' },
      { title: 'Saved Items', key: 'savedItems.length' },
      { title: 'Actions', key: 'actions', sortable: false }
    ];

    const notificationHeaders = [
      { text: 'Subject', value: 'subject' },
      { text: 'Content', value: 'content' },
      { text: 'Date', value: 'createdAt' },
      { text: 'Read', value: 'isRead' },
    ];

    const filteredUsers = computed(() => {
      return users.value.filter(user => {
        const matchesSearch = (user.name && user.name.toLowerCase().includes(search.value.toLowerCase())) ||
                              (user.email && user.email.toLowerCase().includes(search.value.toLowerCase())) ||
                              (user.phone && user.phone.includes(search.value));
        const matchesPremium = filterPremium.value === 'All' || 
                               (filterPremium.value === 'Premium' && user.isPremium) ||
                               (filterPremium.value === 'Non-Premium' && !user.isPremium);
        const matchesSeller = filterSeller.value === 'All' || 
                              (filterSeller.value === 'Seller' && user.isSeller) ||
                              (filterSeller.value === 'Non-Seller' && !user.isSeller);
        const matchesAdmin = filterAdmin.value === 'All' || 
                             (filterAdmin.value === 'Admin' && user.isAdmin) ||
                             (filterAdmin.value === 'Non-Admin' && !user.isAdmin);
        return matchesSearch && matchesPremium && matchesSeller && matchesAdmin;
      });
    });

    const fetchUsers = async () => {
      try {
        const response = await store.dispatch('fetchUsers');
        users.value = response;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Show error message to user
      }
    };

    const editUser = (user) => {
      editedUser.value = { ...user };
      editDialog.value = true;
    };

    const closeEdit = () => {
      editDialog.value = false;
      editedUser.value = {};
    };

    const saveUser = async () => {
      try {
        await store.dispatch('updateUser', editedUser.value);
        await fetchUsers();
        closeEdit();
      } catch (error) {
        console.error('Error updating user:', error);
        // Show error message to user
      }
    };

    const deleteUser = async (userId) => {
      if (confirm('Are you sure you want to delete this user?')) {
        try {
          await store.dispatch('deleteUser', userId);
          await fetchUsers();
        } catch (error) {
          console.error('Error deleting user:', error);
          // Show error message to user
        }
      }
    };

    const viewUserDetails = async (user) => {
      try {
        selectedUser.value = { ...user, notifications: [], notificationPage: 1 };
        detailsDialog.value = true;
        await fetchUserNotifications();
      } catch (error) {
        console.error('Error fetching user details:', error);
        // Show error message to user
      }
    };

    const fetchUserNotifications = async () => {
      try {
        const response = await store.dispatch('fetchUserNotifications', {
          userId: selectedUser.value._id,
          page: notificationPage.value,
          limit: notificationLimit.value
        });
        selectedUser.value.notifications = response.notifications;
        totalNotifications.value = response.totalNotifications;
        totalPages.value = response.totalPages;
      } catch (error) {
        console.error('Error fetching user notifications:', error);
        // Show error message to user
      }
    };

    const loadMoreNotifications = async () => {
      if (notificationPage.value < totalPages.value) {
        notificationPage.value++;
        await fetchUserNotifications();
      }
    };

    const updateUser = async (user) => {
      try {
        await store.dispatch('updateUser', user);
        await fetchUsers();
      } catch (error) {
        console.error('Error updating user:', error);
        // Show error message to user
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const formatAddress = (address) => {
      if (!address) return 'N/A';
      return `${address.street}, ${address.city}, ${address.province} ${address.postalCode}`;
    };

    const applyFilters = () => {
      // This method is called whenever a filter criteria changes
      // The filteredUsers computed property will automatically update
    };

    onMounted(() => {
      fetchUsers();
    });

    return {
      users,
      headers,
      editDialog,
      detailsDialog,
      editedUser,
      selectedUser,
      filteredUsers,
      search,
      filterPremium,
      filterSeller,
      filterAdmin,
      notificationHeaders,
      notificationPage,
      notificationLimit,
      totalNotifications,
      totalPages,
      editUser,
      closeEdit,
      saveUser,
      deleteUser,
      updateUser,
      viewUserDetails,
      fetchUserNotifications,
      loadMoreNotifications,
      formatDate,
      formatAddress,
      applyFilters
    };
  }
};
</script>
