<template>
  <v-container fluid>
    <v-row>
        <AccountManagementDrawer />

      <v-col fluid>
          <h1 class="text-h4 mb-6">Your Orders</h1>
          <p class="text-medium-emphasis mb-8">
            View and manage your order history
          </p>

        <v-expansion-panels v-if="orders.length">
          <v-expansion-panel v-for="order in orders" :key="order._id">
            <v-expansion-panel-title>
              <div class="d-flex justify-space-between align-center w-100">
                <div>
                  <p class="font-weight-medium">Order #{{ order._id }}</p>
                  <p class="text-caption">{{ formatDate(order.createdAt) }}</p>
                </div>
                <div>
                  <p class="font-weight-medium">${{ order.totalAmount.toFixed(2) }}</p>
                  <p class="text-caption">{{ order.status }}</p>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list>
                <v-list-subheader>Order Items</v-list-subheader>
                <v-list-item v-for="item in order.items" :key="item.productId._id">
                  <v-list-item-title>{{ item.productId.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Quantity: {{ item.quantity }} | Price: ${{ item.price.toFixed(2) }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
              <v-divider class="my-4"></v-divider>
              <h3 class="text-h6 mb-2">Shipping Information</h3>
              <p>{{ order.deliveryAddress.street }}</p>
              <p>{{ order.deliveryAddress.city }}, {{ order.deliveryAddress.province }} {{ order.deliveryAddress.postalCode }}</p>
              <v-divider class="my-4"></v-divider>
              <h3 class="text-h6 mb-2">Payment Information</h3>
              <p>Total: ${{ order.totalAmount.toFixed(2) }}</p>
              <p>Status: {{ order.status }}</p>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card v-else class="text-center pa-6">
          <v-card-text>
            <p class="text-h6">You haven't placed any orders yet.</p>
            <v-btn color="primary" class="mt-4" to="/">Start Shopping</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import AccountManagementDrawer from '@/components/AccountManagementDrawer.vue';

export default {
  name: 'OrdersPage',
  components: {
    AccountManagementDrawer,
  },
  setup() {
    const store = useStore();
    const orders = computed(() => store.state.orders);

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    onMounted(() => {
      store.dispatch('fetchOrders');
    });

    return {
      orders,
      formatDate,
    };
  },
};
</script>
