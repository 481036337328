<!-- ./frontend/src/components/AccountMFACard.vue -->
<template>
  <v-card outlined class="mb-6">
    <v-card-title>
      <v-icon left>mdi-shield-account</v-icon>
      Multi-Factor Authentication
    </v-card-title>
    <v-card-text>
      <v-switch
        v-model="mfaEnabledLocal"
        label="Enable Multi-Factor Authentication"
        @change="toggleMFA"
      ></v-switch>
      <v-expand-transition>
        <div v-if="mfaEnabledLocal">
          <v-text-field
            v-model="mfaCode"
            label="Enter MFA Code"
            type="text"
            :rules="[v => !!v || 'MFA Code is required']"
          ></v-text-field>
          <v-btn color="primary" @click="verifyMFA">Verify MFA</v-btn>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'AccountMFACard',
  props: {
    mfaEnabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-mfa', 'verify-mfa'],
  setup(props, { emit }) {
    const mfaEnabledLocal = ref(props.mfaEnabled);
    const mfaCode = ref('');

    watch(() => props.mfaEnabled, (newValue) => {
      mfaEnabledLocal.value = newValue;
    });

    const toggleMFA = () => {
      emit('toggle-mfa', mfaEnabledLocal.value);
    };

    const verifyMFA = () => {
      emit('verify-mfa', mfaCode.value);
      mfaCode.value = '';
    };

    return {
      mfaEnabledLocal,
      mfaCode,
      toggleMFA,
      verifyMFA
    };
  }
};
</script>
