<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="mt-5">
          <v-card-title class="headline">Join Bulk Meat Order</v-card-title>
          <v-card-text v-if="bulkOrder">
            <p>You've been invited by {{ inviterName }} to join a bulk meat order!</p>
            <v-list>
              <v-list-item>
                <v-list-item-title>Meat Type: {{ bulkOrder.meatType }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Specifications: {{ bulkOrder.specifications.join(', ') }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Location: {{ bulkOrder.location }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Available Quantity: {{ bulkOrder.availableQuantity }}lbs</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-btn color="primary" @click="navigateToMoreInfo" class="mb-4">More Info</v-btn>
            <v-form @submit.prevent="joinOrder" v-if="!isLoggedIn">
              <v-text-field
                v-model="name"
                label="Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" block>Sign Up and Join Order</v-btn>
            </v-form>
            <v-form @submit.prevent="joinOrder" v-else>
              <v-text-field
                v-model="quantity"
                label="Quantity committed to (lbs)"
                type="number"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" block>Join Order</v-btn>
            </v-form>
          </v-card-text>
          <v-card-text v-else>
            <p>Loading order information...</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'BulkOrderLanding',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      quantity: null,
    }
  },
  computed: {
    ...mapState(['bulkOrder']),
    ...mapGetters(['isLoggedIn']),
    inviterName() {
      return this.bulkOrder?.inviter?.name || 'Unknown';
    },
  },
  methods: {
    ...mapActions(['fetchBulkOrderByInviteCode', 'login', 'signup', 'joinBulkOrder']),
    async joinOrder() {
      try {
        if (!this.isLoggedIn) {
          await this.signup({
            name: this.name,
            email: this.email,
            password: this.password
          });
        }
        await this.joinBulkOrder({
          orderId: this.bulkOrder._id,
          quantity: this.quantity,
        });
        // Redirect to the bulk meat page or show a success message
        this.$router.push('/bulk-meat');
      } catch (error) {
        console.error('Error joining bulk order:', error);
        // Handle error (e.g., show an error message to the user)
      }
    },
    navigateToMoreInfo() {
      if (this.bulkOrder && this.bulkOrder._id) {
        this.$router.push(`/bulk-order-info/${this.bulkOrder._id}`);
      }
    },
  },
  created() {
    const inviteCode = this.$route.params.inviteCode;
    this.fetchBulkOrderByInviteCode(inviteCode);
  },
}
</script>
