<template>
  <v-container>
    <h1>Admin: Bulk Meat Products</h1>
    <v-btn color="primary" @click="openDialog" class="mb-4">Add New Bulk Meat Product</v-btn>
    <v-data-table
      :headers="headers"
      :items="bulkMeatProducts"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editItem(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteItem(item)">Delete</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="save" ref="form">
            <v-text-field
              v-model="editedItem.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedItem.animalType"
              label="Animal Type"
              :rules="[v => !!v || 'Animal Type is required']"
              required
            ></v-text-field>
            <v-textarea
              v-model="editedItem.description"
              label="Description"
              :rules="[v => !!v || 'Description is required']"
              required
            ></v-textarea>
            <v-subheader>Specification Chart</v-subheader>
            <v-btn small color="secondary" @click="addSpecificationChartItem" class="mb-2">Add Specification</v-btn>
            <v-row v-for="(item, index) in editedItem.specificationChart" :key="index" class="mb-2">
              <v-col cols="4">
                <v-text-field v-model="item.specification" label="Specification"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="item.specificationDescription" label="Description"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="item.monthsAvailable"
                  :items="months"
                  label="Months Available"
                  multiple
                  chips
                  small-chips
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-btn small color="error" @click="removeSpecificationChartItem(index)">Remove</v-btn>
              </v-col>
            </v-row>
            <v-combobox
              v-model="editedItem.pickUpMonths"
              label="Pick Up Months"
              multiple
              chips
              small-chips
              :items="months"
            ></v-combobox>
            <v-textarea
              v-model="editedItem.orderProcess"
              label="Order Process"
            ></v-textarea>
            <v-textarea
              v-model="editedItem.pickUpProcess"
              label="Pick Up Process"
            ></v-textarea>
            <v-combobox
              v-model="editedItem.pickupLocations"
              label="Pickup Locations"
              multiple
              chips
              small-chips
            ></v-combobox>
            <v-text-field
              v-model.number="editedItem.averageDressedWeight"
              label="Average Dressed Weight"
              type="number"
            ></v-text-field>
            <v-switch
              v-model="editedItem.halalOption"
              label="Halal Option Available"
            ></v-switch>
            
            <v-subheader>Price Chart</v-subheader>
            <v-btn small color="secondary" @click="addPriceChartItem" class="mb-2">Add Price Chart Item</v-btn>
            <v-row v-for="(item, index) in editedItem.priceChart" :key="index" class="mb-2">
              <v-col cols="2">
                <v-text-field v-model.number="item.quantity" label="Quantity" type="number"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model.number="item.price" label="Price" type="number"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="item.comment" label="Comment"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="item.measure" label="Measure"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn small color="error" @click="removePriceChartItem(index)">Remove</v-btn>
              </v-col>
            </v-row>

            <v-subheader>Cut Options</v-subheader>
            <v-btn small color="secondary" @click="addCutOption" class="mb-2">Add Cut Option</v-btn>
            <v-row v-for="(cut, index) in editedItem.cutOptions" :key="index" class="mb-2">
              <v-col cols="5">
                <v-text-field v-model="cut.cut" label="Cut"></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field v-model="cut.cutDescription" label="Cut Description"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn small color="error" @click="removeCutOption(index)">Remove</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminBulkMeatProductsView',
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Animal Type', value: 'animalType' },
        { text: 'Description', value: 'description' },
        { text: 'Average Dressed Weight', value: 'averageDressedWeight' },
        { text: 'Halal Option', value: 'halalOption' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        animalType: '',
        description: '',
        specificationChart: [],
        pickUpMonths: [],
        priceChart: [],
        orderProcess: '',
        pickUpProcess: '',
        pickupLocations: [],
        cutOptions: [],
        averageDressedWeight: 0,
        halalOption: false
      },
      defaultItem: {
        name: '',
        animalType: '',
        description: '',
        specificationChart: [],
        pickUpMonths: [],
        priceChart: [],
        orderProcess: '',
        pickUpProcess: '',
        pickupLocations: [],
        cutOptions: [],
        averageDressedWeight: 0,
        halalOption: false
      },
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
    };
  },
  computed: {
    ...mapState(['bulkMeatProducts']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Bulk Meat Product' : 'Edit Bulk Meat Product';
    }
  },
  methods: {
    ...mapActions(['fetchBulkMeatProducts', 'createBulkMeatProduct', 'updateBulkMeatProduct', 'deleteBulkMeatProduct']),
    openDialog() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.bulkMeatProducts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      if (confirm('Are you sure you want to delete this item?')) {
        try {
          await this.deleteBulkMeatProduct(item._id);
          this.fetchBulkMeatProducts();
        } catch (error) {
          console.error('Error deleting bulk meat product:', error);
        }
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedIndex > -1) {
            await this.updateBulkMeatProduct(this.editedItem);
          } else {
            await this.createBulkMeatProduct(this.editedItem);
          }
          this.close();
          this.fetchBulkMeatProducts();
        } catch (error) {
          console.error('Error saving bulk meat product:', error);
        }
      }
    },
    removePriceChartItem(index) {
      this.editedItem.priceChart.splice(index, 1);
    },
    addCutOption() {
      this.editedItem.cutOptions.push({ cut: '', cutDescription: '' });
    },
    removeCutOption(index) {
      this.editedItem.cutOptions.splice(index, 1);
    },
    addSpecificationChartItem() {
      this.editedItem.specificationChart.push({ specification: '', specificationDescription: '', monthsAvailable: [] });
    },
    removeSpecificationChartItem(index) {
      this.editedItem.specificationChart.splice(index, 1);
    }
  },
  created() {
    this.fetchBulkMeatProducts();
  }
};
</script>
