<!-- ./frontend/src/views/ResetPasswordPage.vue -->
<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="resetPassword">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="New Password"
                name="password"
                prepend-icon="mdi-lock"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm New Password"
                name="confirmPassword"
                prepend-icon="mdi-lock"
                @click:append="showConfirmPassword = !showConfirmPassword"
                required
              ></v-text-field>
              <v-btn
                color="#36454F"
                class="mt-4"
                type="submit"
                block
                :loading="loading"
              >
                Reset Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'ResetPasswordPage',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const password = ref('')
    const confirmPassword = ref('')
    const showPassword = ref(false)
    const showConfirmPassword = ref(false)
    const loading = ref(false)

    const resetPassword = async () => {
      if (password.value !== confirmPassword.value) {
        alert('Passwords do not match')
        return
      }

      loading.value = true
      try {
        await store.dispatch('user/confirmResetPassword', {
          token: route.params.token,
          password: password.value
        })
        alert('Password has been reset successfully')
        router.push('/login')
      } catch (error) {
        console.error('Password reset failed:', error)
        alert('Failed to reset password. Please try again.')
      } finally {
        loading.value = false
      }
    }

    return {
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      loading,
      resetPassword
    }
  }
}
</script>
