<!-- ./frontend/src/views/SellerPage.vue -->
<template>
  <v-container class="pa-md-6" fluid v-if="seller">
    <!-- Hero Section -->
    <div class="hero-section position-relative">
      <v-img
        :src="seller.coverPhoto || 'https://via.placeholder.com/1200x400'"
        height="300"
        class="align-end"
        cover
      >
        <v-avatar
          size="150"
          class="seller-avatar"
        >
          <v-img :src="seller.profileImage || 'https://via.placeholder.com/150'" alt="Seller Profile"></v-img>
        </v-avatar>
      </v-img>

      <v-container class="hero-content pt-16">
        <v-row no-gutters>
          <v-col cols="12" md="8" offset-md="3">
            <div class="d-flex align-center">
              <div>
                <div class="d-flex align-center">
                  <h1 :class="[`text-h3 font-weight-bold mb-2`, `text--${seller.heroColor}`]">
                    {{ seller.name }}
                  </h1>
                  <v-icon color="success" class="ml-2">mdi-check-decagram</v-icon>
                  <span :class="[`text-caption ml-2`, `text--${seller.heroColor}`]">
                    Loquo verified {{ seller.type }}
                  </span>
                </div>
                <p :class="[`text-subtitle-1 mb-4`, `text--${seller.heroColor}`]">
                  {{ seller.location.city }}, {{ seller.location.provinceTerritory }}
                </p>
                <div class="d-flex">
                  <v-btn color="success" class="mr-2">Subscribe</v-btn>
                  <v-btn icon class="mr-2">
                    <v-icon>mdi-bell-plus</v-icon>
                  </v-btn>
                  <v-btn icon @click="shareSellerPage">
                    <v-icon>mdi-share-variant</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Delivery and Pickup Information -->
    <v-card class="mb-4 mt-4">
      <v-card-text>
        <p v-if="seller.deliverySchedule && seller.deliverySchedule.length > 0">
          Delivery is available weekly on {{ seller.deliverySchedule[0].readyForDeliveryDay }}s nearby {{ seller.location.city }}.
        </p>
        <p v-if="seller.pickupSchedule && seller.pickupSchedule.length > 0">
          Pickup schedule: {{ seller.pickupSchedule[0].pickupDay }} from {{ seller.pickupSchedule[0].pickupStartTime }} to {{ seller.pickupSchedule[0].pickupEndTime }} at {{ seller.pickupSchedule[0].pickupLocation }}.
        </p>
      </v-card-text>
    </v-card>

    <!-- Tab Navigation -->
    <v-tabs v-model="activeTab" color="primary" grow>
      <v-tab value="products">Products</v-tab>
      <v-tab value="images">Images</v-tab>
      <v-tab value="recommendations">Recommendations</v-tab>
      <v-tab value="certifications">Certifications</v-tab>
    </v-tabs>

    <v-window v-model="activeTab">
      <v-window-item value="products">
        <!-- Products Section -->
        <v-container>
          <h2 class="text-h5 mb-4">Products</h2>
          <v-row>
            <v-col v-for="product in activeProducts" :key="product._id" cols="12" sm="6" md="4">
              <ProductCard
                :product="product"
                :cart-quantity="cartQuantities[product._id] || 0"
                @navigate-to-product="navigateToProduct"
                @add-to-cart="addToCart"
                @increase-quantity="increaseQuantity"
                @decrease-quantity="decreaseQuantity"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="images">
        <!-- Images Section -->
        <v-container>
          <h2 class="text-h5 mb-4">Images</h2>
          <v-row>
            <v-col v-for="(image, index) in seller.images" :key="index" cols="12" sm="6" md="4">
              <v-img :src="image" aspect-ratio="1" @click="openPhotoGallery(index)"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="recommendations">
        <!-- Recommendations Section -->
        <v-container class="pa-md-6" fluid>
          <section>
            <v-responsive class="mx-auto" max-width="700">
              <v-row :no-gutters="$vuetify.display.smAndDown">
                <template v-for="(recommendation, i) in productRecommendations" :key="i">
                  <v-col cols="12">
                    <v-list-item class="px-0" lines="three">
                      <template #prepend>
                        <div class="d-flex flex-column align-center">
                          <v-img
                            :src="recommendation.productId.productImage || 'https://via.placeholder.com/80'"
                            width="80"
                            height="80"
                            cover
                            class="mb-2"
                          ></v-img>
                          <v-chip
                            :color="recommendation.type.includes('changedLife') ? 'error' : 'primary'"
                            small
                          >
                            {{ recommendation.type.includes('changedLife') ? 'Life Changed' : 'Recommendation' }}
                          </v-chip>
                        </div>
                      </template>
                      <v-list-item-title>{{ recommendation.productId.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ recommendation.userId.name }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ formatDate(recommendation.createdAt) }}</v-list-item-subtitle>
                      <div class="py-3 text-body-2 opacity-60" v-html="recommendation.comment" />
                    </v-list-item>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="my-8 my-md-0" />
                  </v-col>
                </template>
              </v-row>
            </v-responsive>
          </section>
        </v-container>
      </v-window-item>

      <v-window-item value="certifications">
        <!-- Certifications Section -->
        <v-container>
          <h2 class="text-h5 mb-4">Certifications</h2>
          <p>Coming soon!</p>
        </v-container>
      </v-window-item>
    </v-window>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'SellerPage',
  components: {
    ProductCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const seller = ref(null)
    const products = ref([])
    const productRecommendations = ref([])
    const activeTab = ref('products')
    const photoGalleryDialog = ref(false)
    const currentPhotoIndex = ref(0)
    const snackbar = ref(false)
    const snackbarText = ref('')

    const cartQuantities = computed(() => {
      const quantities = {}
      store.state.cart.forEach(item => {
        quantities[item._id] = item.quantity
      })
      return quantities
    })

    const activeProducts = computed(() => {
      console.log('Computing active products:', products.value)
      return products.value.filter(p => p && p.isActive)
    })

    const fetchSellerData = async (sellerId) => {
      try {
        if (!sellerId) {
          console.error('Seller ID is undefined');
          return;
        }
        const sellerData = await store.dispatch('fetchSellerById', sellerId);
        seller.value = sellerData;
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    };

    const navigateToProduct = (product) => {
      if (product && product.category) {
        router.push(`/${product.category.toLowerCase()}/${product._id}`)
      } else {
        console.error('Invalid product data:', product)
      }
    }

    const addToCart = (product) => {
      store.dispatch('addToCart', product)
    }

    const increaseQuantity = (product) => {
      store.dispatch('updateCartItemQuantity', { 
        productId: product._id, 
        quantity: (cartQuantities.value[product._id] || 0) + 1 
      })
    }

    const decreaseQuantity = (product) => {
      const currentQuantity = cartQuantities.value[product._id] || 0
      if (currentQuantity > 0) {
        store.dispatch('updateCartItemQuantity', { 
          productId: product._id, 
          quantity: currentQuantity - 1 
        })
      }
    }

    const openPhotoGallery = (index) => {
      currentPhotoIndex.value = index
      photoGalleryDialog.value = true
    }

    const shareSellerPage = () => {
      const url = window.location.href
      navigator.clipboard.writeText(url).then(() => {
        snackbarText.value = 'Seller page link copied to clipboard'
        snackbar.value = true
      }, (err) => {
        console.error('Could not copy text: ', err)
      })
    }

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(dateString).toLocaleDateString(undefined, options)
    }

    onMounted(async () => {
      const sellerUrl = route.params.url;
      try {
        const sellerResponse = await store.dispatch('fetchSellerByUrl', sellerUrl);
        seller.value = sellerResponse;
        console.log('Seller data:', seller.value);

        if (seller.value) {
          const productsResponse = await store.dispatch('fetchProductsBySeller', seller.value._id);
          products.value = productsResponse;
          console.log('Products data:', products.value);

          if (seller.value._id) {
            const recommendationsResponse = await store.dispatch('fetchRecommendationsBySeller', seller.value._id);
            productRecommendations.value = recommendationsResponse;
            console.log('Recommendations data:', productRecommendations.value);

            // Update product recommendations count
            products.value = products.value.map(product => {
              const recommendations = productRecommendations.value.filter(r => r.productId._id === product._id);
              return {
                ...product,
                countRecommendations: recommendations.filter(r => r.type.includes('recommendation')).length,
                countChangedMyLife: recommendations.filter(r => r.type.includes('changedLife')).length
              };
            });
          }
        }
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    })

    return {
      seller,
      products,
      activeProducts,
      productRecommendations,
      activeTab,
      cartQuantities,
      photoGalleryDialog,
      currentPhotoIndex,
      snackbar,
      snackbarText,
      navigateToProduct,
      addToCart,
      increaseQuantity,
      decreaseQuantity,
      openPhotoGallery,
      shareSellerPage,
      formatDate
    }
  }
}
</script>

<style scoped>
.hero-section {
  position: relative;
  margin-bottom: 80px;
}

.seller-avatar {
  position: absolute;
  bottom: -75px;
  left: 24px;
  border: 4px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-content {
  position: relative;
  z-index: 1;
}

.v-btn {
  text-transform: none;
}
</style>
