// ./frontend/src/store/index.js

import { createStore } from 'vuex'
import axiosInstance from '../utils/axios'
import getStripe from '../utils/stripe'
import payment from './modules/payment';
import address from './modules/address';
import user from './modules/user';
import userVerification from './modules/userVerification';

export default createStore({
  state: {
    products: [],
    bulkMeatProducts: [],
    bulkOrders: [],
    bulkOrderInfo: null,
    seller: null,
    sellers: [],
    sellersByUserId: [],
    orders: [],
    notifications: [],
    bulkOrder: null,
    users: [],
    localShippingZones: [],
    cart: [],
    allProducts: [],
    deliveryOptions: [],
    selectedDeliveryOption: null,
    savedItems: [],
    searchResults: [],
    categories: [],
    categoryTree: [],
    recommendations: [],
    userRecommendations: [],
    currentProduct: null,
    paymentError: null,
  },
  getters: {
    getProductById: (state) => (id) => {
      return state.products.find(product => product._id === id)
    },
    getBulkOrderById: (state) => (id) => {
      return state.bulkOrders.find(order => order._id === id)
    },
    getSellerById: (state) => (id) => {
      return state.sellers.find(seller => seller._id === id)
    },
    getLocationById: (state) => (id) => {
      return state.locations.find(location => location._id === id)
    },
    cartItemCount: state => state.cart.reduce((total, item) => total + item.quantity, 0),
    cartTotal: state => state.cart.reduce((total, item) => total + item.price * item.quantity, 0),
    paymentError: state => state.paymentError,
  },
  mutations: {
    UPDATE_ORDER_PAYMENT_STATUS(state, { orderId, paymentStatus }) {
      const orderIndex = state.orders.findIndex(order => order._id === orderId);
      if (orderIndex !== -1) {
        state.orders[orderIndex].paymentStatus = paymentStatus;
      }
    },
    SET_PREMIUM_SUBSCRIPTIONS(state, subscriptions) {
      state.premiumSubscriptions = subscriptions;
    },
    SET_PAYMENT_ERROR(state, error) {
      state.paymentError = error;
    },
    CLEAR_PAYMENT_ERROR(state) {
      state.paymentError = null;
    },
    UPDATE_USER_SUBSCRIPTION(state, subscription) {
      if (state.user) {
        state.user.subscription = subscription;
      }
    },
    SET_SUBSCRIPTION_DETAILS(state, subscriptionDetails) {
      if (state.user) {
        state.user.subscription = subscriptionDetails;
      }
    },
    UPDATE_SUBSCRIPTION(state, updateData) {
      if (state.user && state.user.subscription) {
        state.user.subscription = { ...state.user.subscription, ...updateData };
      }
    },
    SET_SELECTED_DELIVERY_OPTION(state, option) {
      state.selectedDeliveryOption = option;
    },
    SET_SELLER(state, seller) {
      state.seller = seller
    },
    SET_SELLERS_BY_USER_ID(state, sellers) {
      state.sellersByUserId = sellers;
    },
    SET_USER_AS_SELLER(state) {
      if (state.user) {
        state.user.isSeller = true;
      }
    },
    SET_RECOMMENDATIONS(state, recommendations) {
      state.recommendations = recommendations;
    },
    SET_USER_RECOMMENDATIONS(state, recommendations) {
      state.userRecommendations = recommendations;
    },
    SET_SAVED_ITEMS(state, items) {
      state.savedItems = items;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_CATEGORY_TREE(state, categoryTree) {
      state.categoryTree = categoryTree;
    },
    ADD_CATEGORY(state, category) {
      state.categories.push(category);
    },
    UPDATE_CATEGORY(state, updatedCategory) {
      const index = state.categories.findIndex(c => c._id === updatedCategory._id);
      if (index !== -1) {
        state.categories.splice(index, 1, updatedCategory);
      }
    },
    REMOVE_CATEGORY(state, categoryId) {
      state.categories = state.categories.filter(c => c._id !== categoryId);
    },
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results
    },
    SET_CART(state, newCart) {
      state.cart = newCart;
      localStorage.setItem('cart', JSON.stringify(newCart));
    },
    ADD_TO_CART(state, { product, deliveryOption }) {
      console.log('ADD_TO_CART mutation called with:', { product, deliveryOption });
      const cartItem = state.cart.find(item => item._id === product._id);
      if (cartItem) {
        cartItem.quantity++;
        console.log('Updated existing cart item:', cartItem);
      } else {
        state.cart.push({ 
          ...product, 
          quantity: 1, 
          deliveryOption: deliveryOption,
          addedAt: new Date() 
        });
        console.log('Added new item to cart:', state.cart[state.cart.length - 1]);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart));
      console.log('Updated cart in localStorage');
    },
    REMOVE_FROM_CART(state, productId) {
      const index = state.cart.findIndex(item => item._id === productId);
      if (index !== -1) {
        state.cart.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    UPDATE_CART_ITEM_QUANTITY(state, { productId, quantity }) {
      const item = state.cart.find(item => item._id === productId);
      if (item) {
        item.quantity = quantity;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    CLEAR_CART(state) {
      state.cart = [];
      localStorage.removeItem('cart');
    },
    UPDATE_CART_PRODUCTS(state, products) {
      console.log('UPDATE_CART_PRODUCTS mutation called with:', products);
      state.cart = state.cart.map(cartItem => {
        const updatedProduct = products.find(p => p._id === cartItem._id);
        console.log('Updating cart item:', cartItem);
        console.log('Updated product data:', updatedProduct);
        return updatedProduct ? { ...cartItem, ...updatedProduct, deliveryOption: cartItem.deliveryOption } : cartItem;
      });
      console.log('Updated cart:', state.cart);
    },
    UPDATE_CART_ITEM_DELIVERY_OPTION(state, { productId, deliveryOption }) {
      const item = state.cart.find(item => item._id === productId);
      if (item) {
        item.deliveryOption = deliveryOption;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    ADD_TO_SAVED_ITEMS(state, product) {
      if (!state.user.savedItems) {
        state.user.savedItems = [];
      }
      state.user.savedItems.push(product);
    },

    REMOVE_FROM_SAVED_ITEMS(state, productId) {
      if (state.user.savedItems) {
        state.user.savedItems = state.user.savedItems.filter(item => item._id !== productId);
      }
    },
    SET_DELIVERY_OPTIONS(state, options) {
      state.deliveryOptions = options;
    },
    SET_DELIVERY_OPTION(state, option) {
      state.selectedDeliveryOption = option;
    },
    UPDATE_PAYMENT_METHOD(state, { id, paymentMethod }) {
      console.log('Updating payment method:', id, paymentMethod);
      const index = state.user.paymentMethods.findIndex(pm => pm._id === id);
      if (index !== -1) {
        state.user.paymentMethods.splice(index, 1, paymentMethod);
      }
    },
    ADD_PAYMENT_METHOD(state, paymentMethod) {
      if (!state.user.paymentMethods) {
        state.user.paymentMethods = [];
      }
      state.user.paymentMethods.push(paymentMethod);
    },
    ADD_PRODUCT(state, product) {
      state.allProducts.push(product);
    },
    UPDATE_PRODUCT(state, updatedProduct) {
      const index = state.products.findIndex(p => p._id === updatedProduct._id);
      if (index !== -1) {
        state.products.splice(index, 1, updatedProduct);
      } else {
        state.products.push(updatedProduct);
      }
    },
    SET_PRODUCT(state, product) {
      state.currentProduct = product;
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_ALL_PRODUCTS(state, products) {
      state.allProducts = products
    },
    SET_BULK_MEAT_PRODUCTS(state, bulkMeatProducts) {
      state.bulkMeatProducts = bulkMeatProducts
    },
    ADD_BULK_MEAT_PRODUCT(state, bulkMeatProduct) {
      state.bulkMeatProducts.push(bulkMeatProduct)
    },
    UPDATE_BULK_MEAT_PRODUCT(state, updatedBulkMeatProduct) {
      const index = state.bulkMeatProducts.findIndex(p => p._id === updatedBulkMeatProduct._id)
      if (index !== -1) {
        state.bulkMeatProducts.splice(index, 1, updatedBulkMeatProduct)
      }
    },
    REMOVE_BULK_MEAT_PRODUCT(state, id) {
      state.bulkMeatProducts = state.bulkMeatProducts.filter(p => p._id !== id)
    },
    SET_BULK_ORDERS(state, bulkOrders) {
      state.bulkOrders = bulkOrders
    },
    ADD_BULK_ORDER(state, bulkOrder) {
      state.bulkOrders.push(bulkOrder)
    },
    SET_BULK_ORDER_INFO(state, info) {
      state.bulkOrderInfo = info;
    },
    SET_SELLERS(state, sellers) {
      state.sellers = sellers;
    },
    ADD_SELLER(state, seller) {
      state.sellers.push(seller)
    },
    UPDATE_SELLER(state, updatedSeller) {
      const index = state.sellers.findIndex(seller => seller._id === updatedSeller._id)
      if (index !== -1) {
        state.sellers.splice(index, 1, updatedSeller)
      }
    },
    REMOVE_SELLER(state, sellerId) {
      state.sellers = state.sellers.filter(seller => seller._id !== sellerId)
    },
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    REMOVE_ORDER(state, orderId) {
      state.orders = state.orders.filter(order => order._id !== orderId)
    },
    ADD_ORDER(state, order) {
      state.orders.push(order)
    },
    UPDATE_ORDER(state, updatedOrder) {
      const index = state.orders.findIndex(order => order._id === updatedOrder._id);
      if (index !== -1) {
        state.orders.splice(index, 1, updatedOrder);
      }
    },
    UPDATE_BULK_ORDER(state, updatedBulkOrder) {
      const index = state.bulkOrders.findIndex(bo => bo._id === updatedBulkOrder._id)
      if (index !== -1) {
        state.bulkOrders.splice(index, 1, updatedBulkOrder)
      }
    },
    ADD_NOTIFICATION(state, notification) {
      state.notifications.push(notification)
    },
    SET_BULK_ORDER(state, bulkOrder) {
      state.bulkOrder = bulkOrder
    },
    REMOVE_BULK_ORDER(state, id) {
      state.bulkOrders = state.bulkOrders.filter(order => order._id !== id);
    },
     SET_USERS(state, users) {
      state.users = users;
    },
    SET_LOCAL_SHIPPING_ZONES(state, zones) {
      state.localShippingZones = zones
    },
    ADD_LOCAL_SHIPPING_ZONE(state, zone) {
      state.localShippingZones.push(zone)
    },
    UPDATE_LOCAL_SHIPPING_ZONE(state, updatedZone) {
      const index = state.localShippingZones.findIndex(zone => zone._id === updatedZone._id)
      if (index !== -1) {
        state.localShippingZones.splice(index, 1, updatedZone)
      }
    },
    REMOVE_LOCAL_SHIPPING_ZONE(state, zoneId) {
      state.localShippingZones = state.localShippingZones.filter(zone => zone._id !== zoneId)
    },
  },

  actions: {
    async fetchUnreadNotificationsCount({ commit }) {
      try {
        const response = await axiosInstance.get('/api/notifications/unread-count');
        return response.data.count;
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
        throw error;
      }
    },
    async fetchNotifications({ commit }) {
      try {
        const response = await axiosInstance.get('/api/notifications');
        return response.data;
      } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
      }
    },
    async markNotificationAsRead({ commit }, id) {
      try {
        await axiosInstance.put(`/api/notifications/${id}`);
      } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
      }
    },
    async fetchUserNotifications({ commit }, { userId, page = 1, limit = 10 }) {
      try {
        const response = await axiosInstance.get(`/api/admin/users/${userId}/notifications`, {
          params: { page, limit }
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching user notifications:', error);
        throw error;
      }
    },
    async syncSubscriptionDetails({ commit }) {
      try {
        const response = await axiosInstance.get('/api/users/subscription-details');
        commit('UPDATE_USER_SUBSCRIPTION', response.data);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        throw error;
      }
    },
    async subscribeToPremium({ commit }, paymentMethodId) {
      try {
        const response = await axiosInstance.post('/api/users/premium-subscription', { paymentMethodId });
        commit('UPDATE_USER_SUBSCRIPTION', response.data.subscription);
        return response.data;
      } catch (error) {
        console.error('Error subscribing to premium:', error);
        throw error;
      }
    },
    async cancelSubscription({ commit, state }) {
      try {
        console.log('Cancelling subscription for user:', state.user);
        const response = await axiosInstance.post('/api/users/cancel-subscription');
        console.log('Cancel subscription response:', response.data);
        commit('UPDATE_USER_SUBSCRIPTION', response.data.subscription);
        return response.data;
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        throw error;
      }
    },
    async createPaymentIntent({ commit }, amount) {
      try {
        commit('CLEAR_PAYMENT_ERROR');
        const response = await axiosInstance.post('/api/payments/create-payment-intent', { amount });
        return response.data;
      } catch (error) {
        console.error('Error creating payment intent:', error);
        commit('SET_PAYMENT_ERROR', error.response?.data?.message || 'An error occurred while creating the payment intent');
        throw error;
      }
    },
    clearPaymentError({ commit }) {
      commit('CLEAR_PAYMENT_ERROR');
    },
    async updateUserSubstitutionPreference({ commit }, preference) {
      try {
        const response = await axiosInstance.put('/api/users/substitution-preference', { preference });
        commit('UPDATE_USER', { substitutionPreference: preference });
        return response.data;
      } catch (error) {
        console.error('Error updating user substitution preference:', error);
        throw error;
      }
    },
    setSelectedDeliveryOption({ commit }, option) {
      commit('SET_SELECTED_DELIVERY_OPTION', option);
    },
    async fetchSellerByUrl({ commit }, url) {
      try {
        const response = await axiosInstance.get(`/api/sellers/by-url/${url}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching seller by URL:', error);
        throw error;
      }
    },
    async fetchSellerByName({ commit }, name) {
      try {
        const response = await axiosInstance.get(`/api/sellers/by-name/${name}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching seller by name:', error);
        throw error;
      }
    },
    async fetchRecommendationsBySeller({ commit }, sellerId) {
      try {
        const response = await axiosInstance.get(`/api/recommendations/by-seller/${sellerId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching recommendations by seller:', error);
        throw error;
      }
    },
    async fetchAndUpdateProduct({ commit }, productId) {
      try {
        const response = await axiosInstance.get(`/api/products/${productId}`);
        console.log('Fetched product:', response.data);
        commit('UPDATE_PRODUCT', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching product:', error);
        throw error;
      }
    },
    async fetchUserRecommendations({ commit }) {
      try {
        const response = await axiosInstance.get('/api/recommendations/user');
        commit('SET_USER_RECOMMENDATIONS', response.data);
      } catch (error) {
        console.error('Error fetching user recommendations:', error);
      }
    },
    async fetchRecommendations({ commit }) {
      try {
        const response = await axiosInstance.get('/api/recommendations');
        commit('SET_RECOMMENDATIONS', response.data);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    },
    async createRecommendation({ dispatch }, recommendationData) {
      await axiosInstance.post('/api/recommendations', recommendationData);
      dispatch('fetchUserRecommendations');
    },
    async updateRecommendation({ dispatch }, recommendationData) {
      await axiosInstance.put(`/api/recommendations/${recommendationData._id}`, recommendationData);
      dispatch('fetchRecommendations');
    },
    async deleteRecommendation({ dispatch }, { productId, type }) {
      await axiosInstance.delete(`/api/recommendations/${productId}/${type}`);
      dispatch('fetchUserRecommendations');
    },
    async getUserRecommendations({ commit }, productId) {
      const response = await axiosInstance.get(`/api/recommendations/user/${productId}`);
      return response.data;
    },
    async fetchSavedItems({ commit }) {
      try {
        const response = await axiosInstance.get('/api/users/saved-items');
        console.log('Fetched saved items:', response.data);
        commit('SET_SAVED_ITEMS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching saved items:', error);
        return [];
      }
    },
    initializeCart({ commit }) {
      const savedCart = localStorage.getItem('cart');
      if (savedCart) {
        commit('SET_CART', JSON.parse(savedCart));
      }
    },
    async becomeSeller({ commit, dispatch }) {
      try {
        await axiosInstance.post('/api/users/become-seller');
        await dispatch('fetchUserDetails');
      } catch (error) {
        console.error('Error becoming a seller:', error);
        throw error;
      }
    },
    async fetchSellerById({ commit }, sellerId) {
      try {
        const response = await axiosInstance.get(`/api/sellers/${sellerId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching seller:', error);
        throw error;
      }
    },
    async fetchSellerInfoById({ commit }, sellerId) {
      try {
        const response = await axiosInstance.get(`/api/sellers/${sellerId}/info`);
        return response.data;
      } catch (error) {
        console.error('Error fetching seller info:', error);
        throw error;
      }
    },
    setSeller({ commit }, seller) {
      commit('SET_SELLER', seller)
    },
    async fetchSellersByUserID({ commit }, userId) {
      try {
        const response = await axiosInstance.get(`/api/sellers/user/${userId}`);
        commit('SET_SELLERS_BY_USER_ID', [response.data]);
        return [response.data];
      } catch (error) {
        console.error('Error fetching sellers by user ID:', error);
        throw error;
      }
    },
    async fetchProductsBySeller({ commit }, sellerId) {
      try {
        const response = await axiosInstance.get(`/api/products/by-seller/${sellerId}`);
        return response.data || [];
      } catch (error) {
        console.error('Error fetching seller products:', error);
        throw error;
      }
    },
    async fetchCategories({ commit }) {
      try {
        const response = await axiosInstance.get('/api/categories')
        commit('SET_CATEGORIES', response.data)

        // Build category tree
        const tree = []
        const sections = [...new Set(response.data.map(cat => cat.section))]
        sections.forEach(section => {
          const sectionCategories = response.data.filter(cat => cat.section === section)
          tree.push({
            name: section,
            categories: sectionCategories.map(cat => ({
              name: cat.name,
              subcategories: cat.subcategories
            }))
          })
        })
        commit('SET_CATEGORY_TREE', tree)
      } catch (error) {
        console.error('Error fetching categories:', error)
        commit('SET_CATEGORIES', [])
        commit('SET_CATEGORY_TREE', [])
      }
    },
    async createCategory({ commit }, categoryData) {
      try {
        const response = await axiosInstance.post('/api/categories', categoryData);
        commit('ADD_CATEGORY', response.data);
        return response.data;
      } catch (error) {
        console.error('Error creating category:', error);
        throw error;
      }
    },
    async updateCategory({ commit }, categoryData) {
      try {
        const response = await axiosInstance.put(`/api/categories/${categoryData._id}`, categoryData);
        commit('UPDATE_CATEGORY', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating category:', error);
        throw error;
      }
    },
    async deleteCategory({ commit }, categoryId) {
      try {
        await axiosInstance.delete(`/api/categories/${categoryId}`);
        commit('REMOVE_CATEGORY', categoryId);
      } catch (error) {
        console.error('Error deleting category:', error);
        throw error;
      }
    },
    async fetchCartProductInfo({ commit }, productIds) {
      try {
        console.log('Fetching cart product info for IDs:', productIds);
        const response = await axiosInstance.get('/api/products/cart-info', {
          params: { ids: productIds.join(',') }
        });
        console.log('Cart product info response:', JSON.stringify(response.data, null, 2));
        commit('UPDATE_CART_PRODUCTS', response.data);
      } catch (error) {
        console.error('Error fetching cart product info:', error);
      }
    },
    async saveSearchQuery(_, query) {
      try {
        await axiosInstance.post('/api/users/save-search', { query });
      } catch (error) {
        console.error('Error saving search query:', error);
      }
    },
    async searchProducts({ commit }, query) {
  console.log('1. searchProducts action started with query:', query);
  try {
    console.log('2. Preparing to make API call');
    const response = await axiosInstance.get('/api/products/search', { params: { q: query } });
    console.log('3. API call completed. Response:', response);
    console.log('4. Response data:', response.data);
    console.log('5. Committing SET_SEARCH_RESULTS');
    commit('SET_SEARCH_RESULTS', response.data);
    console.log('6. SET_SEARCH_RESULTS committed');
  } catch (error) {
    console.error('7. Error in searchProducts:', error);
    if (error.response) {
      console.error('8. Error response data:', error.response.data);
      console.error('9. Error response status:', error.response.status);
      console.error('10. Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('11. Error request:', error.request);
    } else {
      console.error('12. Error message:', error.message);
    }
    console.log('13. Committing empty SET_SEARCH_RESULTS');
    commit('SET_SEARCH_RESULTS', []);
    console.log('14. Empty SET_SEARCH_RESULTS committed');
  }
  console.log('15. searchProducts action completed');
},
    async fetchDeliveryOptions({ commit }) {
      try {
        const response = await axiosInstance.get('/api/delivery-options');
        commit('SET_DELIVERY_OPTIONS', response.data);
      } catch (error) {
        console.error('Error fetching delivery options:', error);
      }
    },
    async saveForLater({ commit, dispatch }, product) {
      try {
        await dispatch('addToSavedItems', product);
        await dispatch('removeFromCart', product._id);
      } catch (error) {
        console.error('Error saving item for later:', error);
      }
    },
    async addToSavedItems({ commit, state }, product) {
      try {
        await axiosInstance.post('/api/users/save-item', { productId: product._id });
        commit('ADD_TO_SAVED_ITEMS', product);
      } catch (error) {
        console.error('Error adding item to saved items:', error);
      }
    },

    async removeFromSavedItems({ commit, state }, productId) {
      try {
        await axiosInstance.delete(`/api/users/save-item/${productId}`);
        commit('REMOVE_FROM_SAVED_ITEMS', productId);
      } catch (error) {
        console.error('Error removing item from saved items:', error);
      }
    },
    async updateAccountDetails({ commit }, updatedData) {
      try {
        const response = await axiosInstance.put('/api/users/profile', updatedData);
        commit('UPDATE_USER', response.data);
      } catch (error) {
        console.error('Error updating account details:', error);
        throw error;
      }
    },
    async changePassword(_, passwordData) {
      try {
        await axiosInstance.put('/api/users/change-password', passwordData);
      } catch (error) {
        console.error('Error changing password:', error);
        throw error;
      }
    },
    async fetchDietaryRestrictions() {
      try {
        const response = await axiosInstance.get('/api/users/dietary-restrictions');
        return response.data;
      } catch (error) {
        console.error('Error fetching dietary restrictions:', error);
        throw error;
      }
    },

    async updateDietaryRestrictions(_, restrictions) {
      try {
        await axiosInstance.put('/api/users/dietary-restrictions', { restrictions });
      } catch (error) {
        console.error('Error updating dietary restrictions:', error);
        throw error;
      }
    },
    async fetchLanguagePreference({ commit }) {
      try {
        const response = await axiosInstance.get('/api/users/language-preference');
        commit('UPDATE_USER', { languagePreference: response.data.languagePreference });
        return response.data.languagePreference;
      } catch (error) {
        console.error('Error fetching language preference:', error);
        throw error;
      }
    },
    async updateLanguagePreference({ commit }, languagePreference) {
      try {
        const response = await axiosInstance.put('/api/users/language-preference', { languagePreference });
        commit('UPDATE_USER', { languagePreference: response.data.languagePreference });
        return response.data.languagePreference;
      } catch (error) {
        console.error('Error updating language preference:', error);
        throw error;
      }
    },
    async fetchProductById({ commit }, productId) {
      try {
        const response = await axiosInstance.get(`/api/products/${productId}`);
        console.log('Fetched product:', response.data);
        // Optionally, you can commit this to the state if you want to keep it there
        // commit('SET_PRODUCT', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching product:', error);
        throw error;
      }
    },
    async fetchProducts({ commit }) {
      try {
        const response = await axiosInstance.get('/api/products/active')
        commit('SET_PRODUCTS', response.data)
      } catch (error) {
        console.error('Error fetching products:', error)
        commit('SET_PRODUCTS', [])
      }
    },
    async fetchAllProducts({ commit }) {
      try {
        const response = await axiosInstance.get('/api/products');
        console.log('Fetched all products:', response.data);
        commit('SET_ALL_PRODUCTS', response.data);
      } catch (error) {
        console.error('Error fetching all products:', error);
        commit('SET_ALL_PRODUCTS', []);
      }
    },
    async createProduct({ commit }, productData) {
      console.log('createProduct action dispatched');
      try {
        console.log('Sending POST request to /api/products');
        const response = await axiosInstance.post('/api/products', productData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Response received:', response.data);
        commit('ADD_PRODUCT', response.data);
        return response.data;
      } catch (error) {
        console.error('Error in createProduct action:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error message:', error.message);
         }
        throw error;
      }
    },
  async updateProduct({ commit }, productData) {
    try {
      const response = await axiosInstance.put(`/api/products/${productData.get('_id')}`, productData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      commit('UPDATE_PRODUCT', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating product:', error);
      throw error;
    }
  },
    async deleteProduct({ commit }, productId) {
      try {
        await axiosInstance.delete(`/api/products/${productId}`);
        const updatedProducts = this.state.products.filter(product => product._id !== productId);
        commit('SET_PRODUCTS', updatedProducts);
      } catch (error) {
        console.error('Error deleting product:', error);
        throw error;
      }
    },
    async fetchBulkMeatProducts({ commit }) {
      try {
        const response = await axiosInstance.get('/api/bulk-meat-products')
        commit('SET_BULK_MEAT_PRODUCTS', response.data)
      } catch (error) {
        console.error('Error fetching bulk meat products:', error)
        commit('SET_BULK_MEAT_PRODUCTS', [])
      }
    },
    async createBulkMeatProduct({ commit }, bulkMeatProductData) {
      try {
        const response = await axiosInstance.post('/api/bulk-meat-products', bulkMeatProductData)
        commit('ADD_BULK_MEAT_PRODUCT', response.data)
        return response.data
      } catch (error) {
        console.error('Error creating bulk meat product:', error)
        throw error
      }
    },
    async updateBulkMeatProduct({ commit }, bulkMeatProductData) {
      try {
        const response = await axiosInstance.put(`/api/bulk-meat-products/${bulkMeatProductData._id}`, bulkMeatProductData)
        commit('UPDATE_BULK_MEAT_PRODUCT', response.data)
        return response.data
      } catch (error) {
        console.error('Error updating bulk meat product:', error)
        throw error
      }
    },
    async deleteBulkMeatProduct({ commit }, id) {
      try {
        await axiosInstance.delete(`/api/bulk-meat-products/${id}`)
        commit('REMOVE_BULK_MEAT_PRODUCT', id)
      } catch (error) {
        console.error('Error deleting bulk meat product:', error)
        throw error
      }
    },
    async fetchBulkOrders({ commit }) {
      try {
        const response = await axiosInstance.get('/api/bulk-orders')
        commit('SET_BULK_ORDERS', response.data)
      } catch (error) {
        console.error('Error fetching bulk orders:', error)
        commit('SET_BULK_ORDERS', [])
      }
    },
    async createBulkOrder({ commit }, bulkOrderData) {
      try {
        const response = await axiosInstance.post('/api/bulk-orders', bulkOrderData)
        commit('ADD_BULK_ORDER', response.data)
        return response.data
      } catch (error) {
        console.error('Error creating bulk order:', error)
      }
    },
    async fetchBulkOrderInfo({ commit }, orderId) {
      try {
        const response = await axiosInstance.get(`/api/bulk-orders/${orderId}/info`);
        commit('SET_BULK_ORDER_INFO', response.data);
      } catch (error) {
        console.error('Error fetching bulk order info:', error);
      }
    },
    async fetchUser({ commit }) {
      try {
        const response = await axiosInstance.get('/api/auth/user')
        commit('SET_USER', response.data)
      } catch (error) {
        console.error('Error fetching user:', error)
        if (error.response && error.response.status === 401) {
          commit('CLEAR_USER')
        }
      }
    },
    async fetchSellers({ commit }) {
      try {
        const response = await axiosInstance.get('/api/sellers');
        commit('SET_SELLERS', response.data);
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    },
    async createSeller({ commit }, formData) {
      try {
        const response = await axiosInstance.post('/api/sellers', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        commit('ADD_SELLER', response.data);
        commit('SET_USER_AS_SELLER');
        return response.data;
      } catch (error) {
        console.error('Error creating seller:', error);
        throw error;
      }
    },
    async updateSeller({ commit }, { id, formData }) {
      try {
        if (!id) {
          throw new Error('Seller ID is undefined');
        }
        const response = await axiosInstance.put(`/api/sellers/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        commit('UPDATE_SELLER', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating seller:', error);
        throw error;
      }
    },
    async deleteSeller({ commit }, sellerId) {
      try {
        await axiosInstance.delete(`/api/sellers/${sellerId}`)
        commit('REMOVE_SELLER', sellerId)
      } catch (error) {
        console.error('Error deleting seller:', error)
        throw error
      }
    },
    async updateOrderStatus({ commit }, { id, status }) {
      try {
        const response = await axiosInstance.put(`/api/orders/${id}/status`, { status });
        commit('UPDATE_ORDER', response.data);
      } catch (error) {
        console.error('Error updating order status:', error);
        throw error;
      }
    },
    async updateOrderNotes({ commit }, { id, notes }) {
      try {
        const response = await axiosInstance.put(`/api/orders/${id}/notes`, { notes });
        commit('UPDATE_ORDER', response.data);
      } catch (error) {
        console.error('Error updating order notes:', error);
        throw error;
      }
    },
    async fetchOrders({ commit }, filters = {}) {
      try {
        const response = await axiosInstance.get('/api/orders', { params: filters });
        commit('SET_ORDERS', response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
      }
    },
    async fetchOrder({ commit }, orderId) {
      try {
        const response = await axiosInstance.get(`/api/orders/${orderId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching order:', error);
        throw error;
      }
    },
    async deleteOrder({ commit }, orderId) {
      try {
        await axiosInstance.delete(`/api/orders/${orderId}`)
        commit('REMOVE_ORDER', orderId)
      } catch (error) {
        console.error('Error deleting order:', error)
        throw error
      }
    },
    async createOrder({ commit }, orderData) {
      try {
        const response = await axiosInstance.post('/api/orders', orderData)
        commit('ADD_ORDER', response.data)
        return response.data
      } catch (error) {
        console.error('Error creating order:', error)
        throw error
      }
    },
    async updateOrderPaymentStatus({ commit }, { orderId, paymentIntentId }) {
      try {
        const response = await axiosInstance.put(`/api/orders/${orderId}/payment`, { paymentIntentId });
        commit('UPDATE_ORDER_PAYMENT_STATUS', { orderId, paymentStatus: response.data.order.paymentStatus });
        return response.data;
      } catch (error) {
        console.error('Error updating order payment status:', error);
        throw error;
      }
    },
    async joinBulkOrder({ commit }, { orderId, quantity }) {
      try {
        const response = await axiosInstance.post(`/api/bulk-orders/${orderId}/join`, { quantity })
        commit('UPDATE_BULK_ORDER', response.data)
        return response.data
      } catch (error) {
        console.error('Error joining bulk order:', error)
        throw error
      }
    },
    async subscribeNotification({ commit }, notificationData) {
      try {
        const response = await axiosInstance.post('/api/notifications', notificationData)
        commit('ADD_NOTIFICATION', response.data)
        return response.data
      } catch (error) {
        console.error('Error subscribing to notification:', error)
      }
    },
    async updateProfile({ commit }, profileData) {
      try {
        const response = await axiosInstance.put('/api/users/profile', profileData)
        commit('SET_USER', response.data.user)
        return response.data.user
      } catch (error) {
        console.error('Profile update failed:', error)
      }
    },
    async generateInviteLink({ commit }, orderId) {
      try {
        const response = await axiosInstance.post(`/api/bulk-orders/${orderId}/invite`)
        return response.data
      } catch (error) {
        console.error('Error generating invite link:', error)
        throw error
      }
    },
    async fetchBulkOrderByInviteCode({ commit }, inviteCode) {
      try {
        const response = await axiosInstance.get(`/api/bulk-orders/invite/${inviteCode}`)
        commit('SET_BULK_ORDER', response.data)
      } catch (error) {
        console.error('Error fetching bulk order by invite code:', error)
        throw error
      }
    },
    async fetchAllBulkOrders({ commit }) {
      try {
        const response = await axiosInstance.get('/api/bulk-orders/admin/all')
        commit('SET_BULK_ORDERS', response.data)
      } catch (error) {
        console.error('Error fetching all bulk orders:', error)
      }
    },
    async deleteBulkOrder({ commit }, id) {
      try {
        await axiosInstance.delete(`/api/bulk-orders/${id}`);
        commit('REMOVE_BULK_ORDER', id);
      } catch (error) {
        console.error('Error deleting bulk order:', error);
        throw error;
      }
    },
    async updateBulkOrderStatus({ commit }, { id, status }) {
      try {
        console.log('Sending update request:', id, status);
        const response = await axiosInstance.put(`/api/bulk-orders/${id}/status`, { status });
        commit('UPDATE_BULK_ORDER', response.data);
      } catch (error) {
        console.error('Error updating bulk order status:', error);
        throw error;
      }
    },
    async fetchUsers({ commit }) {
      try {
        const response = await axiosInstance.get('/api/users/admin/all');
        commit('SET_USERS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
        commit('SET_USERS', []);
      }
    },
    async updateUser({ commit }, userData) {
      try {
        const response = await axiosInstance.put(`/api/users/admin/${userData._id}`, userData);
        return response.data;
      } catch (error) {
        console.error('Error updating user:', error);
        throw error;
      }
    },
    async deleteUser({ commit }, userId) {
      try {
        await axiosInstance.delete(`/api/users/admin/${userId}`);
        // After successful deletion, fetch the updated user list
        const response = await axiosInstance.get('/api/users/admin/all');
        commit('SET_USERS', response.data);
      } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
      }
    },
    async fetchAdminSearchHistory({ commit }) {
      try {
        const response = await axiosInstance.get('/api/admin/search-history')
        return response.data
      } catch (error) {
        console.error('Error fetching admin search history:', error)
        throw error
      }
    },
    async fetchLocalShippingZones({ commit }) {
      try {
        const response = await axiosInstance.get('/api/local-shipping-zones')
        commit('SET_LOCAL_SHIPPING_ZONES', response.data)
      } catch (error) {
        console.error('Error fetching local shipping zones:', error)
        commit('SET_LOCAL_SHIPPING_ZONES', [])
      }
    },
    async createLocalShippingZone({ commit }, zoneData) {
      try {
        const response = await axiosInstance.post('/api/local-shipping-zones', zoneData)
        commit('ADD_LOCAL_SHIPPING_ZONE', response.data)
        return response.data
      } catch (error) {
        console.error('Error creating local shipping zone:', error)
        throw error
      }
    },
    async updateLocalShippingZone({ commit }, zoneData) {
      try {
        const response = await axiosInstance.put(`/api/local-shipping-zones/${zoneData._id}`, zoneData)
        commit('UPDATE_LOCAL_SHIPPING_ZONE', response.data)
        return response.data
      } catch (error) {
        console.error('Error updating local shipping zone:', error)
        throw error
      }
    },
    async deleteLocalShippingZone({ commit }, zoneId) {
      try {
        await axiosInstance.delete(`/api/local-shipping-zones/${zoneId}`)
        commit('REMOVE_LOCAL_SHIPPING_ZONE', zoneId)
      } catch (error) {
        console.error('Error deleting local shipping zone:', error)
        throw error
      }
    },
    async addToCart({ commit, dispatch }, product) {
      console.log('addToCart action called with product:', product);
      try {
        console.log('Fetching delivery options for product:', product._id);
        const response = await axiosInstance.get(`/api/products/${product._id}/delivery-options`);
        console.log('Delivery options response:', response.data);
        const { deliveryOptions, defaultOption } = response.data;
        console.log('Default delivery option:', defaultOption);
        commit('ADD_TO_CART', { product, deliveryOption: defaultOption });
      } catch (error) {
        console.error('Error fetching delivery options:', error);
        // Fallback to 'Delivery' if there's an error
        console.log('Using fallback delivery option: Delivery');
        commit('ADD_TO_CART', { product, deliveryOption: 'Delivery' });
      }
    },
    updateCartItemDeliveryOption({ commit }, { productId, deliveryOption }) {
      console.log('Updating delivery option for product:', productId, 'to', deliveryOption);
      commit('UPDATE_CART_ITEM_DELIVERY_OPTION', { productId, deliveryOption });
    },
    removeFromCart({ commit }, productId) {
      commit('REMOVE_FROM_CART', productId);
    },
    updateCartItemQuantity({ commit }, { productId, quantity }) {
      commit('UPDATE_CART_ITEM_QUANTITY', { productId, quantity });
    },
    clearCart({ commit }) {
      commit('CLEAR_CART');
    },
  },

  plugins: [
    store => {
      store.subscribe((mutation, state) => {
        if (mutation.type.startsWith('SET_CART') || 
            mutation.type.startsWith('ADD_TO_CART') || 
            mutation.type.startsWith('REMOVE_FROM_CART') || 
            mutation.type.startsWith('UPDATE_CART_ITEM_QUANTITY') || 
            mutation.type.startsWith('CLEAR_CART')) {
          localStorage.setItem('cart', JSON.stringify(state.cart));
        }
      });
    }
  ],

  modules: {
    user,
    userVerification,
    payment,
    address
  }
})
