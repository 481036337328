<!-- ./frontend/src/components/ProductCard.vue -->
<template>
  <v-card>
    <v-img :src="product.productImage" height="200" cover @click="$emit('navigate-to-product', product)"></v-img>
    <v-card-title @click="$emit('navigate-to-product', product)" style="cursor: pointer;">{{ product.name }}</v-card-title>
    <v-card-text>
      <p class="text-h6" v-if="product.weightAdjusted">
        ${{ product.pricePerMeasure }} / {{ product.weightMeasure }}
      </p>
      <p class="text-h6" v-else>${{ product.price }}</p>
      <p>{{ product.seller.name }}</p>
      <v-icon icon="mdi-thumb-up" color="charcoal"></v-icon>
      <span class="ml-1">{{ product.countRecommendations }}</span>
      <v-icon icon="mdi-heart" color="charcoal" class="ml-2"></v-icon>
      <span class="ml-1">{{ product.countChangedMyLife }}</span>
      <v-chip-group>
        <v-chip v-for="option in product.deliveryOptions" :key="option" color="charcoal" class="mr-2">
          {{ option }}
        </v-chip>
      </v-chip-group>
      <div v-if="cartQuantity > 0" class="d-flex align-center mt-2 quantity-control">
        <v-btn 
          icon="mdi-minus" 
          @click="decreaseQuantity" 
          variant="text"
          :class="{ 'active-icon': lastClicked === 'minus' }"
        ></v-btn>
        <span class="mx-2">{{ cartQuantity }}</span>
        <v-btn 
          icon="mdi-plus" 
          @click="increaseQuantity" 
          variant="text"
          :class="{ 'active-icon': lastClicked === 'plus' }"
        ></v-btn>
      </div>
      <v-btn v-else color=success @click="$emit('add-to-cart', product)" class="mt-2">Add to Cart</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    },
    cartQuantity: {
      type: Number,
      default: 0
    }
  },
  emits: ['navigate-to-product', 'add-to-cart', 'increase-quantity', 'decrease-quantity'],
  setup(props, { emit }) {
    const lastClicked = ref(null);

    const increaseQuantity = () => {
      lastClicked.value = 'plus';
      emit('increase-quantity', props.product);
    };

    const decreaseQuantity = () => {
      lastClicked.value = 'minus';
      emit('decrease-quantity', props.product);
    };

    return {
      lastClicked,
      increaseQuantity,
      decreaseQuantity
    };
  }
}
</script>

<style scoped>
.v-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
}

.quantity-control {
  background-color: #4CAF50;
  border-radius: 9999px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 40px;
}

.quantity-control .v-btn {
  color: white !important;
  min-width: 36px;
  width: 36px;
  height: 36px;
}

.quantity-control span {
  color: white;
  flex-grow: 1;
  text-align: center;
}

.active-icon {
  color: #2E7D32 !important;
}

@media (max-width: 600px) {
  .v-card__title {
    font-size: 1.2rem;
  }

  .v-card__text {
    font-size: 0.9rem;
  }

  .v-img {
    height: 150px;
  }
}
</style>
