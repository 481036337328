<!-- ./frontend/src/components/ForgotPassword.vue -->
<template>
  <div>
    <v-form @submit.prevent="resetPassword">
      <v-text-field
        v-model="email"
        label="Email"
        name="email"
        prepend-icon="mdi-email"
        type="email"
        required
      ></v-text-field>
      <v-btn
        color="#36454F"
        class="mt-4"
        type="submit"
        block
        :loading="loading"
      >
        Reset Password
      </v-btn>
    </v-form>
    <v-btn class="mt-4" block text @click="$emit('back')">Back to Login</v-btn>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ForgotPassword',
  emits: ['back'],
  setup(props, { emit }) {
    const store = useStore()
    const email = ref('')
    const loading = ref(false)

    const resetPassword = async () => {
      loading.value = true
      try {
        await store.dispatch('user/resetPassword', email.value)
        alert('Password reset instructions have been sent to your email.')
        emit('back') // Go back to login after successful request
      } catch (error) {
        console.error('Password reset failed:', error)
        alert('Failed to reset password. Please try again.')
      } finally {
        loading.value = false
      }
    }

    return {
      email,
      loading,
      resetPassword
    }
  }
}
</script>
