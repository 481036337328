<!-- ./frontend/src/views/MeatPage.vue -->
<template>
  <v-container class="pa-md-6" fluid>
    <!-- Join Bulk Order Section -->
    <v-layout>
      <v-main :min-height="$vuetify.display.mdAndUp ? 500 : 400">
        <v-container class="h-100 d-flex align-center pa-6" fluid>
          <v-row align="center">
            <v-col cols="12" md="6">
              <div class="text-h6 font-weight-regular">
                Save more by ordering in bulk
              </div>
              <h1 class="text-h4 text-md-h2 font-weight-bold mb-6">
                Join a Bulk Meat Order
              </h1>
              <div class="text-body-1 text-medium-emphasis mb-10">
                Participate in community bulk orders to get high-quality, locally sourced meat at better prices. Join existing orders or start your own to connect with local farmers and fellow meat enthusiasts.
              </div>
              <div class="d-flex ga-4 align-center justify-start flex-wrap">
                <v-btn class="text-none" color="primary" flat rounded="lg" text="Join Bulk Order" @click="navigateToBulkOrder" />
                <span>or</span>
                <v-btn append-icon="mdi-chevron-right" border class="text-none" flat rounded="lg" slim text="Learn More" @click="learnMoreAboutBulkOrders" />
              </div>
            </v-col>
            <v-col class="hidden-sm-and-down" cols="6">
              <div class="me-n12">
                <v-sheet border class="ps-4 py-4" color="surface-light" rounded="s-lg">
                  <v-img class="border-s border-t border-b" cover max-height="400" position="left" :rounded="$vuetify.display.smAndDown ? true : 's-lg'" src="https://images.unsplash.com/photo-1607623814075-e51df1bdc82f?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3" />
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>

    <!-- Individual Meat Products with Filter -->
    <v-layout>
      <v-main>
        <v-container class="pa-md-12" fluid>
          <div class="d-flex justify-space-between">
            <div>
              <h2 class="text-h5 text-md-h4 font-weight-bold mb-2">Individual Meat Products</h2>
              <p class="text-medium-emphasis">
                Explore our selection of high-quality, locally sourced meat products.
              </p>
            </div>
          </div>
          <v-divider class="my-4" />
          <div>
            <v-btn v-if="$vuetify.display.mdAndDown" class="text-none px-0" color="medium-emphasis" prepend-icon="mdi-filter" :ripple="false" size="small" text="Filters" variant="plain" @click="drawer = !drawer" />
          </div>
          <v-row class="mt-0">
            <v-col class="hidden-md-and-down" cols="3">
              <div ref="desktopTarget" />
            </v-col>
            <v-col cols="12" lg="9">
              <v-row v-if="filteredProducts && filteredProducts.length > 0">
                <v-col v-for="(product, i) in filteredProducts" :key="i" cols="12" md="4" sm="6">
                  <v-card class="mx-auto" flat link :ripple="false" rounded="lg">
                    <v-img
                      :aspect-ratio="4/5"
                      block
                      cover
                      :src="product.productImage || 'https://via.placeholder.com/300x400'"
                      @click="navigateToProduct(product)"
                      style="cursor: pointer;"
                    />
                    <v-card-title @click="navigateToProduct(product)" style="cursor: pointer;">
                      {{ product.name }}
                    </v-card-title>
                    <v-card-text>
                      <p>{{ product.name }} by {{ product.seller.name }}</p>
                      <p class="font-weight-bold" v-if="product.weightAdjusted">
                        ${{ product.pricePerMeasure }} / {{ product.weightMeasure }}
                      </p>
                      <p class="font-weight-bold" v-else>
                        ${{ product.price }}
                      </p>
                      <v-btn color="primary" @click="addToCart(product)" class="mt-2">Add to Cart</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <p>No products available.</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <v-navigation-drawer v-model="drawer" location="end" temporary>
        <template #prepend>
          <v-toolbar flat>
            <v-toolbar-title>Filters</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <div ref="mobileTarget" class="pa-4" />
      </v-navigation-drawer>
      <Teleport v-if="teleportTarget" :to="teleportTarget">
        <v-label>Meat Type</v-label>
        <div class="mt-4">
          <v-checkbox-btn v-for="type in meatTypes" :key="type" color="primary" density="comfortable" :label="type" :model-value="selectedTypes.includes(type)" @change="toggleFilter('selectedTypes', type)" />
        </div>
        <v-divider class="my-4" />
        <v-label>Options</v-label>
        <div class="mt-4">
          <v-checkbox-btn v-for="option in options" :key="option" color="primary" density="comfortable" :label="option" :model-value="selectedOptions.includes(option)" @change="toggleFilter('selectedOptions', option)" />
        </div>
        <v-divider class="my-4" />
        <v-label>Seller</v-label>
        <div class="mt-4">
          <v-checkbox-btn v-for="seller in seller" :key="seller" color="primary" density="comfortable" :label="seller" :model-value="selectedSeller.includes(seller)" @change="toggleFilter('selectedSellers', seller)" />
        </div>
        <v-divider class="my-4" />
        <v-label>Halal</v-label>
        <div class="mt-4">
          <v-checkbox-btn color="primary" density="comfortable" label="Halal" v-model="selectedHalal" />
        </div>
      </Teleport>
    </v-layout>

    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      Item added to cart
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

export default {
  name: 'MeatPage',
  setup() {
    const store = useStore();
    const router = useRouter();
    const display = useDisplay();
    const products = ref([]);
    const snackbar = ref(false);
    const drawer = ref(false);
    const desktopTarget = ref(null);
    const mobileTarget = ref(null);
    const selectedTypes = ref([]);
    const selectedOptions = ref([]);
    const selectedSellers = ref([]);
    const selectedHalal = ref(false);

    const fetchProducts = async () => {
      console.log('Fetching products for category: Meat');
      await store.dispatch('fetchProducts', { category: 'Meat' });
      products.value = store.state.products;
      console.log('Products in component after fetch:', products.value);
    };

    const addToCart = (product) => {
      store.dispatch('addToCart', product);
      snackbar.value = true;
    };

    const navigateToBulkOrder = () => {
      router.push('/bulk-meat');
    };

    const learnMoreAboutBulkOrders = () => {
      console.log('Learn more about bulk orders');
    };

    const teleportTarget = computed(() => {
      return display.mdAndDown.value ? mobileTarget.value : desktopTarget.value;
    });

    const meatTypes = computed(() => [...new Set(products.value.map(p => p.subcategory))]);
    const options = computed(() => {
      const allOptions = products.value.flatMap(p => p.options.map(o => o.option));
      return [...new Set(allOptions)];
    });
    const sellers = computed(() => [...new Set(products.value.map(p => p.seller.name))]);

    const filteredProducts = computed(() => {
      return products.value.filter(product => {
        const typeMatch = selectedTypes.value.length === 0 || selectedTypes.value.includes(product.subcategory);
        const optionMatch = selectedOptions.value.length === 0 || product.options.some(o => selectedOptions.value.includes(o.option));
        const sellerMatch = selectedSellers.value.length === 0 || selectedSellers.value.includes(product.seller.name);
        const halalMatch = !selectedHalal.value || product.halal;
        return typeMatch && optionMatch && sellerMatch && halalMatch;
      });
    });

    const toggleFilter = (filterType, value) => {
      const filterArray = filterType === 'selectedTypes' ? selectedTypes :
                          filterType === 'selectedOptions' ? selectedOptions :
                          filterType === 'selectedSellers' ? selectedSellers : null;
      
      if (filterArray) {
        const index = filterArray.value.indexOf(value);
        if (index === -1) {
          filterArray.value.push(value);
        } else {
          filterArray.value.splice(index, 1);
        }
      }
    };

    const navigateToProduct = (product) => {
      router.push(`/${product.category}/${product._id}`);
    };

    watch([selectedTypes, selectedOptions, selectedSellers, selectedHalal], () => {
      console.log('Filters changed:', { selectedTypes: selectedTypes.value, selectedOptions: selectedOptions.value, selectedSellers: selectedSellers.value, selectedHalal: selectedHalal.value });
    });

    onMounted(() => {
      fetchProducts();
    });

    return {
      products,
      filteredProducts,
      addToCart,
      snackbar,
      drawer,
      desktopTarget,
      mobileTarget,
      teleportTarget,
      navigateToBulkOrder,
      learnMoreAboutBulkOrders,
      selectedTypes,
      selectedOptions,
      selectedSellers,
      selectedHalal,
      meatTypes,
      options,
      sellers,
      toggleFilter,
      navigateToProduct,
    };
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
